import AdComponent from "components/AdSense";
import Footer from "components/Footer";
import Header from "components/Header";
import Loader from "components/loader/Loader";
import NewsPicList from "components/newspic/NewsPicList";
import AuthContext from "context/AuthContext";
import {
  collection,
  query,
  orderBy,
  where,
  limit,
  startAfter,
  getDocs,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { db } from "firevaseApp";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { IoSearch } from "react-icons/io5";
import { Link } from "react-router-dom";

export interface NewsPicsProps {
  id: string;
  email: string;
  title: string;
  source: string;
  registrationdate: string;
  createdAt: string;
  uid: string;
  hashTags: string;
  imageUrl: string;
  linkUrl: string;
  views?: number;
}

const PAGE_SIZE = 9;

export default function NewsPic() {
  const exception1 = process.env.REACT_APP_ADMIN_EMAIL1 || "";
  const exception2 = process.env.REACT_APP_ADMIN_EMAIL2 || "";
  const [newsPics, setNewsPics] = useState<NewsPicsProps[]>([]);
  const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot | null>(
    null
  );
  const [hasNextPage, setHasNextPage] = useState<boolean>(true);
  const { user } = useContext(AuthContext);
  const [value, setValue] = useState<string>(""); // 검색창의 input
  const [tagQuery, setTagQuery] = useState<string>(""); // 검색창의 input
  const [error, setError] = useState<string | null>(null);
  const [slice, setSlice] = useState<number>(9);
  const [loading, setLoading] = useState<boolean>(false);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e?.target?.value); // 검색창에 입력되는 값을 tagQuery로 저장
  };

  const handleKeyUp = async (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      setTagQuery(value);
      setSlice(9); // 검색어가 변경될 때 slice 초기화
      window.scrollTo({ top: 0, behavior: "smooth" }); // 검색 시 페이지 맨 위로 이동
    }
  };

  const fetchNewsPics = async (
    loadMore: boolean = false,
    searchMode: boolean = false
  ) => {
    try {
      let newsPicsRef = collection(db, "newspics");
      let newsPicsQuery;
      setLoading(true);
      if (searchMode && tagQuery) {
        newsPicsQuery = query(newsPicsRef, orderBy("registrationdate", "desc"));
        console.log("쿼리 검색 실행");
      } else {
        newsPicsQuery = query(
          newsPicsRef,
          orderBy("registrationdate", "desc"),
          limit(PAGE_SIZE)
        );
        console.log("최초 쿼리 검색 실행1");
        if (loadMore && lastVisible) {
          newsPicsQuery = query(
            newsPicsRef,
            orderBy("registrationdate", "desc"),
            startAfter(lastVisible),
            limit(PAGE_SIZE)
          );
          console.log("최초 쿼리 검색 실행2");
        }
      }

      const snapShot = await getDocs(newsPicsQuery);

      if (!snapShot.empty) {
        const lastVisibleDoc = snapShot.docs[snapShot.docs.length - 1];
        setLastVisible(lastVisibleDoc);

        const dataObj = snapShot.docs.map((doc) => ({
          ...(doc.data() as NewsPicsProps),
          id: doc.id,
        }));

        if (searchMode) {
          const filteredData = dataObj.filter((newsPic) =>
            newsPic.title.toLowerCase().includes(tagQuery.toLowerCase())
          );
          setNewsPics(
            loadMore
              ? (prev) => [
                  ...prev,
                  ...filteredData.slice(prev.length, prev.length + slice),
                ]
              : filteredData.slice(0, slice)
          );
        } else {
          setNewsPics((prev) => (loadMore ? [...prev, ...dataObj] : dataObj));
          setHasNextPage(snapShot.docs.length === PAGE_SIZE);
        }
      } else {
        setHasNextPage(false);
      }
      setTimeout(() => {
        setLoading(false);
      }, 500);
    } catch (error) {
      setError("데이터를 불러오는 중 오류가 발생했습니다.");
    }
  };

  useEffect(() => {
    fetchNewsPics();
  }, []);

  useEffect(() => {
    if (tagQuery.trim() === "") {
      setSlice(9); // slice 초기화
      fetchNewsPics();
    } else {
      setSlice(9); // slice 초기화
      fetchNewsPics(false, true);
    }
  }, [tagQuery]);

  const handleLoadMore = () => {
    if (tagQuery.trim() === "") {
      fetchNewsPics(true);
    } else {
      setSlice((prev) => prev + PAGE_SIZE);
      fetchNewsPics(true, true);
    }
  };

  return (
    <>
      <Helmet>
        <meta name="description" content="안전관련 뉴스" />
        <meta property="og:title" content="소소안전" />
        <meta property="og:description" content="안전관련 뉴스" />
        <meta
          property="og:image"
          content="https://sososafety.shop/main_slider.png"
        />
        <meta property="og:url" content="https://sososafety.shop/newspic" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Header />
      <div className="posters">
        <div className="posters__title">뉴스 기사</div>
        <div className="posters__add">
          {(user?.uid === exception1 || user?.uid === exception2) && (
            <>
              <Link
                to={"/newspic/new"}
                state={"산업안전"}
                className="posters__new"
              >
                산업안전 등록
              </Link>
              {newsPics.length}
            </>
          )}
        </div>
        <div className="search__box">
          <div className="searchs__search-div">
            <div className="searchs__box">
              <input
                type="text"
                className="searchs__search"
                value={value}
                placeholder="키워드를 검색하세요."
                onChange={onChange}
                onKeyUp={handleKeyUp}
              />
              <button
                type="button"
                onClick={() => {
                  setTagQuery(value);
                  setSlice(9);
                }}
                className="searchs__search-btn"
              >
                <IoSearch
                  color="white"
                  size={25}
                  className="searchs__search-btnimg"
                />
              </button>
            </div>
          </div>
        </div>
        {loading ? (
          <>
            <div className="loader_gap">
              <Loader />
              <div className="loader_gap-title">
                안전을 위해 열심히 검색 중..
              </div>
            </div>
          </>
        ) : (
          <div className="newspic">
            <div className="addsense_smart">
            <AdComponent />
            </div>
            {newsPics.length > 0 ? (
              newsPics.map((newsPic) => (
                <NewsPicList newsPic={newsPic} key={newsPic.id} />
              ))
            ) : (
              <div className="poster__no-posters">
                <div className="poster__text">게시글이 없습니다.</div>
              </div>
            )}
          </div>
        )}
        {hasNextPage && (
          <div className="load-more">
            <button className="more__btn" onClick={handleLoadMore}>
              더보기
            </button>
          </div>
        )}
      </div>
      <Footer />
    </>
  );
}
