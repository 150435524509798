import Header from "components/Header"

export default function EquipmentPage() {
    return (
        <>
            <Header />
            <h1>Equipment Page</h1>
        </>
    );

}