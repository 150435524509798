import React, { useEffect, useState } from "react";
import Header from "components/Header";
import Footer from "components/Footer";
import { FaArrowRight } from "react-icons/fa";
import {
  IoIosArrowDroprightCircle,
  IoIosArrowDropleftCircle,
} from "react-icons/io";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useSwipeable } from "react-swipeable";
import Modal from "components/popup/Popup";

//포스터
const imagesPoster = [
  "/img/main_poster_1.png",
  "/img/main_poster_2.png",
  "/img/main_poster_3.png",
  "/img/main_poster_4.png",
  "/img/main_poster_5.png",
  "/img/main_poster_6.png",
];
//표지
const imagesSign = [
  "/img/main_sign_1.png",
  "/img/main_sign_2.png",
  "/img/main_sign_3.png",
  "/img/main_sign_4.png",
  "/img/main_sign_5.png",
  "/img/main_sign_6.png",
  "/img/main_sign_7.png",
  "/img/main_sign_8.png",
  "/img/main_sign_9.png",
  "/img/main_sign_10.png",
];

//표지판
const imagesEtc = [
  "/img/main_etc_1.png",
  "/img/main_etc_2.png",
  "/img/main_etc_3.png",
  "/img/main_etc_4.png",
  "/img/main_etc_5.png",
  "/img/main_etc_6.png",
];

//카드뉴스
const imagesCard = [
  "/img/main_card_1.png",
  "/img/main_card_2.png",
  "/img/main_card_3.png",
  "/img/main_card_4.png",
  "/img/main_card_5.png",
  "/img/main_card_6.png",
];

const MAX_VISIBILITY = 3;

const Carousel: React.FC<{ children: React.ReactNode[] }> = ({ children }) => {
  const [active, setActive] = useState(0);
  const count = React.Children.count(children);

  const handlers = useSwipeable({
    onSwipedLeft: () => setActive(active < count - 1 ? active + 1 : active),
    onSwipedRight: () => setActive(active > 0 ? active - 1 : active),
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return (
    <div className="carousel" {...handlers}>
      {active > 0 && (
        <button
          className="nav left"
          aria-label="left btn"
          onClick={() => setActive(active - 1)}
        >
          <IoIosArrowDropleftCircle />
        </button>
      )}
      {React.Children.map(children, (child, i) => (
        <div
          className="card-container"
          style={
            {
              "--active": i === active ? 1 : 0,
              "--offset": (active - i) / 3,
              "--direction": Math.sign(active - i),
              "--abs-offset": Math.abs(active - i) / 3,
              pointerEvents: active === i ? "auto" : "none",
              opacity: Math.abs(active - i) >= MAX_VISIBILITY ? "0" : "1",
              display: Math.abs(active - i) > MAX_VISIBILITY ? "none" : "block",
            } as React.CSSProperties
          }
        >
          {child}
        </div>
      ))}
      {active < count - 1 && (
        <button
          className="nav right"
          aria-label="right btn"
          onClick={() => setActive(active + 1)}
        >
          <IoIosArrowDroprightCircle />
        </button>
      )}
    </div>
  );
};

export default function HomePage() {
  window.scrollTo({ top: -100, behavior: "smooth" }); // 페이지 맨 위로 스크롤

  useEffect(() => {
    const handleContextMenu = (event: Event) => {
      event.preventDefault();
    };

    const elements = document.querySelectorAll("img, button");
    elements.forEach((element) => {
      element.addEventListener("contextmenu", handleContextMenu);
    });

    return () => {
      elements.forEach((element) => {
        element.removeEventListener("contextmenu", handleContextMenu);
      });
    };
  }, []);

  return (
    <>
      <Helmet>
        <meta name="description" content="안전업무가 더 편해지는 즐거운 경험" />
        <meta property="og:title" content="소소안전" />
        <meta
          property="og:description"
          content="안전업무가 더 편해지는 즐거운 경험"
        />
        <meta
          property="og:image"
          content="https://sososafety.shop/main_slider.png"
        />
        <meta property="og:url" content="https://sososafety.shop" />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="home">
        <Header />
        <div className="slider">
          <div className="slider_img">
            <div className="slider__box">
              <div className="slider__content">
                <h1 className="slider__content-h1">
                  <span>안전 업무</span>가 더 편해지는 <span>즐거운 경험</span>
                </h1>
                <h2 className="slider__content-h1 underscore">
                  <span>소소안전</span>과 함께하세요.
                </h2>
                <div className="slider__content-flex">
                  <h3 className="slider__content-h2">
                    안전관리자를 위한 소소한 도움.
                  </h3>
                  <img src="/img/logo_title.png" alt="logo_title" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="smart">
          <div className="smart_cont">
            <h2>불편했던 법령 검색과 공단 자료 찾기!</h2>
            <p>키워드 하나로 산업안전보건 법령 및 공단자료까지 한번에 검색</p>
            <div className="smart_cont-flex">
              <div className="smart_cont-main-img">
                <img src="/img/main_smart_1.png" alt="main_smart_1" />
              </div>
              <ul>
                <li className="smart_main-li">
                  <img src="/img/main_smart_add.png" alt="smart_add" />{" "}
                  산업안전보건 법령
                </li>
                <li className="smart_main-li">
                  <img src="/img/main_smart_add.png" alt="smart_add" /> 고시 /
                  훈령 / 예규
                </li>
                <li className="smart_main-li">
                  <img src="/img/main_smart_add.png" alt="smart_add" /> KOSHA
                  GUID
                </li>
                <li className="smart_main-li">
                  <img src="/img/main_smart_add.png" alt="smart_add" />{" "}
                  안전보건공단 미디어 자료
                </li>
              </ul>
            </div>
            <img
              src="/img/main_smart_2.png"
              alt="main_smart_2"
              className="smart_cont-main-img2"
            />
            <div className="smart_cont-smart">
              <div className="smart_cont-smart-title">안전보건 스마트 검색</div>
              <div className="smart_cont-smart-cont">
                키워드로 한 번에 검색해서 필요한 내용만 쏙쏙
              </div>
              <div className="searchs__top">
                <div className="searchs__search-div-main">
                  <Link to={"/smart"}>
                    <img
                      className="searchs__search-main"
                      src="/img/main_smart_input_2.png"
                      alt="serach img"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="post">
          <div className="post__box">
            <div className="post__box-flex">
              <div className="content">
                <h2>안전포스터 괜찮은 거 없나..?</h2>
                <p>
                  다양하고 새로운 안전포스터를
                  <br />
                  무료로 무제한 다운로드 받으세요.
                </p>
                <ul>
                  <li className="content_main-li">
                    <img src="/img/main_smart_add.png" alt="smart_add" />
                    소소안전 자체 제작 포스터
                  </li>
                  <li className="content_main-li">
                    <img src="/img/main_smart_add.png" alt="smart_add" />
                    안전보건 포스터 무제한 다운로드
                  </li>
                  <li className="content_main-li">
                    <img src="/img/main_smart_add.png" alt="smart_add" />
                    다양한 분야, 다양한 주제의 안전보건 포스터
                  </li>
                  <li className="content_main-li">
                    <img src="/img/main_smart_add.png" alt="smart_add" />
                    무료로 업장에서 자유롭게 사용하세요
                  </li>
                </ul>
              </div>
              <div className="post_slider">
                <Carousel>
                  {imagesPoster.map((src, index) => (
                    <div key={index} className="post_img-rg ">
                      <img src={src} alt={`slide${index + 1}`} />
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
            <div className="margin_t10">
              <a className="sample-cont_down_btn" href="/poster">
                무료 다운로드 받으러 가기 <FaArrowRight className="arrow" />
              </a>
            </div>
          </div>
        </div>
        <div className="post">
          <div className="post__box post_sm1">
            <div className="post__box-flex">
              <div className="content">
                <h2>가려운 곳을 긁어주는 안전보건표지</h2>
                <p>
                  기본적인 안전보건표지부터 실생활,업무에
                  <br />
                  필요한 다양한 종류의 안전보건표지를 <br />
                  자유롭게 다운받아사용하세요.
                </p>
                <ul>
                  <li className="content_main-li">
                    <img src="/img/main_smart_add.png" alt="smart_add" />
                    산업안전보건표지
                  </li>
                  <li className="content_main-li">
                    <img src="/img/main_smart_add.png" alt="smart_add" />
                    ISO 7010
                  </li>
                  <li className="content_main-li">
                    <img src="/img/main_smart_add.png" alt="smart_add" />
                    소소안전 제작 안전표지
                  </li>
                  <li className="content_main-li">
                    <img src="/img/main_smart_add.png" alt="smart_add" />
                    무료로 업장에서 자유롭게 사용하세요
                  </li>
                </ul>
              </div>
              <div className="post_slider">
                <Carousel>
                  {imagesSign.map((src, index) => (
                    <div key={index} className="post_img">
                      <img src={src} alt={`slide${index + 1}`} />
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
            <div className="margin_t10">
              <a className="sample-cont_down_btn" href="/sign">
                무료 다운로드 받으러 가기 <FaArrowRight className="arrow" />
              </a>
            </div>
          </div>
        </div>
        <div className="post">
          <div className="post__box post_sm">
            <div className="post__box-flex">
              <div className="content">
                <h2>내가 찾던 안전표지판 이미지</h2>
                <p>
                  이런 표지판 어디 없나하고 찾아보던
                  <br />
                  다양한 종류의 안전보건표지판을 <br />
                  키워드로 간편하게 찾아보세요.
                </p>
                <ul>
                  <li className="content_main-li">
                    <img src="/img/main_smart_add.png" alt="smart_add" />
                    소소안전 제작 안전 표지판
                  </li>
                  <li className="content_main-li">
                    <img src="/img/main_smart_add.png" alt="smart_add" />
                    키워드로 빠르게 찾아보세요
                  </li>
                  <li className="content_main-li">
                    <img src="/img/main_smart_add.png" alt="smart_add" />
                    안전부터 일상까지 다양한 주제
                  </li>
                  <li className="content_main-li">
                    <img src="/img/main_smart_add.png" alt="smart_add" />
                    무료로 업장에서 자유롭게 사용하세요
                  </li>
                </ul>
              </div>
              <div className="post_slider">
                <Carousel>
                  {imagesEtc.map((src, index) => (
                    <div key={index} className="post_img-rg">
                      <img src={src} alt={`slide${index + 1}`} />
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
            <div className="margin_t10">
              <a className="sample-cont_down_btn" href="/sign">
                무료 다운로드 받으러 가기 <FaArrowRight className="arrow" />
              </a>
            </div>
          </div>
        </div>
        <div className="post">
          <div className="post__box post_sm underscore">
            <div className="post__box-flex">
              <div className="content">
                <h2>링크로 바로 공유하는 안전보건 카드뉴스</h2>
                <p>
                  카드뉴스 이미지를 하나하나 저장하셨나요?
                  <br />
                  필요한 안전보건 카드뉴스를 찾아서 <br />
                  링크로 바로 공유하세요.
                </p>
                <ul>
                  <li className="content_main-li">
                    <img src="/img/main_smart_add.png" alt="smart_add" />
                    이미지 저장없이 링크 바로 공유
                  </li>
                  <li className="content_main-li">
                    <img src="/img/main_smart_add.png" alt="smart_add" />
                    클릭 한번으로 원하는 카드뉴스 링크 복사
                  </li>
                  <li className="content_main-li">
                    <img src="/img/main_smart_add.png" alt="smart_add" />
                    무료로 얼마든지 공유하세요
                  </li>
                </ul>
              </div>
              <div className="post_slider">
                <Carousel>
                  {imagesCard.map((src, index) => (
                    <div key={index} className="post_img">
                      <img src={src} alt={`slide${index + 1}`} />
                    </div>
                  ))}
                </Carousel>
              </div>
            </div>
            <div className="margin_t10">
              <a className="sample-cont_down_btn" href="/cardnews">
                무료 다운로드 받으러 가기 <FaArrowRight className="arrow" />
              </a>
            </div>
          </div>
        </div>
        <div className="smart">
          <div className="smart_cont">
            <h2>중대재해 사이렌 키워드로 모아보기!</h2>
            <p>
              딱 필요한 그 자료 ! 찾으려면 힘들었던 사고사례를<br></br>
              필요한 키워드로 한번에 모아보기
            </p>
            <div className="smart_cont-img">
              <img src="/img/main_acc.png" alt="main_acc" />
            </div>
            <a className="sample-cont_down_btn" href="/seriousdisaster">
              다운로드 받으러 가기 <FaArrowRight className="arrow" />
            </a>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
