import Header from "components/Header";
import CardForm from "components/cardnews/CardForm";

export default function CardNewsNewPage() {
    return (
        <>
            <Header /> 
            <CardForm />
        </>
    );
}