import { useEffect, useState } from "react";
import { FaArrowRightLong } from "react-icons/fa6";
import { Link } from "react-router-dom";

interface itemProps {
    items: {
        category: string;
        content: string;
        doc_id: string;
        highlight_content: string;
        score: number;
        title: string;
        filepath?: string;
    };
}

export default function SmartItem({ items }: itemProps) {
    const [expanded, setExpanded] = useState(false);
    const truncatedContent = "내용 : " + items.content.slice(0, 400);
    const truncatedContentHTML = truncatedContent + (items.content.length > 400 ? '...' : '')
    const fullContent = "내용 : " + items.content;
    const filepath = items?.filepath;

    const toggleExpanded = () => {
        setExpanded(!expanded);
    };

    const getCategoryTitle = (category: string) => {
        switch (category) {
            case "1":
                return "산업안전보건법";
            case "2":
                return "산업안전보건법 시행령";
            case "3":
                return "산업안전보건법 시행규칙";
            case "4":
                return "산업안전보건 기준에 관한 규칙";
            case "5":
                return "고시/훈령/예규";
            case "6":
                return "미디어";
            case "7":
                return "KOSHA GUIDE";
            default:
                return "기타";
        }
    };

    useEffect(() => {
        console.log("useEffect item 실행")
    }, []);

    return (
        <div className="smart__box">
            <div className="smart__top">
                <div className="smart__top-category draggable">구분 : {getCategoryTitle(items.category)}</div>
                <div className="asmart__top-title">제목 : {items.title}</div>

            </div>
            <div className="smart__mid">
                <div className="smart__mid-content draggable" dangerouslySetInnerHTML={{ __html: expanded ? fullContent : truncatedContentHTML }}></div>
                {items?.filepath && items.category === "6" && (
                    <button onClick={() => { window.open(filepath) }}>{items.filepath}</button>
                )}
                {items.content.length > 400 && (
                    <button className="more__btn-white" onClick={toggleExpanded}>
                        {expanded ?
                            <>
                                <FaArrowRightLong className="more__btn-icon" />
                                <div>접기</div>
                            </>
                            :
                            <>
                                <FaArrowRightLong className="more__btn-icon" />
                                <div>더 보기</div>
                            </>
                        }
                    </button>
                )}
            </div>
        </div >
    );
};