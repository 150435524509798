import { useContext, useEffect, useState } from "react";

import { PosterProps } from "pages/posters";
import AuthContext from "context/AuthContext";
import { collection, orderBy, query, where, onSnapshot } from "firebase/firestore";
import { db } from "firevaseApp";
import Header from "components/Header";

export default function SearchPage() {
    const [posters, setPosters] = useState<PosterProps[]>([]);
    const [tagQuery, setTagQuery] = useState<string>("");
    const { user } = useContext(AuthContext);

    const onChange = (e: any) => {
        setTagQuery(e?.target?.value?.trim());
    };

    useEffect(() => {
        if (user) {
            let postersRef = collection(db, "posters");
            let postersQuery = query(
                postersRef, where("hashTags", "array-contains-any", [tagQuery]),
                orderBy("createdAt", "desc")
            );

            onSnapshot(postersQuery, (snapShot) => {
                let dataObj = snapShot?.docs?.map((doc) => ({
                    ...doc?.data(),
                    id: doc?.id,
                }));

                setPosters(dataObj as PosterProps[]);
            });
        }
    }, [tagQuery, user]);

    return (
        <>
            <Header />
            <div className="searchs">
                <div className="searchs__top">
                    <div className="searchs__title">
                        <div className="searchs__title-text">태그 검색</div>
                    </div>
                    <div className="searchs__search-div">
                        <input className="searchs__search" placeholder="해시태크 검색" onChange={onChange} />
                    </div>
                </div>
                <div className="poster">
                    {posters?.length > 0 && tagQuery?.length > 0 ? posters?.map((poster) => (
                        <div></div>
                        // <PosterBox poster={poster} key={poster.id} />
                    )) : (
                        <div className="poster__no-posters">
                            <div className="poster__text">게시글이 없습니다.</div>
                        </div>
                    )}

                </div>
            </div>
        </>
    );
}