import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { collection, addDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import { db, storage } from "firevaseApp";
import AuthContext from "context/AuthContext";

import { v4 as uuidv4 } from 'uuid';

import { FiImage } from "react-icons/fi";

export default function SirenrulesRulesForm(){
    const [hashTag, setHashTag] = useState<string>("");
    const [title, setTitle] = useState<string>("");
    const [tags, setTags] = useState<string[]>([]);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);//중복 이미지 등록 방지
    const [imageFile, setImageFile] = useState<string | null>(null);
    const { user } = useContext(AuthContext); //유저정보 받아오기

    const navigate = useNavigate();

    //이미지 업로드
    const handleFileUpload = (e: any) => {
        const {
            target: { files },
        } = e;

        const file = files?.[0];
        const fileReader = new FileReader();
        fileReader?.readAsDataURL(file);

        fileReader.onloadend = (e: any) => {
            const { result } = e?.currentTarget;
            setImageFile(result);
        };
    };

    const handleDeleteImage = () => {
        setImageFile(null);
    };

    const handleDrop = (event: any) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        const fileReader = new FileReader();

        fileReader.onloadend = (e: any) => {
            const { result } = e?.currentTarget;
            setImageFile(result);
        };

        fileReader.readAsDataURL(file);
    };

    const onSubmit = async (e: any) => {
        e.preventDefault(); //폼이 넘어가지 않도록 설정

        setIsSubmitting(true);
        const key = `${user?.uid}/${uuidv4()}`; //고유 키값
        const storageRef = ref(storage, key);

        try {
            if (!title) {
                toast.success("제목을 입력하세요.");
                setIsSubmitting(false);
            } else if (tags.length < 1) {
                toast.success("태그를 등록하세요.");
                setIsSubmitting(false);
            } else if (!imageFile) {
                toast.success("이미지를 등록하세요.");
                setIsSubmitting(false);
            } else {
                //이미지 먼저 업로드
                let imageUrl = "";
                if (imageFile) {
                    const data = await uploadString(storageRef, imageFile, "data_url");
                    imageUrl = await getDownloadURL(data?.ref);
                }

                //업로드된 이미즈이 다운로드 url 업데이트
                await addDoc(collection(db, 'sirenrules'), {
                    title: title,
                    createdAt: new Date()?.toLocaleDateString("ko", {
                        year: "numeric",
                        month: "2-digit", 
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                        hour12: false,
                    }),
                    uid: user?.uid,
                    email: user?.email,
                    hashTags: tags,
                    imageUrl: imageUrl,
                });
                setTitle("");
                setTags([]);
                setHashTag("");
                setImageFile(null);
                setIsSubmitting(false);
                toast.success("게시물을 등록하였습니다.");
                navigate("/sirenrules")
            }
        } catch (e: any) {
            console.log(e);
        }
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {
            target: { name, value }, //타이핑 찍기
        } = e;

        if (name === "title") {
            setTitle(value);// 입력값 업데이트
        }
    };

    const removeTag = (tag: string) => {
        setTags(tags?.filter((val) => val !== tag));
    };

    const onChangeHashTag = (e: any) => {
        setHashTag(e?.target?.value?.trim());
    };

    const handleKeyUp = (e: any) => {
        if (e.keyCode === 32 && e.target.value.trim() !== "") {
            // 만약 같은 태그가 있다면 에러를 띄운다
            // 아니라면 태그를 생성해준다
            if (tags?.includes(e.target.value?.trim())) {
                toast.error("같은 태그가 있습니다.");
            } else {
                setTags((prev) => (prev?.length > 0 ? [...prev, hashTag] : [hashTag]));
                setHashTag("");
            }
        }
    };

    return (
        <form className="poster-form" onSubmit={onSubmit}>
            <div className="poster-form__title">예방자료 등록</div>
            <input className="poster-form__input" required name="title" id="title" placeholder="타이틀 입력" onChange={onChange} value={title} />
            <div className="poster-form__hashtages">
                <span className="poster-form__hashtages-outputs">
                    {tags?.map((tag, index) => (
                        <span className="poster-form__hashtages-tag" key={index} onClick={() => removeTag(tag)}>#{tag}</span>
                    ))}
                </span>
                <input
                    className="poster-form__hashtages-input"
                    type="text"
                    name="hashteg"
                    id="hashteg"
                    placeholder="태그명 + 스페이스바 입력"
                    onChange={onChangeHashTag}
                    onKeyUp={handleKeyUp}
                    value={hashTag}
                />
            </div>
            <div className="poster-form__submit-input">
                <div className="poster-form__image-input" onDrop={handleDrop} onDragOver={(e) => e.preventDefault()}>
                    {!imageFile && (
                        <label htmlFor="file-input" className="poster-from__file">
                            <FiImage className="poster-from__file-icon" size={500} />
                        </label>
                    )}
                    <input type="file" id="file-input" name="file-input" accept="image/*" onChange={handleFileUpload} className="hidden" />
                    {imageFile && (
                        <div className="poster-form__attachment">
                            <img src={imageFile} alt="attachment" width={300} />
                            <button className="poster-form__clear-btn" type="button" onClick={handleDeleteImage}>
                                이미지 삭제
                            </button>
                        </div>
                    )}
                </div>
                <input type="submit" value={"등록"} className="poster-form__submit-btn" disabled={isSubmitting} />
            </div>
        </form>
    );
};