import { useEffect } from "react";

interface Props {
  items: {
    keyword: string;
    contents: string;
    arno: string;
  };
}

export default function AccidentNewsItem({ items }: Props) {
  const { keyword, contents, arno } = items;

  const extractImgSrc = (htmlContent: string): string[] => {
    const srcRegex = /<img.*?src="(.*?)"/g;
    const srcMatches = htmlContent.matchAll(srcRegex);
    const srcList: string[] = [];
    for (const match of srcMatches) {
      srcList.push(match[1]);
    }
    return srcList;
  };

  // Extract src attributes from contents
  const imgSrcList = extractImgSrc(contents);

  useEffect(() => {
    const handleContextMenu = (event: Event) => {
        event.preventDefault();
    };

    const elements = document.querySelectorAll('img, button');
    elements.forEach(element => {
        element.addEventListener('contextmenu', handleContextMenu);
    });

    return () => {
        elements.forEach(element => {
            element.removeEventListener('contextmenu', handleContextMenu);
        });
    };
}, []);

  return (
    <div className="newspic__box">
      {imgSrcList.map((src, index) => (
        <img key={index} src={src} alt={`Image ${index}`} className="newspic__imgurl-news" />
      ))}
      <div className="newspic__title-sm">{keyword}</div>
    </div>
  );
};