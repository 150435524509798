import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GoogleAuthProvider, getAuth, sendPasswordResetEmail, signInWithEmailAndPassword, signInWithPopup } from "firebase/auth";
import { app } from "firevaseApp";
import { toast } from 'react-toastify';
import Header from "components/Header";
import Footer from "components/Footer";

// const exception1 = process.env.REACT_APP_EXEMPT_EMAILS1;
const exception1 = process.env.REACT_APP_EXEMPT_EMAILS1 || "";
const exception2 = process.env.REACT_APP_EXEMPT_EMAILS2 || "";
const EXEMPT_EMAILS = [exception1, exception2].filter(email => email.trim() !== "");


export default function LoginForm() {
    //useState 목록
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [rememberMe, setRememberMe] = useState(false);
    const navigate = useNavigate();

    const onSubmit = async (e: any) => {
        e.preventDefault(); //해당 폼 미 제출
        try {
            const auth = getAuth(app);//파이어베이스에서 인증 불러오기
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            //아이디 저장
            if (rememberMe) {
                localStorage.setItem("rememberedUserEmail", email);
            } else {
                localStorage.removeItem("rememberedUserEmail");
            };

            // 사용자 상태 최신화
            await user.reload();

            if (EXEMPT_EMAILS.includes(email) || user.emailVerified) {
                navigate(-1);
                toast.success("성공적으로 로그인 되었습니다.");
            } else {
                navigate("/users/verify");
                toast.warn("이메일 인증이 필요합니다.");
            }
        } catch (error: any) {
            toast.error(error?.code);
        }
    };
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // 변경되는 입력 값 받아오기
        const {
            target: { name, value },
        } = e;

        if (name === "email") {
            setEmail(value);
            const validRegex =
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

            if (!value?.match(validRegex)) {
                setError("이메일 형식이 올바르지 않습니다.");
            } else {
                setError("");
            }
        }
        if (name === "password") {
            setPassword(value);

            if (value?.length < 8) {
                setError("비밀번호는 8자리 이상 입력해주세요")
            } else {
                setError("");
            }
        }
    };
    const onClickSocialLogin = async (e: any) => {
        const { name } = e.target;
        const auth = getAuth(app);
        let provider = new GoogleAuthProvider();             

        try {            
            const result = await signInWithPopup(auth, provider);
            console.log(result);
            toast.success("로그인 되었습니다.");
            navigate("/");
        } catch (error: any) {
            console.log(error);
            const errorMessage = error?.message;
            toast.error(errorMessage);

            if (error.code === 'auth/popup-closed-by-user') {
                setError('팝업이 닫혔습니다. 다시 시도해주세요.');
            } else if (error.code === 'auth/cancelled-popup-request') {
                setError('이전에 열린 팝업이 완료되지 않았습니다. 다시 시도해주세요.');
            } else if (error.code === 'auth/unauthorized-domain') {
                setError('허용되지 않은 도메인입니다. Firebase 콘솔에서 허용된 도메인을 설정해주세요.');
            } else {
                setError('로그인 중 오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
            }
        }
    };

    const onForgotPassword = async () => {
        try {
            const auth = getAuth(app);
            await sendPasswordResetEmail(auth, email);
            toast.success("비밀번호 재설정 이메일이 전송되었습니다.");
        } catch (error: any) {
            toast.error(error?.message);
        }
    };

    useEffect(() => {
        const rememberedUserEmail = localStorage.getItem("rememberedUserEmail");
        if (rememberedUserEmail) {
            setEmail(rememberedUserEmail);
            setRememberMe(true);
        }
    }, []);

    return (
        <>
            <Header />
            <form className="form form--lg" onSubmit={onSubmit}>
                <div className="form__title">로그인</div>
                <div className="form__content-box">
                    <div className="form__content-top">소소안전을 찾아주셔서 감사합니다.</div>
                    <div className="form__content-bot">로그인하고 다양한 소소안전의 컨텐츠를 이용해보세요.</div>
                </div>
                <div className="form__block">
                    <label htmlFor="email">이메일</label>
                    <input type="text" name="email" id="email" value={email} required onChange={onChange} placeholder="이메일 주소를 입력해주세요." />
                    <label className="form__block-check">
                        <input type="checkbox" checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />
                        이메일 기억하기
                    </label>
                </div>
                <div className="form__block">
                    <label htmlFor="password">비밀번호</label>
                    <input type="password" name="password" id="password" value={password} required onChange={onChange} placeholder="비밀번호를 입력해주세요." />
                </div>

                {error && error?.length > 0 && (
                    <div className="form__block">
                        <div className="form__error">{error}</div>
                    </div>
                )}
                <div className="form__block--b">
                    비밀번호를 잊으셨나요?
                    <Link to="/users/forgot-password" className="form__link">
                        비밀번호 찾기
                    </Link>
                </div>

                <div className="form__block--bb">
                    계정이 없으신가요?
                    <Link to="/users/signup" className="form__link">
                        회원가입하기
                    </Link>
                </div>


                <div className="form__block--lg">
                    <button type="submit" className="form__btn--submit" disabled={error?.length > 0}>
                        로그인
                    </button>
                </div>
                <div className="form__block--lg">
                    <button type="button" name="google" className="form__btn--google" onClick={onClickSocialLogin}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 33 34" fill="none" className="google_icon">
                            <path d="M28.0994 29.799C32.0079 26.151 33.745 20.0711 32.7028 14.2517H17.1555V20.6791H26.0148C25.6674 22.7636 24.4514 24.5007 22.7143 25.6299L28.0994 29.799Z" fill="#4285F4" />
                            <path d="M2.38998 24.6746C3.53257 26.9254 5.1712 28.8875 7.18234 30.4129C9.19349 31.9382 11.5247 32.9872 14.0002 33.4806C16.4756 33.974 19.0309 33.8991 21.4732 33.2614C23.9155 32.6236 26.1811 31.4398 28.0994 29.7992L22.7143 25.6301C18.1109 28.67 10.4676 27.5409 7.86191 20.4187L2.38998 24.6746Z" fill="#34A853" />
                            <path d="M7.86191 20.4187C7.16706 18.2473 7.16706 16.2496 7.86191 14.0782L2.38998 9.82227C0.392289 13.8176 -0.215704 19.4633 2.38998 24.6747L7.86191 20.4187Z" fill="#FBBC02" />
                            <path d="M7.86191 14.078C9.77274 8.08493 17.9372 4.61069 23.4092 9.7352L28.1862 5.04497C21.4115 -1.46924 8.20933 -1.20867 2.38998 9.82205L7.86191 14.078Z" fill="#EA4335" />
                        </svg>Google로 로그인
                    </button>
                </div>
            </form>
            <Footer />
        </>
    );
}