import Header from "components/Header";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  collection,
  query,
  onSnapshot,
  orderBy,
  where,
  limit,
  startAfter,
  getDocs,
} from "firebase/firestore";
import AuthContext from "context/AuthContext";
import { db } from "firevaseApp";

import { IoSearch } from "react-icons/io5";
import Footer from "components/Footer";
import SignBox from "components/signs/SignBox";
import { FaArrowRight, FaArrowRightLong } from "react-icons/fa6";
import { AiFillHeart } from "react-icons/ai";
import React from "react";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import { useSwipeable } from "react-swipeable";
import { Helmet } from "react-helmet-async";
import Loader from "components/loader/Loader";
import AdComponent from "components/AdSense";
import PopupManager from "components/popup/PopupManager";

export interface SignProps {
  id: string;
  email: string;
  title: string;
  createdAt: string;
  uid: string;
  admin?: boolean;
  profileUrl?: string;
  likes?: string[];
  likeCount?: number;
  hashTags?: string[];
  imageUrl?: string;
  views?: number;
  downCount?: number;
  currentPage?: number;
}

//표지
const imagesSign = [
  {
    img: "/img/main_sign_1.png",
    id: "mCrASwxvry2ANI5lBxzW",
  },
  {
    img: "/img/main_sign_2.png",
    id: "WEwtBiyZgX5423Dm0npE",
  },
  {
    img: "/img/main_sign_3.png",
    id: "j0gkQL5rcOkLCzqMuw66",
  },
  {
    img: "/img/main_sign_4.png",
    id: "UoXk3X9gfvohoJOnmP8S",
  },
  {
    img: "/img/main_sign_5.png",
    id: "fDQXaobeI8mVspJe1hVy",
  },
];
const MAX_VISIBILITY = 3;

const Carousel: React.FC<{ children: React.ReactNode[] }> = ({ children }) => {
  const [active, setActive] = useState(0);
  const count = React.Children.count(children);

  const handlers = useSwipeable({
    onSwipedLeft: () => setActive(active < count - 1 ? active + 1 : active),
    onSwipedRight: () => setActive(active > 0 ? active - 1 : active),
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return (
    <div className="carousel" {...handlers}>
      {active > 0 && (
        <button className="nav left" onClick={() => setActive(active - 1)}>
          <IoIosArrowDropleftCircle />
        </button>
      )}
      {React.Children.map(children, (child, i) => (
        <div
          className="card-container"
          style={
            {
              "--active": i === active ? 1 : 0,
              "--offset": (active - i) / 3,
              "--direction": Math.sign(active - i),
              "--abs-offset": Math.abs(active - i) / 3,
              pointerEvents: active === i ? "auto" : "none",
              opacity: Math.abs(active - i) >= MAX_VISIBILITY ? "0" : "1",
              display: Math.abs(active - i) > MAX_VISIBILITY ? "none" : "block",
            } as React.CSSProperties
          }
        >
          {child}
        </div>
      ))}
      {active < count - 1 && (
        <button className="nav right" onClick={() => setActive(active + 1)}>
          <IoIosArrowDroprightCircle />
        </button>
      )}
    </div>
  );
};

type TabType =
  | ""
  | "ISO7010"
  | "금지표지"
  | "지시표지"
  | "넘어짐"
  | "고소작업대"
  | "찜";

export default function SignsPage() {
  const exception1 = process.env.REACT_APP_ADMIN_EMAIL1 || "";
  const exception2 = process.env.REACT_APP_ADMIN_EMAIL2 || "";
  const [signs, setSigns] = useState<SignProps[]>([]);
  const [lastVisible, setLastVisible] = useState<any>(null); // 마지막으로 로드된 문서 추적
  const { user } = useContext(AuthContext);
  const [tagQuery, setTagQuery] = useState<string>(""); // 검색창의 input
  const [filter, setFilter] = useState<string>("createdAt"); // 초기 필터
  const [activeTab, setActiveTab] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const itemsPerPage = 9; // 페이지당 표시할 항목 수

  // 검색 관련
  const sanitizeInput = (input: string): string => {
    // 특수 문자 제거 및 길이 제한 (최대 10자)
    return input.replace(/[<>\/]/g, "").substring(0, 10);
  };

  // const onChangeFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //     setFilter(e.target.value); // 입력되는 값을 filter로 저장
  // };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTagQuery(sanitizeInput(e.target.value)); // 검색창에 입력되는 값을 tagQuery로 저장
  };

  const onClickFilter = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const value = sanitizeInput(e.currentTarget.value) as TabType;

    if (activeTab === value) {
      setActiveTab("");
      setTagQuery("");
      handleSearch("");
    } else {
      setActiveTab(value);
      setTagQuery(value);
      handleSearch(value);
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const sanitizedQuery = sanitizeInput(e.currentTarget.value);
      if (activeTab === sanitizedQuery) {
        setActiveTab("");
        setTagQuery("");
        handleSearch("");
      } else {
        setActiveTab(sanitizedQuery);
        setTagQuery(sanitizedQuery);
        handleSearch(sanitizedQuery);
      }
    }
  };

  const onClickTag = (tag: string) => {
    const sanitizedQuery = sanitizeInput(tag);
    if (activeTab === sanitizedQuery) {
      setActiveTab("");
      setTagQuery("");
      handleSearch("");
    } else {
      setActiveTab(sanitizedQuery);
      setTagQuery(sanitizedQuery);
      handleSearch(sanitizedQuery);
    }
  };

  // 검색
  const handleSearch = async (tagQuery: string) => {
    let postRef = collection(db, "signs"); // 정보 가져오기 레퍼런스
    let postQuery;
    setLoading(true);
    if (tagQuery === "찜" && user?.uid) {
      postQuery = query(
        postRef,
        where("likes", "array-contains", user.uid),
        orderBy(filter, "desc"),
        limit(itemsPerPage)
      );
    } else if (tagQuery) {
      postQuery = query(
        postRef,
        where("hashTags", "array-contains-any", [tagQuery]),
        orderBy(filter, "desc"),
        limit(itemsPerPage)
      );
    } else {
      postQuery = query(postRef, orderBy(filter, "desc"), limit(itemsPerPage));
      window.scrollTo({ top: 0, behavior: "smooth" }); // 페이지 맨 위로 스크롤
    }

    const unsubscribe = onSnapshot(postQuery, (snapShot) => {
      let lastVisible = snapShot.docs[snapShot.docs.length - 1];
      let dataObj = snapShot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setSigns(dataObj as SignProps[]);
      setLastVisible(lastVisible);
      // 강제로 1초 지연
      setTimeout(() => {
        setLoading(false);
      }, 500);
    });
    window.scrollTo({ top: 600, behavior: "smooth" }); // 페이지 맨 위로 스크롤
    return () => unsubscribe();
  };

  const loadMore = async () => {
    if (!lastVisible) return;

    let postRef = collection(db, "signs");
    let postQuery;
    if (tagQuery === "찜" && user?.uid) {
      postQuery = query(
        postRef,
        where("likes", "array-contains", user.uid),
        orderBy(filter, "desc"),
        startAfter(lastVisible),
        limit(itemsPerPage)
      );
    } else if (tagQuery) {
      postQuery = query(
        postRef,
        where("hashTags", "array-contains-any", [tagQuery]),
        orderBy(filter, "desc"),
        startAfter(lastVisible),
        limit(itemsPerPage)
      );
    } else {
      postQuery = query(
        postRef,
        orderBy(filter, "desc"),
        startAfter(lastVisible),
        limit(itemsPerPage)
      );
    }

    const snapShot = await getDocs(postQuery); // getDocs로 변경
    let newLastVisible = snapShot.docs[snapShot.docs.length - 1] || null;
    let dataObj = snapShot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    })) as SignProps[];

    setSigns((prevPosters) => {
      const updatedPosters = [...prevPosters, ...dataObj].reduce(
        (acc, curr) => {
          if (!acc.find((item) => item.id === curr.id)) {
            acc.push(curr);
          }
          return acc;
        },
        [] as SignProps[]
      );
      return updatedPosters;
    });
    setLastVisible(newLastVisible);
  };

  const handleToggleLike = (updatedSign: SignProps) => {
    setSigns((prevSigns) =>
      prevSigns.map((sign) => (sign.id === updatedSign.id ? updatedSign : sign))
    );
  };

  useEffect(() => {
    handleSearch(tagQuery);
    window.scrollTo({ top: -100, behavior: "smooth" }); // 페이지 맨 위로 스크롤
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="다양한 필요를 충족하는 안전보건표지"
        />
        <meta property="og:title" content="소소안전" />
        <meta
          property="og:description"
          content="다양한 필요를 충족하는 안전보건표지"
        />
        <meta
          property="og:image"
          content="https://sososafety.shop/main_slider.png"
        />
        <meta property="og:url" content="https://sososafety.shop/sign" />
        <meta property="og:type" content="website" />
      </Helmet>
      {/* 팝업을 관리하는 컴포넌트 */}
      <PopupManager />
      <Header />
      <div className="posters">
        <div className="posters__noti">
          <div className="post__signs">
            <div className="post__signs-box">
              <div className="post__signs-box-flex">
                <div className="content">
                  <h3>다양한 필요를 충족하는 안전보건표지</h3>
                  <p>
                    기본적인 안전보건표지부터 실생활, 업무에<br></br>
                    필요한 안전보건표지를 무료로 다운받아 사용하세요.
                  </p>
                  <ul>
                    <li className="content_poster-li">
                      <img src="\img\main_smart_add.png" alt="smart_add" />
                      산업안전보건표지
                    </li>
                    <li className="content_poster-li">
                      <img src="\img\main_smart_add.png" alt="smart_add" />
                      ISO 7010
                    </li>
                    <li className="content_poster-li">
                      <img src="\img\main_smart_add.png" alt="smart_add" />
                      소소안전 제작 안전표지
                    </li>
                    <li className="content_poster-li">
                      <img src="\img\main_smart_add.png" alt="smart_add" />
                      무료로 업장에서 자유롭게 사용하세요
                    </li>
                  </ul>
                </div>
                <div className="post_slider">
                  <Carousel>
                    {imagesSign.map((src, index) => (
                      <Link
                        to={`/sign/${src?.id}`}
                        key={src?.id}
                        onMouseDown={(e) => e.preventDefault()}
                        onDragStart={(e) => e.preventDefault()}
                      >
                        <div key={index} className="post_slider_img">
                          <img
                            src={src?.img}
                            alt={`slide${index + 1}`}
                            draggable="false"
                          />
                        </div>
                      </Link>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="posters__title">안전보건 표지</div>
        {(user?.uid === exception1 || user?.uid === exception2) && (
          <div>
            <Link to={"/sign/new"} className="posters__new">
              안전표지 등록
            </Link>
            <div>{signs.length}</div>
          </div>
        )}
        <div className="search__box">
          <div className="searchs__top">
            <div className="searchs__search-div">
              <div className="searchs__box">
                <input
                  type="text"
                  className="searchs__search"
                  value={tagQuery}
                  placeholder="키워드를 검색하세요."
                  onChange={onChange}
                  onKeyUp={handleKeyUp}
                />
                <button
                  type="button"
                  value={tagQuery}
                  onClick={onClickFilter}
                  className="searchs__search-btn"
                >
                  <IoSearch
                    color="white"
                    size={25}
                    className="searchs__search-btnimg"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="tags">
          <div className="tags__box">
            <button
              type="button"
              value={"ISO7010"}
              onClick={onClickFilter}
              className={`tags_tag ${
                activeTab === "ISO7010" && "tags_tag--active"
              }`}
            >
              ISO7010
            </button>
            <button
              type="button"
              value={"금지표지"}
              onClick={onClickFilter}
              className={`tags_tag ${
                activeTab === "금지표지" && "tags_tag--active"
              }`}
            >
              금지표지
            </button>
            <button
              type="button"
              value={"지시표지"}
              onClick={onClickFilter}
              className={`tags_tag ${
                activeTab === "지시표지" && "tags_tag--active"
              }`}
            >
              지시표지
            </button>
            <button
              type="button"
              value={"넘어짐"}
              onClick={onClickFilter}
              className={`tags_tag ${
                activeTab === "넘어짐" && "tags_tag--active"
              }`}
            >
              넘어짐
            </button>
            <button
              type="button"
              value={"고소작업대"}
              onClick={onClickFilter}
              className={`tags_tag ${
                activeTab === "고소작업대" && "tags_tag--active"
              }`}
            >
              고소작업대
            </button>
            <button
              type="button"
              value={"찜"}
              onClick={onClickFilter}
              className={`tags_tag ${activeTab === "찜" && "tags_tag--active"}`}
            >
              <AiFillHeart /> {/* Add heart icon */}
            </button>
          </div>
        </div>
        {/* poster list */}
        {loading ? (
          <>
            <div className="loader_gap">
              <Loader />
              <div className="loader_gap-title">
                안전을 위해 열심히 검색 중..
              </div>
            </div>
          </>
        ) : (
          <div className="poster">
            <div className="addsense_smart">
            <AdComponent />
            </div>
            {signs?.length > 0 ? (
              signs.map((sign) => (
                <SignBox
                  sign={sign}
                  key={sign.id}
                  onClickTag={onClickTag}
                  currentPage={1}
                  onToggleLike={handleToggleLike}
                />
              ))
            ) : (
              <div className="poster__no-posters">
                <div className="poster__text">게시글이 없습니다.</div>
              </div>
            )}
          </div>
        )}
        {lastVisible && signs.length >= itemsPerPage && (
          <button className="more__btn" onClick={loadMore}>
            <FaArrowRightLong className="more__btn-icon" />
            <div>더 보기</div>
          </button>
        )}
      </div>
      <Footer />
    </>
  );
}
