import Header from "components/Header";
import NewsReportForm from "components/newsreport/NewsReportForm";


export default function NewsReportNewPage() {
    return (
        <>
            <Header /> 
            <NewsReportForm />
        </>
    );
}