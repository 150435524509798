import React, { useEffect, useState } from 'react';
import Footer from 'components/Footer';
import Header from 'components/Header';
import { collection, getCountFromServer } from 'firebase/firestore';
import { db } from 'firevaseApp';

// Define the type for the counts state
interface Counts {
    posters: number;
    cards: number;
    etcs: number;
    newspics: number;
    seriousDisasters: number;
    signs: number;
    sirenrules: number;
}

export default function Administrator() {
    const [counts, setCounts] = useState<Counts>({
        posters: 0,
        cards: 0,
        etcs: 0,
        newspics: 0,
        seriousDisasters: 0,
        signs: 0,
        sirenrules: 0
    });

    const fetchCounts = async () => {
        const collectionNames = [
            'posters', 'cards', 'etcs', 'newspics', 
            'seriousDisasters', 'signs', 'sirenrules'
        ];

        try {
            const promises = collectionNames.map(async (name) => {
                const coll = collection(db, name);
                const snapshot = await getCountFromServer(coll);
                return { name, count: snapshot.data().count };
            });

            const results = await Promise.all(promises);

            const newCounts = results.reduce((acc, { name, count }) => {
                acc[name as keyof Counts] = count; // Type assertion
                return acc;
            }, {} as Counts);

            setCounts(newCounts);
        } catch (error) {
            console.error("Error getting counts: ", error);
        }
    };

    return (
        <>
            <Header />
            <div className="posters">
                <div className="admin">
                    <h3>데이터 확인</h3>
                    <button onClick={fetchCounts}>데이터 업데이트 버튼</button>
                    <div className='admin__box'>
                        <div className='admin__title'>중대재해</div>
                        <div className='admin__cont'>사이렌 : <span>{counts.seriousDisasters}</span>개</div>
                        <div className='admin__cont'>예방자료 : <span>{counts.sirenrules}</span>개</div>
                    </div>
                    <div className='admin__box'>
                        <div className='admin__title'>칼퇴자료</div>
                        <div className='admin__cont'>안전보건표지 : <span>{counts.signs}</span>개</div>
                        <div className='admin__cont'>포스터 : <span>{counts.posters}</span>개</div>
                        <div className='admin__cont'>카드뉴스 : <span>{counts.cards}</span>개</div>
                        <div className='admin__cont'>기타자료 : <span>{counts.etcs}</span>개</div>
                    </div>
                    <div className='admin__box'>
                        <div className='admin__title'>안전뉴스</div>
                        <div className='admin__cont'>뉴스 : <span>{counts.newspics}</span>개</div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
