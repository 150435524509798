import axios from "axios";
import { useEffect, useState } from "react";
import { styled } from "styled-components";
import SmartItem from "./SmartItem";
import { IoSearch } from "react-icons/io5";
import Loader from "components/loader/Loader";
import { FaArrowRightLong } from "react-icons/fa6";
import { AiFillHeart } from "react-icons/ai";
import { Helmet } from "react-helmet-async";
import AdComponent from "components/AdSense";

export interface itemsProps {
  category: string;
  content: string;
  doc_id: string;
  highlight_content: string;
  score: number;
  title: string;
  filepath?: string;
}

// const NewsListBlock = styled.div`
//   box-sizing: border-box;
//   padding-bottom: 1rem;
//   width: 100%;
//   margin: 0 auto;
//   margin-top: 1rem;
// `;

const LoadMoreButton = styled.button`
  display: block;
  margin: 0 auto;
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  border: 1px solid #333;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
`;

type TabType =
  | ""
  | "보호구"
  | "관리감독자"
  | "사업주"
  | "산업재해"
  | "도급"
  | "산업안전보건관리비";

export default function SmartList() {
  const SERVICE_KEY = process.env.REACT_APP_DATA_KEY;
  const [pageNo, setPageNo] = useState<number>(1);
  const [rows, setRows] = useState<number>(10);
  const [value, setValue] = useState<string>("");
  const [value1, setValue1] = useState<string>("");
  const [category, setCategory] = useState<string>("0");
  const [safetyAct, setSafetyAct] = useState<itemsProps[] | null>(null);
  const [safetyActED, setSafetyActED] = useState<itemsProps[] | null>(null);
  const [safetyActER, setSafetyActER] = useState<itemsProps[] | null>(null);
  const [safetyRoS, setSafetyRoS] = useState<itemsProps[] | null>(null);
  const [noti, setNoti] = useState<itemsProps[] | null>(null);
  const [media, setMediat] = useState<itemsProps[] | null>(null);
  const [kosha, setKosha] = useState<itemsProps[] | null>(null);
  const [totalItems, setTotalItems] = useState<itemsProps[] | null>(null);
  const [totalItems1, setTotalItems1] = useState<itemsProps[] | null>(null);
  const [totalItems2, setTotalItems2] = useState<itemsProps[] | null>(null);
  const [totalItems3, setTotalItems3] = useState<itemsProps[] | null>(null);
  const [totalItems4, setTotalItems4] = useState<itemsProps[] | null>(null);
  const [totalItems5, setTotalItems5] = useState<itemsProps[] | null>(null);
  const [totalItems6, setTotalItems6] = useState<itemsProps[] | null>(null);
  const [totalItems7, setTotalItems7] = useState<itemsProps[] | null>(null);
  const [visibleItems1, setVisibleItems1] = useState<itemsProps[] | null>(null);
  const [visibleItems2, setVisibleItems2] = useState<itemsProps[] | null>(null);
  const [visibleItems3, setVisibleItems3] = useState<itemsProps[] | null>(null);
  const [visibleItems4, setVisibleItems4] = useState<itemsProps[] | null>(null);
  const [visibleItems5, setVisibleItems5] = useState<itemsProps[] | null>(null);
  const [visibleItems6, setVisibleItems6] = useState<itemsProps[] | null>(null);
  const [visibleItems7, setVisibleItems7] = useState<itemsProps[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [activeTab, setActiveTab] = useState<string>("");

  const URL = `https://apis.data.go.kr/B552468/srch/smartSearch?serviceKey=${SERVICE_KEY}&pageNo=${pageNo}&numOfRows=${rows}&searchValue=${value}&category=${category}`;

  const sanitizeInput = (input: string): string => {
    // 특수 문자 제거 및 길이 제한 (최대 10자)
    return input.replace(/[<>\/]/g, "").substring(0, 10);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue1(sanitizeInput(e?.target?.value)); //검색창에 입력되는 값을 tagQuery로 저장
  };

  const onClickFilter = async (e: any) => {
    const value = sanitizeInput(e.currentTarget.value);

    if (activeTab === value) {
      setActiveTab("");
      setValue("");
      setValue1("");
    } else {
      setActiveTab(value);
      setValue(value);
      setValue1(value);
    }
    console.log(value);
  };

  const handleKeyUp = async (e: any) => {
    if (e.keyCode === 13 && e.target.value.trim() !== "") {
      const value = sanitizeInput(e.currentTarget.value);
      if (activeTab === value) {
        setActiveTab("");
        setValue("");
        setValue1("");
      } else {
        setActiveTab(value);
        setValue(value);
        setValue1(value);
      }
      console.log(value);
    }
  };

  useEffect(() => {
    async function fetchData(): Promise<void> {
      setLoading(true);

      if (value === "") {
        console.log("최초 useEffect");
        setLoading(false);
        setSearch(false);
        window.scrollTo({ top: -100, behavior: "smooth" }); // 페이지 맨 위로 스크롤
      } else {
        try {
          console.log("검색 useEffect");
          setLoading(true);
          const response = await axios.get(URL);
          const newItems = response.data.response.body.items.item;
          const sortedNewItems1 = newItems.filter(
            (item: itemsProps) => item.category === "1"
          );
          const safetyActNewItems = sortedNewItems1.sort(
            (a: itemsProps, b: itemsProps) => {
              if (a.doc_id < b.doc_id) return -1;
              if (a.doc_id > b.doc_id) return 1;
              return 0;
            }
          );
          const sortedNewItems2 = newItems.filter(
            (item: itemsProps) => item.category === "2"
          );
          const safetyActEDNewItems = sortedNewItems2.sort(
            (a: itemsProps, b: itemsProps) => {
              if (a.doc_id < b.doc_id) return -1;
              if (a.doc_id > b.doc_id) return 1;
              return 0;
            }
          );
          const sortedNewItems3 = newItems.filter(
            (item: itemsProps) => item.category === "3"
          );
          const safetyActERNewItems = sortedNewItems3.sort(
            (a: itemsProps, b: itemsProps) => {
              if (a.doc_id < b.doc_id) return -1;
              if (a.doc_id > b.doc_id) return 1;
              return 0;
            }
          );
          const sortedNewItems4 = newItems.filter(
            (item: itemsProps) => item.category === "4"
          );
          const safetyRoSNewItems = sortedNewItems4.sort(
            (a: itemsProps, b: itemsProps) => {
              if (a.doc_id < b.doc_id) return -1;
              if (a.doc_id > b.doc_id) return 1;
              return 0;
            }
          );
          const sortedNewItems5 = newItems.filter(
            (item: itemsProps) => item.category === "5"
          );
          const notiNewItems = sortedNewItems5.sort(
            (a: itemsProps, b: itemsProps) => {
              if (a.doc_id < b.doc_id) return -1;
              if (a.doc_id > b.doc_id) return 1;
              return 0;
            }
          );
          const sortedNewItems6 = newItems.filter(
            (item: itemsProps) => item.category === "6"
          );
          const mediaNewItems = sortedNewItems6.sort(
            (a: itemsProps, b: itemsProps) => {
              if (a.doc_id < b.doc_id) return -1;
              if (a.doc_id > b.doc_id) return 1;
              return 0;
            }
          );
          const sortedNewItems7 = newItems.filter(
            (item: itemsProps) => item.category === "7"
          );
          const koshaNewItems = sortedNewItems7.sort(
            (a: itemsProps, b: itemsProps) => {
              if (a.doc_id < b.doc_id) return -1;
              if (a.doc_id > b.doc_id) return 1;
              return 0;
            }
          );
          setTotalItems(newItems);

          setTotalItems1(sortedNewItems1);
          setSafetyAct(safetyActNewItems);
          setVisibleItems1(safetyActNewItems.slice(0, 2));

          setTotalItems2(sortedNewItems2);
          setSafetyActED(safetyActEDNewItems);
          setVisibleItems2(safetyActEDNewItems.slice(0, 2));

          setTotalItems3(sortedNewItems3);
          setSafetyActER(safetyActERNewItems);
          setVisibleItems3(safetyActERNewItems.slice(0, 2));

          setTotalItems4(sortedNewItems4);
          setSafetyRoS(safetyRoSNewItems);
          setVisibleItems4(safetyRoSNewItems.slice(0, 2));

          setTotalItems5(sortedNewItems5);
          setNoti(notiNewItems);
          setVisibleItems5(notiNewItems.slice(0, 2));

          setTotalItems6(sortedNewItems6);
          setMediat(mediaNewItems);
          setVisibleItems6(mediaNewItems.slice(0, 2));

          setTotalItems7(sortedNewItems7);
          setKosha(koshaNewItems);
          setVisibleItems7(koshaNewItems.slice(0, 2)); // 초기에 3개만 보이도록 설정
        } catch (error) {
          setError("데이터를 불러오는 중 오류가 발생했습니다.");
        }
        setLoading(false);
        setSearch(true);
        window.scrollTo({ top: 700, behavior: "smooth" }); // 페이지 맨 위로 스크롤
      }
    }
    fetchData();
    console.log("useEffect List 실행");
  }, [value]);

  //더보기 버튼
  const handleLoadMore = (category: string) => {
    let nextIndex: number;
    switch (category) {
      case "1":
        nextIndex = (visibleItems1 ? visibleItems1.length : 0) + 10;
        setVisibleItems1(totalItems1?.slice(0, nextIndex) || null);
        break;
      case "2":
        nextIndex = (visibleItems2 ? visibleItems2.length : 0) + 10;
        setVisibleItems2(totalItems2?.slice(0, nextIndex) || null);
        break;
      case "3":
        nextIndex = (visibleItems3 ? visibleItems3.length : 0) + 10;
        setVisibleItems3(totalItems3?.slice(0, nextIndex) || null);
        break;
      case "4":
        nextIndex = (visibleItems4 ? visibleItems4.length : 0) + 10;
        setVisibleItems4(totalItems4?.slice(0, nextIndex) || null);
        break;
      case "5":
        nextIndex = (visibleItems5 ? visibleItems5.length : 0) + 10;
        setVisibleItems5(totalItems5?.slice(0, nextIndex) || null);
        break;
      case "6":
        nextIndex = (visibleItems6 ? visibleItems6.length : 0) + 10;
        setVisibleItems6(totalItems6?.slice(0, nextIndex) || null);
        break;
      case "7":
        nextIndex = (visibleItems7 ? visibleItems7.length : 0) + 10;
        setVisibleItems7(totalItems7?.slice(0, nextIndex) || null);
        break;
      default:
        break;
    }
  };

   return (
    <>
      <Helmet>
        <meta name="description" content="불편했던 법령 검색과 공단자료 찾기" />
        <meta property="og:title" content="소소안전" />
        <meta
          property="og:description"
          content="불편했던 법령 검색과 공단자료 찾기"
        />
        <meta
          property="og:image"
          content="https://sososafety.shop/main_slider.png"
        />
        <meta
          property="og:url"
          content="https://sososafety.shop/seriousdisaster"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="posters">
        <div className="posters__noti">
          <div className="smart">
            <div className="smart_cont">
              <h3 className="smart_cont-title">
                불편했던 법령 검색과 공단 자료 찾기!
              </h3>
              <p className="smart_cont-title2">
                키워드 하나로 산업안전보건 법령 및 공단자료까지 한번에 검색
              </p>
              <div className="smart_cont-flex">
                <div className="smart_cont-main-img">
                  <img src="\img\main_smart_1.png" alt="main_smart_1" />
                </div>
                <ul>
                  <li className="smart_cont-li">
                    <img src="\img\main_smart_add.png" alt="smart_add" />{" "}
                    산업안전보건 법령
                  </li>
                  <li className="smart_cont-li">
                    <img src="\img\main_smart_add.png" alt="smart_add" /> 고시 /
                    훈령 / 예규
                  </li>
                  <li className="smart_cont-li">
                    <img src="\img\main_smart_add.png" alt="smart_add" /> KOSHA
                    GUID
                  </li>
                  <li className="smart_cont-li">
                    <img src="\img\main_smart_add.png" alt="smart_add" />{" "}
                    안전보건공단 미디어 자료
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="posters__title">안전보건 스마트 검색</div>
        <div className="search__box">
          <div className="searchs__top">
            <div className="searchs__search-div">
              <div className="searchs__box">
                <input
                  type="text"
                  className="searchs__search"
                  value={value1}
                  placeholder="키워드를 검색하세요."
                  onChange={onChange}
                  onKeyUp={handleKeyUp}
                  disabled={loading}
                />
                <button
                  type="button"
                  value={value1}
                  onClick={onClickFilter}
                  className="searchs__search-btn"
                  disabled={loading}
                >
                  <IoSearch
                    color="white"
                    size={25}
                    className="searchs__search-btnimg"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="tags">
          <div className="tags__box">
            <button
              type="button"
              value={"보호구"}
              onClick={onClickFilter}
              className={`tags_tag ${
                activeTab === "보호구" && "tags_tag--active"
              }`}
              disabled={loading}
            >
              보호구
            </button>
            <button
              type="button"
              value={"관리감독자"}
              onClick={onClickFilter}
              className={`tags_tag ${
                activeTab === "관리감독자" && "tags_tag--active"
              }`}
              disabled={loading}
            >
              관리감독자
            </button>
            <button
              type="button"
              value={"사업주"}
              onClick={onClickFilter}
              className={`tags_tag ${
                activeTab === "사업주" && "tags_tag--active"
              }`}
              disabled={loading}
            >
              사업주
            </button>
            <button
              type="button"
              value={"산업재해"}
              onClick={onClickFilter}
              className={`tags_tag ${
                activeTab === "산업재해" && "tags_tag--active"
              }`}
              disabled={loading}
            >
              산업재해
            </button>
            <button
              type="button"
              value={"도급"}
              onClick={onClickFilter}
              className={`tags_tag ${
                activeTab === "도급" && "tags_tag--active"
              }`}
              disabled={loading}
            >
              도급
            </button>
            <button
              type="button"
              value={"산업안전보건관리비"}
              onClick={onClickFilter}
              className={`tags_tag ${
                activeTab === "산업안전보건관리비" && "tags_tag--active"
              }`}
              disabled={loading}
            >
              산업안전보건관리비
            </button>
          </div>
        </div>
        {/* {!loading && search && (
                <div className="searchs__search-mid">{`'${value}' 검색 결과 총 ${totalItems ? totalItems.length : 0} 건`}</div>
            )} */}
        <div className="smarts__box">
          <div className="addsense_smart">
          <AdComponent />
          </div>
          <div className="smarts__box-division">
            {loading ? (
              <>
                <div className="loader_gap">
                  <Loader />
                  <div className="loader_gap-title">
                    안전을 위해 열심히 검색 중..
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="smarts__count">
                  {search ? (
                    <div className="smarts__title">
                      산업안전보건법({totalItems1?.length})
                    </div>
                  ) : (
                    <div className="smarts__title">산업안전보건법</div>
                  )}
                  <div className="newslistblock">
                    {visibleItems1 && visibleItems1.length > 0 ? (
                      visibleItems1?.map((items: itemsProps, index: number) => (
                        <SmartItem key={index} items={items} />
                      ))
                    ) : (
                      <div className="poster__no-posters">
                        {search ? (
                          <div className="poster__text">
                            검색 결과가 없습니다
                          </div>
                        ) : (
                          <div className="poster__text">
                            키워드를 검색해주세요
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {totalItems1 &&
                    visibleItems1 &&
                    visibleItems1.length < totalItems1.length && (
                      <button
                        className="more__btn"
                        onClick={() => handleLoadMore("1")}
                      >
                        <FaArrowRightLong className="more__btn-icon" />
                        <div>더 보기</div>
                      </button>
                    )}
                </div>
                <div className="smarts__count">
                  {search ? (
                    <div className="smarts__title">
                      산업안전보건법 시행령({totalItems2?.length})
                    </div>
                  ) : (
                    <div className="smarts__title">산업안전보건법 시행령</div>
                  )}

                  <div className="newslistblock">
                    {visibleItems2 && visibleItems2.length > 0 ? (
                      visibleItems2?.map((items: itemsProps, index: number) => (
                        <SmartItem key={index} items={items} />
                      ))
                    ) : (
                      <div className="poster__no-posters">
                        {search ? (
                          <div className="poster__text">
                            검색 결과가 없습니다
                          </div>
                        ) : (
                          <div className="poster__text">
                            키워드를 검색해주세요
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {totalItems2 &&
                    visibleItems2 &&
                    visibleItems2.length < totalItems2.length && (
                      <button
                        className="more__btn"
                        onClick={() => handleLoadMore("2")}
                      >
                        <FaArrowRightLong className="more__btn-icon" />
                        <div>더 보기</div>
                      </button>
                    )}
                </div>
                <div className="smarts__count">
                  {search ? (
                    <div className="smarts__title">
                      산업안전보건법 규칙({totalItems3?.length})
                    </div>
                  ) : (
                    <div className="smarts__title">산업안전보건법 규칙</div>
                  )}
                  <div className="newslistblock">
                    {visibleItems3 && visibleItems3.length > 0 ? (
                      visibleItems3?.map((items: itemsProps, index: number) => (
                        <SmartItem key={index} items={items} />
                      ))
                    ) : (
                      <div className="poster__no-posters">
                        {search ? (
                          <div className="poster__text">
                            검색 결과가 없습니다
                          </div>
                        ) : (
                          <div className="poster__text">
                            키워드를 검색해주세요
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {totalItems3 &&
                    visibleItems3 &&
                    visibleItems3.length < totalItems3.length && (
                      <button
                        className="more__btn"
                        onClick={() => handleLoadMore("3")}
                      >
                        <FaArrowRightLong className="more__btn-icon" />
                        <div>더 보기</div>
                      </button>
                    )}
                </div>
                <div className="smarts__count">
                  {search ? (
                    <div className="smarts__title">
                      산업안전보건 기준에 관한 규칙({totalItems4?.length})
                    </div>
                  ) : (
                    <div className="smarts__title">
                      산업안전보건 기준에 관한 규칙
                    </div>
                  )}
                  <div className="newslistblock">
                    {visibleItems4 && visibleItems4.length > 0 ? (
                      visibleItems4?.map((items: itemsProps, index: number) => (
                        <SmartItem key={index} items={items} />
                      ))
                    ) : (
                      <div className="poster__no-posters">
                        {search ? (
                          <div className="poster__text">
                            검색 결과가 없습니다
                          </div>
                        ) : (
                          <div className="poster__text">
                            키워드를 검색해주세요
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {totalItems4 &&
                    visibleItems4 &&
                    visibleItems4.length < totalItems4.length && (
                      <button
                        className="more__btn"
                        onClick={() => handleLoadMore("4")}
                      >
                        <FaArrowRightLong className="more__btn-icon" />
                        <div>더 보기</div>
                      </button>
                    )}
                </div>
                <div className="smarts__count">
                  {search ? (
                    <div className="smarts__title">
                      고시 / 훈령 / 예규({totalItems5?.length})
                    </div>
                  ) : (
                    <div className="smarts__title">고시 / 훈령 / 예규</div>
                  )}

                  <div className="newslistblock">
                    {visibleItems5 && visibleItems5.length > 0 ? (
                      visibleItems5?.map((items: itemsProps, index: number) => (
                        <SmartItem key={index} items={items} />
                      ))
                    ) : (
                      <div className="poster__no-posters">
                        {search ? (
                          <div className="poster__text">
                            검색 결과가 없습니다
                          </div>
                        ) : (
                          <div className="poster__text">
                            키워드를 검색해주세요
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {totalItems5 &&
                    visibleItems5 &&
                    visibleItems5.length < totalItems5.length && (
                      <button
                        className="more__btn"
                        onClick={() => handleLoadMore("5")}
                      >
                        <FaArrowRightLong className="more__btn-icon" />
                        <div>더 보기</div>
                      </button>
                    )}
                </div>
                <div className="smarts__count">
                  {search ? (
                    <div className="smarts__title">
                      미디어({totalItems6?.length})
                    </div>
                  ) : (
                    <div className="smarts__title">미디어</div>
                  )}

                  <div className="newslistblock">
                    {visibleItems6 && visibleItems6.length > 0 ? (
                      visibleItems6?.map((items: itemsProps, index: number) => (
                        <SmartItem key={index} items={items} />
                      ))
                    ) : (
                      <div className="poster__no-posters">
                        {search ? (
                          <div className="poster__text">
                            검색 결과가 없습니다
                          </div>
                        ) : (
                          <div className="poster__text">
                            키워드를 검색해주세요
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {totalItems6 &&
                    visibleItems6 &&
                    visibleItems6.length < totalItems6.length && (
                      <button
                        className="more__btn"
                        onClick={() => handleLoadMore("6")}
                      >
                        <FaArrowRightLong className="more__btn-icon" />
                        <div>더 보기</div>
                      </button>
                    )}
                </div>
                <div className="smarts__count">
                  {search ? (
                    <div className="smarts__title">
                      KOSHA GUID({totalItems7?.length})
                    </div>
                  ) : (
                    <div className="smarts__title">KOSHA GUID</div>
                  )}
                  <div className="newslistblock">
                    {visibleItems7 && visibleItems7.length > 0 ? (
                      visibleItems7?.map((items: itemsProps, index: number) => (
                        <SmartItem key={index} items={items} />
                      ))
                    ) : (
                      <div className="poster__no-posters">
                        {search ? (
                          <div className="poster__text">
                            검색 결과가 없습니다
                          </div>
                        ) : (
                          <div className="poster__text">
                            키워드를 검색해주세요
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {totalItems7 &&
                    visibleItems7 &&
                    visibleItems7.length < totalItems7.length && (
                      <button
                        className="more__btn"
                        onClick={() => handleLoadMore("7")}
                      >
                        <FaArrowRightLong className="more__btn-icon" />
                        <div>더 보기</div>
                      </button>
                    )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
