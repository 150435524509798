import { useEffect } from 'react';
import './CopyrightPolicy.scss';

export default function CopyrightPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="copyright-policy">
            <h1>저작권 정책</h1>
            <section>
                <h2>사용 조건</h2>
                <p>본 웹사이트에서 제공하는 모든 자료는 다음의 조건에 따라 사용할 수 있습니다:</p>
                <ul>
                    <li>출처 표기 없이 모든 사업장에서 사용 가능합니다.</li>                                        
                </ul>
            </section>

            <section>
                <h2>금지 사항</h2>
                <p>본 웹사이트의 자료를 사용하는 경우 다음 사항을 준수해야 합니다:</p>
                <ul>
                    <li>자료를 상업적 목적으로 사용하거나 재배포할 수 없습니다.</li>
                    <li>자료를 변형하거나 수정하여 상업적 목적으로 사용할 수 없습니다.</li>
                </ul>
            </section>

            <section>
                <h2>저작권 문의</h2>
                <p>본 저작권 정책에 관한 문의 사항이 있으면 아래 연락처로 문의해 주십시오:</p>
                <ul>
                    <li>이메일: sososafety0@gmail.com</li>                    
                </ul>
            </section>

            <section>
                <h2>정책 변경</h2>
                <p>본 저작권 정책은 추후 변경될 수 있으며, 변경 사항은 본 페이지에 공지됩니다. 변경된 정책은 공지된 날로부터 효력이 발생합니다.</p>
            </section>
        </div>
    );
};