import { useCallback, useEffect, useState } from "react";
import { EtcProps } from ".";
import Loader from "components/loader/Loader";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "firevaseApp";
import Header from "components/Header";
import EtcDetailForm from "components/etc/EtcDetailForm";
import Footer from "components/Footer";
import AdComponent from "components/AdSense";

export default function EtcDetail() {
  const params = useParams(); //게시물 아이디 불러오기
  const [etc, setEtc] = useState<EtcProps | null>(null);

  const getPost = useCallback(async () => {
    if (params.id) {
      const docRef = doc(db, "etcs", params.id);
      const docSnap = await getDoc(docRef);

      setEtc({ ...(docSnap?.data() as EtcProps), id: docSnap.id }); //데이터 확인
    }
  }, [params.id]);

  useEffect(() => {
    if (params.id) getPost(); //id가 있다면 호출
  }, [getPost, params.id]);

  return (
    <>
      <Header />
      <div className="posters">
        <div className="addsense_smart">
          <AdComponent />
        </div>
        <div className="poster-form__title">안전 표지판</div>
        {etc ? <EtcDetailForm etc={etc} /> : <Loader />}
      </div>
      <Footer />
    </>
  );
}
