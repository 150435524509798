import Footer from "components/Footer";
import Header from "components/Header";
import SignForm from "components/signs/SignForm";

export default function SignNew() {
    return (
        <>
            <Header />
            <SignForm />
            <Footer />
        </>
    );
}