import Header from "components/Header";
import Posterform from "components/posters/PosterForm";

export default function PosterNew() {
    return (
        <>
            <Header />
            <Posterform />
        </>
    );
}