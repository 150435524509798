import AuthContext from "context/AuthContext";
import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { deleteDoc, doc } from "firebase/firestore";
import { db } from "firevaseApp";
import { ref, deleteObject } from "firebase/storage";
import { storage } from "firevaseApp";
import { MdOutlineReplay } from "react-icons/md";
import { FaArrowRightLong } from "react-icons/fa6";
import { Helmet } from "react-helmet-async";
import { NewsReportProps } from "pages/newsreport";

interface NewsReportBoxProps {
  card: NewsReportProps;
}

export default function NewsReportDetailForm({ card }: NewsReportBoxProps) {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const imageRef = ref(storage, card?.imageUrl);
  const linkAddrees = `/newsreport/link/${card?.id}`;

  //카드뉴스 링크 복사
  const handleCopyLink = () => {
    const fullLink = `${window.location.origin}${linkAddrees}`;
    navigator.clipboard
      .writeText(fullLink)
      .then(() => {
        toast.success("링크가 복사 되었습니다.");
      })
      .catch((err) => {
        console.error("링크 복사에 실패하였습니다 ", err);
      });
  };

  //게시글 삭제
  const handleDelete = async () => {
    const confirm = window.confirm("해당 게시글을 삭제하시곗습니까?");
    if (confirm) {
      //스토리지 이미지 먼저 삭제
      if (card?.imageUrl) {
        deleteObject(imageRef).catch((error) => {
          console.log(error);
        });
      }

      await deleteDoc(doc(db, "newsreports", card.id));
      toast.success("게시글이 삭제되었습니다.");
      navigate("/newsreport");
    }
  };

   //이미지 슬라이드
   const NewsCardWidth = ({ images }: { images: string[] | undefined }) => {
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [showReplay, setShowReplay] = useState(false);

    const nextImage = () => {
      if (images) {
        if (currentImageIndex < images.length - 1) {
          setCurrentImageIndex((prevIndex) => prevIndex + 1);
          console.log(currentImageIndex + " / " + images.length);
        } else {
          setShowReplay(true);
          console.log(currentImageIndex + " 또는 / " + images.length);
        }
      }
    };

    const prevImage = () => {
      if (images && currentImageIndex > 0) {
        setCurrentImageIndex((prevIndex) => prevIndex - 1);
        setShowReplay(false);
      }
    };

    const goToFirstImage = () => {
      setCurrentImageIndex(0);
      setShowReplay(false);
    };

    return (
      <div className="cardnews_box">
        <div className="cardnews_gallery">
          <div className="button-container">
            <button
              className="card_btn btn_l"
              onClick={prevImage}
              style={{
                visibility:
                  currentImageIndex > 0 && !showReplay ? "visible" : "hidden",
              }}
            >
              &lt;
            </button>
          </div>
          {images && images.length > 0 && (
            <img
              src={images[currentImageIndex]}
              alt={`Image ${currentImageIndex + 1}`}
              className="cardnews_img"
              style={{ visibility: !showReplay ? "visible" : "hidden" }}
              onContextMenu={(e) => e.preventDefault()}
            />
          )}
          <div className="button-container">
            <button
              className="card_btn btn_r"
              onClick={nextImage}
              style={{ visibility: !showReplay ? "visible" : "hidden" }}
            >
              &gt;
            </button>
            {showReplay && (
              <button className="card_replay" onClick={goToFirstImage}>
                <MdOutlineReplay className="card_replay-icon" />
              </button>
            )}
          </div>
        </div>
        <div className="image-index">
          {`${currentImageIndex + 1} - ${images?.length}`}
        </div>
      </div>
    );
  };

  //이미지 세로
  const NewsCardLength = ({ images }: { images: string[] | undefined }) => {
    return (
      <div className="newsreport_box">
        {images && images.length > 0 && (
          <div className="newsreport_gallery">
            {images.map((image, index) => (
              <img
                key={index}
                src={image}
                alt={`Image ${index + 1}`}
                className="cardnews_img"
                onContextMenu={(e) => e.preventDefault()}
              />
            ))}
          </div>
        )}
      </div>
    );
  };

  const NewsFeedWidth = ({ cards }: { cards: NewsReportProps[] }) => {
    return (
      <div className="cardnews_feed">
        {cards.map((card) => (
          <NewsCardWidth
            key={card.id} // Don't forget to add a unique key prop
            images={card.imageUrls} // Pass imageUrls from card
          />
        ))}
      </div>
    );
  };

  const NewsFeedLength = ({ cards }: { cards: NewsReportProps[] }) => {
    return (
      <div className="cardnews_feed">
        {cards.map((card, index) => (
          <>
            <NewsCardLength
              key={card.id} // Don't forget to add a unique key prop
              images={card.imageUrls} // Pass imageUrls from card
            />
            {(index + 1) % 3 === 0 && (
              <div id={`ad-container-${index}`}>
                {/* 광고 위치 */}
              </div>
            )}
          </>
        ))}
      </div>
    );
  };

  useEffect(() => {
    const handleContextMenu = (event: Event) => {
      event.preventDefault();
    };

    const elements = document.querySelectorAll("img, button");
    elements.forEach((element) => {
      element.addEventListener("contextmenu", handleContextMenu);
    });

    return () => {
      elements.forEach((element) => {
        element.removeEventListener("contextmenu", handleContextMenu);
      });
    };
  }, []);

  useEffect(() => {
    const adScript = document.createElement("script");
    adScript.async = true;
    adScript.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
    adScript.setAttribute("data-ad-client", "ca-pub-1059579480577414");
    adScript.crossOrigin = "anonymous";
    document.body.appendChild(adScript);

    const adContainer = document.createElement("ins");
    adContainer.className = "adsbygoogle";
    adContainer.style.display = "block";
    adContainer.setAttribute("data-ad-client", "ca-pub-1059579480577414");
    adContainer.setAttribute("data-ad-slot", "4517124924");
    adContainer.setAttribute("data-ad-format", "auto");
    adContainer.setAttribute("data-full-width-responsive", "true");

    const adPushScript = document.createElement("script");
    adPushScript.innerHTML =
      "(adsbygoogle = window.adsbygoogle || []).push({});";

    const adElement = document.getElementById("ad-container");
    if (adElement) {
      adElement.appendChild(adContainer);
      adElement.appendChild(adPushScript);
    }

    return () => {
      document.body.removeChild(adScript);
    };
  }, []);

  return (
    <>
      <Helmet>
        <meta name="description" content={card.title} />
        <meta property="og:description" content={card.title} />
        <meta
          property="og:image"
          content={card.imageUrls ? card.imageUrls[0] : "기본 이미지 URL"}
        />
        <meta
          property="og:url"
          content={`https://sososafety.shop/newsreport/${card.id}`}
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="poster__box" key={card?.id}>
        <div className="poster__box-profile poster__box-profile-detail">
          <div className="poster__box-title">{card?.title}</div>
          <Link to={"/newsreport"}>
            <button type="button" className="poster__box-list">
              <FaArrowRightLong className="more__btn-icon" />
              <div>목록</div>
            </button>
          </Link>
        </div>
        <div className="poster-form__hashtages-outputs poster-form__hashtages-outputs-detail">
          {card?.hashTags?.map((tag, index) => (
            <span className="poster-form__hashtages-tag" key={index}>
              {tag}
            </span>
          ))}
        </div>
        <div className="poster_wrap">    
        <p>가로로 보기</p>      
          {card?.imageUrls && card.imageUrls.length > 0 && (
            <div className="poster__card">
              <NewsFeedWidth cards={[card]} />
            </div>
          )}
        </div>
        <div className="poster_wrap">
          <p>세로로 보기</p>
          {card?.imageUrls && card.imageUrls.length > 0 && (
            <div className="poster__card">
              <NewsFeedLength cards={[card]} />
            </div>
          )}
        </div>
        <div className="poster__down">
          <Link to={`/newsreport/link/${card?.id}`} target="_blank">
            <button className="poster__down-btn">링크 미리보기</button>
          </Link>
          <button className="poster__down-btn" onClick={handleCopyLink}>
            링크 복사하기
          </button>
          <div className="poster__down-guide">
            <div className="poster__down-guide1">
              사업장에서 자유롭게 사용하세요!
            </div>
            <div className="poster__down-guide2">
              (ⓒ소소안전 상업적으로 수정, 재배포, 판매는 금지합니다.)
            </div>
          </div>
        </div>
        <div className="poster__box-footer">
          {/* poster.uid === user.uid 일때 */}
          {user?.uid === card?.uid && (
            <>
              <div className="poster__Count">
                <div className="poster__Count-cont">
                  좋아요 {card?.likeCount || 0}
                </div>
                <div className="poster__Count-cont">
                  조회수 {card?.views || 0}
                </div>
                <div className="poster__Count-cont">
                  다운수 {card?.downCount || 0}
                </div>
                <div className="poster__Count-cont">{card?.createdAt || 0}</div>
              </div>
              <Link to={`/newsreport/edit/${card?.id}`}>
                <button type="button" className="poster__edit">
                  수정
                </button>
              </Link>
              <button
                type="button"
                className="poster__delete"
                onClick={handleDelete}
              >
                삭제
              </button>
            </>
          )}
          {user?.uid === "Qc78Qe8C7ReWlK9roSR3FQVUcHz2" &&
            user?.uid !== card?.uid && (
              <>
                <div className="poster__Count">
                  <div className="poster__Count-cont">
                    좋아요 {card?.likeCount || 0}
                  </div>
                  <div className="poster__Count-cont">
                    조회수 {card?.views || 0}
                  </div>
                  <div className="poster__Count-cont">
                    다운수 {card?.downCount || 0}
                  </div>
                  <div className="poster__Count-cont">
                    {card?.createdAt || 0}
                  </div>
                </div>
                <button type="button" className="poster__edit">
                  <Link to={`/newsreport/edit/${card?.id}`}>수정</Link>
                </button>
                <button
                  type="button"
                  className="poster__delete"
                  onClick={handleDelete}
                >
                  삭제
                </button>
              </>
            )}
        </div>
      </div>
    </>
  );
}
