import { useCallback, useContext, useEffect, useState } from "react";
import { updateDoc, doc, getDoc } from "firebase/firestore";
import { db, storage } from "firevaseApp";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { FiImage } from "react-icons/fi";
import Header from "components/Header";
import { v4 as uuidv4 } from 'uuid';
import AuthContext from "context/AuthContext";
import { getDownloadURL, ref, uploadString, deleteObject } from "firebase/storage";
import { NewsReportProps } from "pages/newsreport";

export default function NewsReportEditForm() {
    const params = useParams();
    const [card, setCard] = useState<NewsReportProps | null>(null);
    const [title, setTitle] = useState<string>("");
    const [hashTag, setHashTag] = useState<string>("");
    const [tags, setTags] = useState<string[]>([]);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);//중복 이미지 등록 방지
    const [imageFiles, setImageFiles] = useState<string[]>([]);
    const [existingImageUrls, setExistingImageUrls] = useState<string[]>([]);
    const { user } = useContext(AuthContext); //유저정보 받아오기
    const navigate = useNavigate();

    const handleFileUpload = (e: any) => {
        const {
            target: { files },
        } = e;

        const fileList = Array.from(files) as File[]; // Convert FileList to array
        const fileReader = new FileReader();

        fileList.forEach((file) => {
            fileReader.readAsDataURL(file);
            fileReader.onloadend = (e: any) => {
                const { result } = e?.currentTarget;
                setImageFiles((prevFiles) => [...prevFiles, result]); // Append new file to array
            };
        });
    };

    const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault();
        const files = event.dataTransfer.files; // Get the dropped files
        const fileReader = new FileReader();

        // Handle each dropped file
        Array.from(files).forEach((file: File) => { // Explicitly specify the type as File
            fileReader.onloadend = (e: ProgressEvent<FileReader>) => { // Specify the type of e as ProgressEvent<FileReader>
                const { result } = e?.currentTarget as FileReader; // Assert the type of e?.currentTarget as FileReader
                if (result) {
                    setImageFiles((prevFiles) => [...prevFiles, result as string]); // Cast result as string
                }
            };

            fileReader.readAsDataURL(file);
        });
    };

    const getCard = useCallback(async () => {
        if (params.id) {
            const docRef = doc(db, "newsreports", params.id);
            const docSnap = await getDoc(docRef);

            const data = docSnap?.data() as NewsReportProps;
            setCard({ ...data, id: docSnap.id }); // 데이터 확인
            setTitle(data.title || ""); // 기존 title 넣기
            setTags(data.hashTags || []); // 기존 hashTags 넣기
            setExistingImageUrls(data.imageUrls || []); // 기존 이미지 넣기
        }
    }, [params.id]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {
            target: { name, value }, //타이핑 찍기
        } = e;

        if (name === "title") {
            setTitle(value);// 입력값 업데이트
        }
    };

    const removeTag = (tag: string) => {
        setTags(tags?.filter((val) => val !== tag));
    };

    const onChangeHashTag = (e: any) => {
        setHashTag(e?.target?.value?.trim());
    };

    const handleKeyUp = (e: any) => {
        if (e.keyCode === 32 && e.target.value.trim() !== "") {
            // 만약 같은 태그가 있다면 에러를 띄운다
            // 아니라면 태그를 생성해준다
            if (tags?.includes(e.target.value?.trim())) {
                toast.error("같은 태그가 있습니다.");
            } else {
                setTags((prev) => (prev?.length > 0 ? [...prev, hashTag] : [hashTag]));
                setHashTag("");
            }
        }
    };

    const handleDeleteImage = (index: number) => {
        if (index < existingImageUrls.length) {
            const updatedUrls = [...existingImageUrls];
            updatedUrls.splice(index, 1); // Remove image URL at specified index
            setExistingImageUrls(updatedUrls);
        } else {
            const adjustedIndex = index - existingImageUrls.length;
            const updatedFiles = [...imageFiles];
            updatedFiles.splice(adjustedIndex, 1); // Remove image file at specified index
            setImageFiles(updatedFiles);
        }
    };

    const onSubmit = async (e: any) => {
        e.preventDefault(); //폼이 넘어가지 않도록 설정

        setIsSubmitting(true);

        try {
            if (card) {
                if (!title) {
                    toast.error("제목을 입력하세요.");
                    setIsSubmitting(false);
                } else if (tags.length < 1) {
                    toast.error("태그를 등록하세요.");
                    setIsSubmitting(false);
                } else if (!existingImageUrls.length && !imageFiles.length) {
                    toast.error("이미지를 등록하세요.");
                    setIsSubmitting(false);
                } else {
                    let imageUrls = [...existingImageUrls];

                    if (imageFiles.length > 0) {
                        // 새로운 사진 업로드
                        const imageUrlPromises = imageFiles.map(async (image) => {
                            const key = `${user?.uid}/${uuidv4()}`; //고유 키값
                            const storageRef = ref(storage, key);
                            const data = await uploadString(storageRef, image, "data_url");
                            return getDownloadURL(data.ref);
                        });
                        const newImageUrls = await Promise.all(imageUrlPromises);
                        imageUrls = [...imageUrls, ...newImageUrls];
                    }

                    // 만약 사진이 아예 없다면 삭제
                    const cardRef = doc(db, "newsreports", card?.id);
                    await updateDoc(cardRef, {
                        title: title,
                        hashTags: tags,
                        imageUrls: imageUrls,
                    });

                    navigate(-1);
                    toast.success("게시물을 수정하였습니다.");
                }
                setImageFiles([]);
                setIsSubmitting(false);
            }
        } catch (e: any) {
            console.log(e);
            toast.error("업로드 오류로 등록되지 않았습니다.");
            setIsSubmitting(false);
        }
    };

    useEffect(() => {
        if (params.id) getCard(); //id가 있다면 호출
    }, [getCard, params.id]);

    return (
        <>
            <Header />
            <form className="poster-form" onSubmit={onSubmit}>
                <div className="poster-form__title">뉴스 리포트 수정</div>
                <input className="poster-form__input" required name="title" id="title" placeholder="타이틀 입력" onChange={onChange} value={title} />
                <div className="poster-form__hashtages">
                    <span className="poster-form__hashtages-outputs">
                        {tags?.map((tag, index) => (
                            <span className="poster-form__hashtages-tag" key={index} onClick={() => removeTag(tag)}>#{tag}</span>
                        ))}
                    </span>
                    <input
                        className="poster-form__hashtages-input"
                        type="text"
                        name="hashteg"
                        id="hashteg"
                        placeholder="태그명 + 스페이스바 입력"
                        onChange={onChangeHashTag}
                        onKeyUp={handleKeyUp}
                        value={hashTag}
                    />
                </div>
                <div className="poster-form__submit-input">
                    <div className="poster-form__image-input-card" onDrop={handleDrop} onDragOver={(e) => e.preventDefault()}>
                        <label htmlFor="file-input" className="poster-from__file">
                            <FiImage className="poster-from__file-icon" size={300} />
                        </label>
                        <input type="file" id="file-input" name="file-input" accept="image/*" multiple onChange={handleFileUpload} className="hidden" />
                        {existingImageUrls.length > 0 && (
                            <div className="poster-form__attachment-card">
                                {existingImageUrls.map((url, index) => (
                                    <div key={index} className="poster-form__image-item">
                                        <img src={url} alt={`attachment-${index}`} className="card_img"/>
                                        <button className="poster-form__clear-btn" type="button" onClick={() => handleDeleteImage(index)}>
                                            이미지 삭제
                                        </button>
                                    </div>
                                ))}
                            </div>
                        )}
                        {imageFiles.length > 0 && (
                            <div className="poster-form__attachment-card">
                                {imageFiles.map((image, index) => (
                                    <div key={index + existingImageUrls.length} className="poster-form__image-item">
                                        <img src={image} alt={`attachment-${index}`} className="card_img"/>
                                        <button className="poster-form__clear-btn" type="button" onClick={() => handleDeleteImage(index + existingImageUrls.length)}>
                                            이미지 삭제
                                        </button>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                    <input type="submit" value="수정" className="poster-form__submit-btn" disabled={isSubmitting} />
                </div>
            </form>
        </>
    );
};
