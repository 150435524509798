import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAuth, createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider, sendEmailVerification } from "firebase/auth";
import { app } from "firevaseApp";
import { toast } from 'react-toastify';
import Header from "components/Header";

export default function SignupForm() {
    //useState 목록
    const [error, setError] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [passwordConfirmation, setPasswordConfirmation] = useState<string>("");
    const navigate = useNavigate();

    const onSubmit = async (e: any) => {
        e.preventDefault(); //해당 폼 미 제출
        try {
            const auth = getAuth(app);//파이어베이스에서 인증 불러오기
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            await sendEmailVerification(user); // 이메일 인증 메일 전송

            toast.success("이메일을 확인하고 인증을 완료해주세요");
            navigate("/login"); // 회원가입 후 로그인 페이지로 이동   
        } catch (error: any) {
            toast.error(error?.code);
        }
    };
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        // 변경되는 입력 값 받아오기
        const {
            target: { name, value },
        } = e;

        if (name === "email") {
            setEmail(value);
            const validRegex =
                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

            if (!value?.match(validRegex)) {
                setError("이메일 형식이 올바르지 않습니다.");
            } else {
                setError("");
            }
        }

        if (name === "password") {
            setPassword(value);

            if (value?.length < 8) {
                setError("비밀번호는 8자리 이상 입력해주세요")
            } else if (value !== passwordConfirmation) {
                setError("비밀번호와 비밀번호 확인 값이 다릅니다.");
            } else {
                setError("");
            }
        }

        if (name === "password_confirmation") {
            setPasswordConfirmation(value);

            if (value?.length < 8) {
                setError("비밀번호는 8자리 이상 입력해주세요")
            } else if (value !== password) {
                setError("비밀번호와 비밀번호 확인 값이 다릅니다.");
            } else {
                setError("");
            }
        }
    };

    const onClickSocialLogin = async (e: any) => {
        const { target: { name },
        } = e;

        let provider;
        const auth = getAuth(app);

        if (name === "google") {
            provider = new GoogleAuthProvider();
        }

        await signInWithPopup(
            auth,
            provider as GoogleAuthProvider
        ).then((result) => {
            console.log(result);
            toast.success("로그인 되었습니다.");
            navigate("/");
        })
            .catch((error) => {
                console.log(error);
                const errorMessage = error?.message;
                toast?.error(errorMessage);
            })
    };

    return (
        <>
            <Header />
            <form className="form form--lg" onSubmit={onSubmit}>
                <div className="form__title">회원가입</div>
                <div className="form__content-box">
                    <div className="form__content-top">소소안전을 찾아주셔서 감사합니다.</div>
                    <div className="form__content-bot">소소안전의 멤버가 되어 다양한 소소안전의 컨텐츠를 이용해보세요.</div>
                </div>
                <div className="form__block">
                    <label htmlFor="email">이메일</label>
                    <input type="text" name="email" id="email" value={email} required onChange={onChange} />
                </div>
                <div className="form__block">
                    <label htmlFor="password">비밀번호</label>
                    <input type="password" name="password" id="password" value={password} required onChange={onChange} />
                </div>
                <div className="form__block">
                    <label htmlFor="password_confirmation">비밀번호 확인</label>
                    <input type="password" name="password_confirmation" id="password_confirmation" value={passwordConfirmation} required onChange={onChange} />
                </div>

                {error && error?.length > 0 && (
                    <div className="form__block">
                        <div className="form__error">{error}</div>
                    </div>
                )}

                <div className="form__block--b">
                    계정이 있으신가요?
                    <Link to="/users/login" className="form__link">
                        로그인하기
                    </Link>
                </div>
                <div className="form__block--lg">
                    <button type="submit" className="form__btn--submit" disabled={error?.length > 0}>
                        회원가입
                    </button>
                </div>
                <div className="form__block">
                    <button type="button" name="google" className="form__btn--google" onClick={onClickSocialLogin}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 33 34" fill="none" className="google_icon">
                            <path d="M28.0994 29.799C32.0079 26.151 33.745 20.0711 32.7028 14.2517H17.1555V20.6791H26.0148C25.6674 22.7636 24.4514 24.5007 22.7143 25.6299L28.0994 29.799Z" fill="#4285F4" />
                            <path d="M2.38998 24.6746C3.53257 26.9254 5.1712 28.8875 7.18234 30.4129C9.19349 31.9382 11.5247 32.9872 14.0002 33.4806C16.4756 33.974 19.0309 33.8991 21.4732 33.2614C23.9155 32.6236 26.1811 31.4398 28.0994 29.7992L22.7143 25.6301C18.1109 28.67 10.4676 27.5409 7.86191 20.4187L2.38998 24.6746Z" fill="#34A853" />
                            <path d="M7.86191 20.4187C7.16706 18.2473 7.16706 16.2496 7.86191 14.0782L2.38998 9.82227C0.392289 13.8176 -0.215704 19.4633 2.38998 24.6747L7.86191 20.4187Z" fill="#FBBC02" />
                            <path d="M7.86191 14.078C9.77274 8.08493 17.9372 4.61069 23.4092 9.7352L28.1862 5.04497C21.4115 -1.46924 8.20933 -1.20867 2.38998 9.82205L7.86191 14.078Z" fill="#EA4335" />
                        </svg>
                        Google로 회원가입
                    </button>
                </div>

            </form>
        </>
    );
}