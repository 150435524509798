import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <div className="footer">
            <div className="footer__logo">
                <Link to={"/"}>
                    <img src="\img\logo.png" alt="로고" />
                </Link>
            </div>
            <div className="footer__box">
                <div className="footer__cont">
                    <Link to={"/privacypolicy"}>개인정보처리방침</Link>
                    <Link to={"/copyrightpolicy"}>저작권</Link>
                    <Link to={"/termsofservice"}>이용약관</Link>
                </div>
                <div className="footer__info">
                    <a href="mailto:sososafety0@gmail.com" className="footer__info-email">sososafety0@gmail.com</a>
                    <div className="footer__info-copyright">COPYRIGHT(C) ALL RIGHTS RESERVED</div>
                </div>
            </div>
        </div>
    );
}