import { useCallback, useEffect, useState } from "react";
import Loader from "components/loader/Loader";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "firevaseApp";
import { NewsReportProps } from ".";
import NewsReportLink from "components/newsreport/NewsReportLink";

export default function NewsReportLinkPage() {
    const params = useParams(); //게시물 아이디 불러오기
    const [card, setCard] = useState<NewsReportProps | null>(null);

    const getCard = useCallback(async () => {
        if (params.id) {
            const docRef = doc(db, "newsreports", params.id);
            const docSnap = await getDoc(docRef);

            setCard({ ...(docSnap?.data() as NewsReportProps), id: docSnap.id }); //데이터 확인            
        }
    }, [params.id]);

    useEffect(() => {
        if (params.id) getCard(); //id가 있다면 호출
    }, [getCard, params.id])

    return (
        <>
            {card ? <NewsReportLink card={card}
            /> : <Loader />}
        </>
    );
}
