import AuthContext from "context/AuthContext";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "firevaseApp";
import { NewsPicsProps } from "pages/newspic";
import { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

interface NewsPicProps {
    newsPic: NewsPicsProps;
}

export default function NewsPicList({ newsPic }: NewsPicProps) {
    const exception1 = process.env.REACT_APP_ADMIN_EMAIL1 || "";
    const exception2 = process.env.REACT_APP_ADMIN_EMAIL2 || "";
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();

    //조회수
    const onClickViews = async () => {
        const signRef = doc(db, "newspics", newsPic.id);
        await updateDoc(signRef, {
            views: newsPic?.views ? newsPic?.views + 1 : 1,
        });
    };

    //게시글 삭제
    const handleDelete = async () => {
        const confirm = window.confirm("해당 게시글을 삭제하시곗습니까?");
        if (confirm) {
            await deleteDoc(doc(db, "newspics", newsPic.id));
            toast.success("게시글이 삭제되었습니다.")
            navigate("/newspic")
        }
    };

    useEffect(() => {
        const handleContextMenu = (event: Event) => {
            event.preventDefault();
        };
    
        const elements = document.querySelectorAll('img, button');
        elements.forEach(element => {
            element.addEventListener('contextmenu', handleContextMenu);
        });
    
        return () => {
            elements.forEach(element => {
                element.removeEventListener('contextmenu', handleContextMenu);
            });
        };
    }, []);

    return (
        <div className="newspic__box" key={newsPic.id}>
            <Link to={newsPic.linkUrl} target="_blank" onClick={onClickViews}>
                <div className="newspic__box">
                    <div className="newspic__imgurl">
                        <img className="newspic__imgurl-img" src={newsPic.imageUrl} alt={newsPic.title} />
                    </div>
                    <div className="newspic__title">{newsPic.title}</div>
                    <div className="newspic__box-sm">
                        <div className="newspic__source">{newsPic.source}</div>
                        <div className="newspic__createdAt">{newsPic.registrationdate}</div>
                    </div>
                    {(user?.uid === exception1 || user?.uid === exception2) && (
                        <div className="newspic__hashtages" >
                            <button value={newsPic.hashTags} className="newspic__hashtages-tag" >{newsPic.hashTags}</button>
                        </div>
                    )}
                </div>
            </Link>
            {(user?.uid === exception1 || user?.uid === exception2) && (
                <>
                    <div className="poster__count">
                        <div className="poster__count-cont">조회수 {newsPic?.views || 0}</div>
                        <div className="poster__count-cont">{newsPic?.createdAt || 0}</div>
                    </div>
                    <Link to={`/newspic/edit/${newsPic?.id}`}>
                        <button type="button" className="poster__edit">
                            수정
                        </button>
                    </Link>
                    <button type="button" className="poster__delete" onClick={handleDelete}>
                        삭제
                    </button>
                </>
            )}
        </div>
    )
}