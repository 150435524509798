import axios from "axios";
import { useEffect, useState } from "react";

import { IoSearch } from "react-icons/io5";
import { FaArrowRightLong } from "react-icons/fa6";

import { Helmet } from "react-helmet-async";
import Loader from "components/loader/Loader";
import AccidentNewsItem from "./AccidentnewsItem";
import AdComponent from "components/AdSense";

interface ItemsProps {
  keyword: string;
  contents: string;
  arno: string;
}

type TabType =
  | ""
  | "떨어짐31"
  | "끼임20"
  | "맞음10"
  | "깔림9"
  | "부딪힘3"
  | "매몰2"
  | "폭발2";

export default function AccidentNewsList() {
  const SERVICE_KEY = process.env.REACT_APP_ENCODING_KEY;
  const [pageNo, setPageNo] = useState<number>(1);
  const [rows, setRows] = useState<number>(80);
  const [totalItems, setTotalItems] = useState<ItemsProps[] | null>(null);
  const [visibleItems, setVisibleItems] = useState<ItemsProps[] | null>(null);
  const [totalItems1, setTotalItems1] = useState<ItemsProps[] | null>(null);
  const [visibleItems1, setVisibleItems1] = useState<ItemsProps[] | null>(null);
  const [filteredItems, setFilteredItems] = useState<ItemsProps[] | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const sliceint = 9;

  const [value, setValue] = useState<string>(""); //검색창의 input
  const [tagQuery, setTagQuery] = useState<string>(""); //검색창의 input

  const URL = `https://apis.data.go.kr/B552468/news_api01/getNews_api01?serviceKey=${SERVICE_KEY}&pageNo=${pageNo}&numOfRows=${rows}`;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e?.target?.value); //검색창에 입력되는 값을 tagQuery로 저장
  };

  const onClickFilter = async (e: any) => {
    if (value.trim() === "") {
      setTagQuery("");
    } else {
      setTagQuery(value);
    }
  };

  const handleKeyUp = async (e: any) => {
    if (e.keyCode === 13) {
      onClickFilter(e);
    }
  };

  useEffect(() => {
    if (!SERVICE_KEY) {
      setError("API 서비스 키가 설정되지 않았습니다.");
      return;
    }

    async function fetchData(): Promise<void> {
      setLoading(true);
      try {
        const response = await axios.get(URL);
        const newItems = response.data.body.items.item;
        const sortedNewItems = newItems.sort((a: ItemsProps, b: ItemsProps) => {
          if (a.arno < b.arno) return 1;
          if (a.arno > b.arno) return -1;
          return 0;
        });
        console.log("초기화 useEffect");
        setTotalItems(sortedNewItems);
        setTotalItems1(sortedNewItems);
        setVisibleItems(sortedNewItems.slice(0, 9)); // 초기에 10개만 보이도록 설정
        window.scrollTo({ top: 0, behavior: "smooth" }); // 페이지 맨 위로 스크롤
      } catch (error) {
        setError("데이터를 불러오는 중 오류가 발생했습니다.");
      }
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }
    fetchData();
    console.log("useEffect 실행");
  }, []);

  useEffect(() => {
    if (tagQuery !== "" && totalItems?.length) {
      setLoading(true);
      try {
        const filtered = totalItems.filter((item: ItemsProps) =>
          item.keyword.includes(tagQuery)
        );
        setVisibleItems(filtered.slice(0, sliceint));
        setFilteredItems(filtered);
        setTotalItems1(filtered);
        window.scrollTo({ top: 0, behavior: "smooth" }); // 페이지 맨 위로 스크롤
      } catch (error) {
        setError("데이터를 필터링하는 중 오류가 발생했습니다.");
      }
      console.log("검색 useEffect");
    } else {
      setVisibleItems(totalItems?.slice(0, sliceint) || null);
      setTotalItems1(totalItems);
      console.log("검색 외 useEffect");
    }
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [tagQuery]);

  const handleLoadMore = () => {
    if (tagQuery !== "" && totalItems?.length) {
      const nextIndex = (visibleItems ? visibleItems.length : 0) + sliceint;
      setVisibleItems(filteredItems?.slice(0, nextIndex) || null);
    } else {
      const nextIndex = (visibleItems ? visibleItems.length : 0) + sliceint;
      setVisibleItems(totalItems?.slice(0, nextIndex) || null);
    }
  };

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <Helmet>
        <meta name="description" content="안전보건공단의 사망사고 알림" />
        <meta
          property="og:description"
          content="안전보건공단의 사망사고 알림"
        />
        <meta
          property="og:image"
          content="https://sososafety.shop/main_slider.png"
        />
        <meta property="og:url" content={`https://sososafety.shop/accidents`} />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="posters">
        <div className="posters__title">
          사망사고 알림
          <div className="posters__title-sm">
            안전보건공단의 사고사망 게시판 정보 중 최근 80건에 대한 정보를
            불러옵니다.
          </div>
        </div>
        <div className="search__box">
          <div className="searchs__top">
            <div className="searchs__search-div">
              <div className="searchs__box">
                <input
                  type="text"
                  className="searchs__search"
                  value={value}
                  placeholder="키워드를 검색하세요."
                  onChange={onChange}
                  onKeyUp={handleKeyUp}
                />
                <button
                  type="button"
                  onClick={onClickFilter}
                  className="searchs__search-btn"
                >
                  <IoSearch
                    color="white"
                    size={25}
                    className="searchs__search-btnimg"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <>
            <div className="loader_gap">
              <Loader />
              <div className="loader_gap-title">
                안전을 위해 열심히 검색 중..
              </div>
            </div>
          </>
        ) : (
          <div className="newspic">
            <div className="addsense_smart">
            <AdComponent />
            </div>
            {visibleItems?.map((items: ItemsProps, index: number) => (
              <AccidentNewsItem key={index} items={items} />
            ))}
          </div>
        )}
        <div className="newspic_b">
          {totalItems1 &&
            visibleItems &&
            visibleItems?.length < totalItems1.length && (
              <button className="more__btn" onClick={handleLoadMore}>
                <FaArrowRightLong className="more__btn-icon" />
                <div>더 보기</div>
              </button>
            )}
        </div>
      </div>
    </>
  );
}
