import "./popuppage.scss";
import Header from "components/Header";
import { useState, useEffect } from "react";
import { db } from 'firevaseApp';
import { doc, setDoc, getDoc } from 'firebase/firestore';

// 팝업 설정을 위한 타입 정의
export interface PopupSettings {
    title: string;
    content: string[];
    isVisible: boolean;
}

export default function PopupPage() {
  const [title, setTitle] = useState("안내사항");
  const [content, setContent] = useState<string[]>([
    "안전포스터, 표지, 표지판, 카드뉴스를 커스텀/주문제작해드립니다.",
    "크몽에서 의뢰를 받고 있으니 많은 관심 부탁드립니다.",
    "주문 제작 문의는 언제든지 환영합니다!",
    "https://kmong.com/",
  ]);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const fetchSettings = async () => {
      const docRef = doc(db, "popupSettings", "currentSettings");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const { title, content, isVisible } = docSnap.data() as PopupSettings;
        setTitle(title); // 제목 상태를 초기화
        setContent(content); // 내용 상태를 초기화
        setIsVisible(isVisible); // 활성화 상태를 초기화
      }
    };

    fetchSettings();
  }, []);

  const handleSaveSettings = async () => {
    const settings: PopupSettings = { title, content, isVisible };
    await setDoc(doc(db, "popupSettings", "currentSettings"), settings);
    alert("팝업 설정이 저장되었습니다.");
  };

  const handleResetDontShowToday = () => {
    localStorage.removeItem('popupClosedDate');
    alert("오늘 하루 보지 않기 설정이 초기화되었습니다.");
  };

  return (
    <>
      <Header />
      <div className="popup-page">
        <div className="popup-page__container">
          <h1 className="popup-page__title">팝업 관리 페이지</h1>
          <div className="popup-page__field">
            <label className="popup-page__label">
              제목:
              <input
                type="text"
                className="popup-page__input"
                value={title} // 불러온 제목을 반영
                onChange={(e) => setTitle(e.target.value)}
              />
            </label>
          </div>
          <div className="popup-page__field">
            <label className="popup-page__label">
              내용:
              <textarea
                className="popup-page__textarea"
                value={content.join("\n")} // 불러온 내용을 반영
                onChange={(e) => setContent(e.target.value.split("\n"))}
                rows={6}
              />
            </label>
          </div>
          <div className="popup-page__field">
            <label className="popup-page__label">
              팝업 활성화(칼퇴자료만 해당됨) :
              <input
                type="checkbox"
                className="popup-page__checkbox"
                checked={isVisible} // 불러온 활성화 상태를 반영
                onChange={(e) => setIsVisible(e.target.checked)}
              />
            </label>
          </div>
          <button className="popup-page__button" onClick={handleSaveSettings}>
            설정 저장
          </button>
          <button className="popup-page__button popup-page__button--reset" onClick={handleResetDontShowToday}>
            오늘 하루 보지 않기 초기화
          </button>
        </div>
      </div>
    </>
  );
}
