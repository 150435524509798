import { Helmet } from "react-helmet-async";
import { useState, useEffect } from "react";

import { MdOutlineReplay } from "react-icons/md";
import { NewsReportProps } from "pages/newsreport";

interface NewsReportBoxProps {
    card: NewsReportProps;
}

export default function NewsReportLink({ card }: NewsReportBoxProps) {
    const NewsCard = ({ images, card }: { images: string[] | undefined, card: NewsReportProps }) => {
        const [currentImageIndex, setCurrentImageIndex] = useState(0);
        const [showReplay, setShowReplay] = useState(false);

        const nextImage = () => {
            if (images) {
                if (currentImageIndex < images.length - 1) {
                    setCurrentImageIndex((prevIndex) => prevIndex + 1);
                } else {
                    setShowReplay(true);
                }
            }
        };

        const prevImage = () => {
            if (images && currentImageIndex > 0) {
                setCurrentImageIndex((prevIndex) => prevIndex - 1);
                setShowReplay(false);
            }
        };

        const goToFirstImage = () => {
            setCurrentImageIndex(0);
            setShowReplay(false);
        };

        useEffect(() => {
            console.log('Meta tags updated');
        }, [currentImageIndex]);

        const jsonLdData = {
            "@context": "http://schema.org",
            "@type": "Article",
            "mainEntityOfPage": {
                "@type": "WebPage",
                "@id": `https://sososafety.shop/cardnews/${card.id}`
            },
            "headline": card.title,
            "image": images ? images[0] : "기본 이미지 URL",
            "author": {
                "@type": "Person",
                "name": card.email
            },
            "publisher": {
                "@type": "Organization",
                "name": "소소안전",
                "logo": {
                    "@type": "ImageObject",
                    "url": "https://sososafety.shop/logo192.png"
                }
            },
            "datePublished": card.createdAt,
            "dateModified": card.createdAt,
            "description": card.title
        };

        return (
            <>
                {card && card.imageUrls && card.imageUrls.length > 0 && (
                    <Helmet>                        
                        <meta name="description" content={card.title} />                        
                        <meta property="og:description" content={card.title || "설명 없음"} />
                        <meta property="og:image" content={images ? images[0] : "기본 이미지 URL"} />
                        <meta property="og:url" content={`https://sososafety.shop/newsreport/${card.id}`} />
                        <meta property="og:type" content="article" />
                        <script type="application/ld+json">
                            {JSON.stringify(jsonLdData)}
                        </script>
                    </Helmet>
                )}
                <div className="cardnews_box">
                    <div className="cardnews_gallery">
                        <div className="button-container">
                            <button
                                className="card_btn btn_l"
                                onClick={prevImage}
                                style={{ visibility: currentImageIndex > 0 && !showReplay ? 'visible' : 'hidden' }}
                            >
                                &lt;
                            </button>
                        </div>
                        {images && images.length > 0 && (
                            <img
                                src={images[currentImageIndex]}
                                alt={`Image ${currentImageIndex + 1}`}
                                className="cardnews_img-link"
                                style={{ visibility: !showReplay ? 'visible' : 'hidden' }}
                                onContextMenu={(e) => e.preventDefault()}
                                />
                        )}
                        <div className="button-container">
                            <button
                                className="card_btn btn_r"
                                onClick={nextImage}
                                style={{ visibility: !showReplay ? 'visible' : 'hidden' }}
                            >
                                &gt;
                            </button>
                            {showReplay && (
                                <button className="card_replay" onClick={goToFirstImage}>
                                    <MdOutlineReplay className="card_replay-icon" />
                                </button>
                            )}
                        </div>
                    </div>
                    <div className="image-index">
                        {`${currentImageIndex + 1} - ${images?.length}`}
                    </div>
                </div>
            </>
        );
    };

    const NewsFeed = ({ cards }: { cards: NewsReportProps[] }) => {
        return (
            <div className="cardnews_feed-link">
                {cards.map((card) => (
                    <NewsCard
                        key={card.id}
                        images={card.imageUrls}
                        card={card}
                    />
                ))}
            </div>
        );
    };

    return (
        <>
            {card && card.imageUrls && card.imageUrls.length > 0 ? (
                <div className="poster__box-link" key={card.id}>
                    <NewsFeed cards={[card]} />
                </div>
            ) : (
                <p>카드 정보를 불러오는 중입니다...</p>
            )}
        </>
    );
}
