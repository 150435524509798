import { initializeApp, FirebaseApp, getApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";//게시물 데이터 서버
import { getStorage } from "firebase/storage";//이미지 서버


export let app: FirebaseApp;

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENTER_ID,
  appId: process.env.REACT_APP_MESSAGING_SENTER_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

//매번 이니셜라이즈를 하는 것이아니라 있으면 getApp으로 가져오고 없으면 이니셜라이즈 실행
try {
  app = getApp("app");
} catch (e) {
  app = initializeApp(firebaseConfig, "app");
}

const firebase = initializeApp(firebaseConfig);

export const db = getFirestore(app);//게시물 데이터 서버

export const storage = getStorage(app);

export default firebase;