import AdComponent from "components/AdSense";
import Footer from "components/Footer";
import Header from "components/Header";
import Loader from "components/loader/Loader";
import SeriousDisasterBox from "components/seriousdisaster/SeriousDisasterBox";
import AuthContext from "context/AuthContext";
import {
  QueryDocumentSnapshot,
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where,
  getDocs,
} from "firebase/firestore";
import { db } from "firevaseApp";
import { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { AiFillHeart } from "react-icons/ai";
import { FaArrowRightLong } from "react-icons/fa6";
import { IoSearch } from "react-icons/io5";
import { Link } from "react-router-dom";

export interface SeriousDisasterProps {
  id: string;
  email: string;
  registrationdate: string;
  createdAt: string;
  uid: string;
  admin?: boolean;
  profileUrl?: string;
  likes?: string[];
  likeCount?: number;
  hashTags?: string[];
  imageUrl?: string;
  views?: number;
  downCount?: number;
  currentPage?: number;
}
type TabType =
  | ""
  | "인양"
  | "끼임"
  | "맞음"
  | "사다리"
  | "벌도목"
  | "개구부"
  | "찜";

export default function SeriousDisasterPage() {
  const exception1 = process.env.REACT_APP_ADMIN_EMAIL1 || "";
  const exception2 = process.env.REACT_APP_ADMIN_EMAIL2 || "";
  const [seriousDisasters, setSeriousDisasters] = useState<
    SeriousDisasterProps[]
  >([]);
  const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot | null>(
    null
  ); // 마지막으로 로드된 문서 추적
  const { user } = useContext(AuthContext);
  const [tagQuery, setTagQuery] = useState<string>(""); // 검색창의 input
  const [filter, setFilter] = useState<string>("registrationdate"); // 초기 필터
  const [activeTab, setActiveTab] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const itemsPerPage = 9; // 페이지당 표시할 항목 수

  // 검색 관련
  const sanitizeInput = (input: string): string => {
    // 특수 문자 제거 및 길이 제한 (최대 10자)
    return input.replace(/[<>\/]/g, "").substring(0, 10);
  };

  const onClickFilter = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const value = sanitizeInput(e.currentTarget.value) as TabType;

    if (activeTab === value) {
      setActiveTab("");
      setTagQuery("");
      handleSearch("");
    } else {
      setActiveTab(value);
      setTagQuery(value);
      handleSearch(value);
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const sanitizedQuery = sanitizeInput(e.currentTarget.value);
      if (activeTab === sanitizedQuery) {
        setActiveTab("");
        setTagQuery("");
        handleSearch("");
      } else {
        setActiveTab(sanitizedQuery);
        setTagQuery(sanitizedQuery);
        handleSearch(sanitizedQuery);
      }
    }
  };

  const onClickTag = (tag: string) => {
    const sanitizedQuery = sanitizeInput(tag);
    if (activeTab === sanitizedQuery) {
      setActiveTab("");
      setTagQuery("");
      handleSearch("");
    } else {
      setActiveTab(sanitizedQuery);
      setTagQuery(sanitizedQuery);
      handleSearch(sanitizedQuery);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTagQuery(sanitizeInput(e.target.value)); // 검색창에 입력되는 값을 tagQuery로 저장
  };

  // 검색
  // 실시간 반영을 위한 상태 관리
  const handleSearch = async (tagQuery: string) => {
    let postRef = collection(db, "seriousDisasters");
    let postQuery;
    setLoading(true);
    if (tagQuery === "찜" && user?.uid) {
      postQuery = query(
        postRef,
        where("likes", "array-contains", user.uid),
        orderBy(filter, "desc"),
        orderBy("createdAt", "desc"),
        limit(itemsPerPage)
      );
    } else if (tagQuery) {
      postQuery = query(
        postRef,
        where("hashTags", "array-contains-any", [tagQuery]),
        orderBy(filter, "desc"),
        orderBy("createdAt", "desc"),
        limit(itemsPerPage)
      );
    } else {
      postQuery = query(
        postRef,
        orderBy(filter, "desc"),
        orderBy("createdAt", "desc"),
        limit(itemsPerPage)
      );
      window.scrollTo({ top: 0, behavior: "smooth" }); // 페이지 맨 위로 스크롤
    }

    const unsubscribe = onSnapshot(postQuery, (snapShot) => {
      let lastVisible = snapShot.docs[snapShot.docs.length - 1];
      let dataObj = snapShot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setSeriousDisasters(dataObj as SeriousDisasterProps[]);
      setLastVisible(lastVisible);
      // 강제로 1초 지연
      setTimeout(() => {
        setLoading(false);
      }, 500);
    });
    window.scrollTo({ top: 600, behavior: "smooth" }); // 페이지 맨 위로 스크롤
    return () => unsubscribe();
  };

  // 추가 로드 기능 수정
  const loadMore = async () => {
    if (!lastVisible) return;

    let postRef = collection(db, "seriousDisasters");
    let postQuery;
    if (tagQuery === "찜" && user?.uid) {
      postQuery = query(
        postRef,
        where("likes", "array-contains", user.uid),
        orderBy(filter, "desc"),
        orderBy("createdAt", "desc"),
        startAfter(lastVisible),
        limit(itemsPerPage)
      );
    } else if (tagQuery) {
      postQuery = query(
        postRef,
        where("hashTags", "array-contains-any", [tagQuery]),
        orderBy(filter, "desc"),
        orderBy("createdAt", "desc"),
        startAfter(lastVisible),
        limit(itemsPerPage)
      );
    } else {
      postQuery = query(
        postRef,
        orderBy(filter, "desc"),
        orderBy("createdAt", "desc"),
        startAfter(lastVisible),
        limit(itemsPerPage)
      );
    }

    const snapShot = await getDocs(postQuery); // getDocs로 변경
    let newLastVisible = snapShot.docs[snapShot.docs.length - 1] || null;
    let dataObj = snapShot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    })) as SeriousDisasterProps[];

    setSeriousDisasters((prevPosters) => {
      const updatedPosters = [...prevPosters, ...dataObj].reduce(
        (acc, curr) => {
          if (!acc.find((item) => item.id === curr.id)) {
            acc.push(curr);
          }
          return acc;
        },
        [] as SeriousDisasterProps[]
      );
      return updatedPosters;
    });
    setLastVisible(newLastVisible);
  };

  useEffect(() => {
    handleSearch(tagQuery);
    window.scrollTo({ top: -100, behavior: "smooth" }); // 페이지 맨 위로 스크롤
  }, [filter]);

  return (
    <>
      <Helmet>
        <meta name="description" content="중대재해 사이렌 키워드로 모아보기" />
        <meta property="og:title" content="소소안전" />
        <meta
          property="og:description"
          content="중대재해 사이렌 키워드로 모아보기"
        />
        <meta
          property="og:image"
          content="https://sososafety.shop/main_slider.png"
        />
        <meta
          property="og:url"
          content="https://sososafety.shop/seriousdisaster"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <Header />
      <div className="posters">
        <div className="posters__noti posters__noti-sm">
          <div className="smart">
            <div className="smart_cont">
              <h3 className="smart_cont-title">
                중대재해 사이렌 키워드로 모아보기!
              </h3>
              <p className="smart_cont-title3">
                딱 필요한 그 자료 ! 찾으려면 힘들었던 사고사례를<br></br>
                필요한 키워드로 한번에 모아보기
              </p>
              <div className="smart_cont-img">
                <img src="/img/main_acc2.png" alt="main_acc" />
              </div>
            </div>
          </div>
        </div>
        <h2 className="posters__title">중대재해 사이렌</h2>
        {(user?.uid === exception1 || user?.uid === exception2) && (
          <>
            <Link to={"/seriousDisaster/new"} className="posters__new">
              사고사례 등록
            </Link>
            <div>{seriousDisasters.length}</div>
          </>
        )}
        <div className="search__box">
          <div className="searchs__top">
            <div className="searchs__search-div">
              <div className="searchs__box">
                <input
                  className="searchs__search"
                  value={tagQuery}
                  placeholder="키워드를 검색하세요."
                  onChange={onChange}
                  onKeyUp={handleKeyUp}
                />
                <button
                  type="button"
                  value={tagQuery}
                  onClick={onClickFilter}
                  className="searchs__search-btn"
                >
                  <IoSearch
                    color="white"
                    size={25}
                    className="searchs__search-btnimg"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="tags">
          <div className="tags__box">
            <button
              type="button"
              value={"인양"}
              onClick={onClickFilter}
              className={`tags_tag ${
                activeTab === "인양" && "tags_tag--active"
              }`}
            >
              인양
            </button>
            <button
              type="button"
              value={"끼임"}
              onClick={onClickFilter}
              className={`tags_tag ${
                activeTab === "끼임" && "tags_tag--active"
              }`}
            >
              끼임
            </button>
            <button
              type="button"
              value={"맞음"}
              onClick={onClickFilter}
              className={`tags_tag ${
                activeTab === "맞음" && "tags_tag--active"
              }`}
            >
              맞음
            </button>
            <button
              type="button"
              value={"사다리"}
              onClick={onClickFilter}
              className={`tags_tag ${
                activeTab === "사다리" && "tags_tag--active"
              }`}
            >
              사다리
            </button>
            <button
              type="button"
              value={"벌도목"}
              onClick={onClickFilter}
              className={`tags_tag ${
                activeTab === "벌도목" && "tags_tag--active"
              }`}
            >
              벌도목
            </button>
            <button
              type="button"
              value={"개구부"}
              onClick={onClickFilter}
              className={`tags_tag ${
                activeTab === "개구부" && "tags_tag--active"
              }`}
            >
              개구부
            </button>
            <button
              type="button"
              value={"찜"}
              onClick={onClickFilter}
              className={`tags_tag ${activeTab === "찜" && "tags_tag--active"}`}
            >
              {" "}
              <AiFillHeart />
            </button>
          </div>
        </div>
        {/* poster list */}
        {loading ? (
          <>
            <div className="loader_gap">
              <Loader />
              <div className="loader_gap-title">
                안전을 위해 열심히 검색 중..
              </div>
            </div>
          </>
        ) : (
          <div className="poster">
            <div className="addsense_smart">
              <AdComponent />
            </div>
            {seriousDisasters?.length > 0 ? (
              seriousDisasters.map((seriousDisaster, index) => (
                <SeriousDisasterBox
                  seriousDisaster={seriousDisaster}
                  key={`${seriousDisaster.id}-${index}`}
                  onClickTag={onClickTag}
                  setSeriousDisasters={setSeriousDisasters}
                />
              ))
            ) : (
              <div className="poster__no-posters">
                <div className="poster__text">게시글이 없습니다.</div>
              </div>
            )}
          </div>
        )}

        {lastVisible && (
          <button className="more__btn" onClick={loadMore}>
            <FaArrowRightLong className="more__btn-icon" />
            <div>더 보기</div>
          </button>
        )}
      </div>
      <Footer />
    </>
  );
}
