import { useCallback, useEffect, useState } from "react";
import { PosterProps } from ".";
import Loader from "components/loader/Loader";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "firevaseApp";
import Header from "components/Header";
import PosterDetailForm from "components/posters/PosterDetailForm";
import Footer from "components/Footer";
import AdComponent from "components/AdSense";

export default function PosterDetail() {
  const params = useParams(); //게시물 아이디 불러오기
  const [poster, setPoster] = useState<PosterProps | null>(null);

  const getPoster = useCallback(async () => {
    if (params.id) {
      const docRef = doc(db, "posters", params.id);
      const docSnap = await getDoc(docRef);

      setPoster({ ...(docSnap?.data() as PosterProps), id: docSnap.id }); //데이터 확인
    }
  }, [params.id]);

  useEffect(() => {
    if (params.id) getPoster(); //id가 있다면 호출
  }, [getPoster, params.id]);

  return (
    <>
      <Header />
      <div className="posters">
        <div className="addsense_smart">
          <AdComponent />
        </div>
        <div className="poster-form__title">안전보건 포스터</div>
        {poster ? <PosterDetailForm poster={poster} /> : <Loader />}
      </div>
      <Footer />
    </>
  );
}
