import Footer from "components/Footer";
import Header from "components/Header";
import EtcForm from "components/etc/EtcForm";

export default function EtcNew(){
    return (
        <>
            <Header />
            <EtcForm/>
            <Footer />
        </>
    )
}