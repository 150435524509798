import { useCallback, useEffect, useState } from "react";

import Loader from "components/loader/Loader";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "firevaseApp";
import Header from "components/Header";
import { CardNewsProps } from ".";
import CardDetailForm from "components/cardnews/CardDetailForm";
import Footer from "components/Footer";
import AdComponent from "components/AdSense";

export default function CardNewsDetailPage() {
  const params = useParams(); //게시물 아이디 불러오기
  const [card, setCard] = useState<CardNewsProps | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const preloadImages = (images: string[]) => {
    images.forEach((src) => {
      const img = new Image();
      img.src = src;
    });
  };

  const getCard = useCallback(async () => {
    if (params.id) {
      const docRef = doc(db, "cards", params.id);
      const docSnap = await getDoc(docRef);

      const cardData = {
        ...(docSnap?.data() as CardNewsProps),
        id: docSnap.id,
      };
      setCard(cardData);

      if (cardData.imageUrls) {
        preloadImages(cardData.imageUrls); // 이미지 프리로딩
      }

      setIsLoading(false); // 로딩 상태 업데이트
    }
  }, [params.id]);

  useEffect(() => {
    if (params.id) getCard(); //id가 있다면 호출
  }, [getCard, params.id]);

  return (
    <>
      <Header />
      <div className="posters">
        <div className="addsense_smart">
          <AdComponent />
        </div>
        <div className="poster-form__title">안전보건 카드뉴스</div>
        {isLoading ? <Loader /> : card && <CardDetailForm card={card} />}
      </div>
      <Footer />
    </>
  );
}
