import Footer from "components/Footer";
import Header from "components/Header";
import SafetyImgForm from "components/safetyimg/SafetyImgForm";

export default function SafetyImgNew() {
    return (
        <>
            <Header />
            <SafetyImgForm />
            <Footer />
        </>
    );
}