import AuthContext from "context/AuthContext";

import { useContext, useEffect } from "react";
import { toast } from "react-toastify";

import { Link, useNavigate } from "react-router-dom";
import { arrayRemove, arrayUnion, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "firevaseApp";
import { ref, deleteObject, getDownloadURL } from "firebase/storage";
import { storage } from "firevaseApp";

import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { CardNewsProps } from "pages/cardnews";
import { Helmet } from "react-helmet-async";

interface CardBoxProps {
    card: CardNewsProps;
    onClickTag: (tag: string) => void;
    currentPage: number;
    onToggleLike: (updatedSign: CardNewsProps) => void; // 새로운 prop 추가
}

export default function CardNewsBox({ card, onClickTag, currentPage, onToggleLike }: CardBoxProps) {
    const exception1 = process.env.REACT_APP_ADMIN_EMAIL1 || "";
    const exception2 = process.env.REACT_APP_ADMIN_EMAIL2 || "";
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();

    //조회수
    const onClickViews = async () => {
        const cardRef = doc(db, "cards", card.id);
        await updateDoc(cardRef, {
            views: card?.views ? card?.views + 1 : 1,
        });
    };

    //좋아요
    const toggleLike = async () => {
        if (!user) {
            navigate("/users/login");
        } else {
            const cardRef = doc(db, "cards", card.id);

            if (user?.uid && card?.likes?.includes(user?.uid)) {
                //사용자가 좋아요를 미리 한경우 취소
                await updateDoc(cardRef, {
                    likes: arrayRemove(user?.uid),
                    likeCount: card?.likeCount ? card?.likeCount - 1 : 0,
                });
            } else {
                //하지않은 경우 좋아요 추가        
                await updateDoc(cardRef, {
                    likes: arrayUnion(user?.uid),
                    likeCount: card?.likeCount ? card?.likeCount + 1 : 1,
                });
            }
        }
    };

    //게시글 삭제
    const handleDelete = async () => {
        const confirm = window.confirm("해당 게시글을 삭제하시곗습니까?");
        if (confirm) {
            try {
                // 스토리지에 있는 각 이미지 삭제
                if (card?.imageUrls) {
                    const deleteImagePromises = card.imageUrls.map(async (imageUrl) => {
                        const imageRef = ref(storage, imageUrl);
                        try {
                            await getDownloadURL(imageRef); // Check if the file exists
                            await deleteObject(imageRef); // If exists, delete the file
                        } catch (error) {
                            console.error(`Error deleting image ${imageUrl}:`, error);
                            // Handle the case where the file does not exist
                        }
                    });
                    await Promise.all(deleteImagePromises);
                }

                // 게시글 삭제
                await deleteDoc(doc(db, "cards", card.id));
                toast.success("게시글이 삭제되었습니다.");
                navigate("/cardnews");
            } catch (error) {
                console.error("Error deleting post:", error);
                toast.error("게시글 삭제 중 오류가 발생했습니다.");
            }
        }
    };

    useEffect(() => {
        const handleContextMenu = (event: Event) => {
            event.preventDefault();
        };
    
        const elements = document.querySelectorAll('img, button');
        elements.forEach(element => {
            element.addEventListener('contextmenu', handleContextMenu);
        });
    
        return () => {
            elements.forEach(element => {
                element.removeEventListener('contextmenu', handleContextMenu);
            });
        };
    }, []);

    return (
        <>
            <div className="poster__box" key={card?.id}>
                <Link
                    to={`/cardnews/${card?.id}`} target="_blank" rel="noopener noreferrer" onClick={onClickViews}>
                    {card?.imageUrls && card.imageUrls.length > 0 && (
                        <div className="poster__image-div-card">
                            <img id="myimg" src={card?.imageUrls[0]} alt="poster img" className="poster__image-card" onContextMenu={(e) => e.preventDefault()}/>
                        </div>
                    )}
                </Link>
                <div className="poster__box-profile">
                    <Link to={`/cardnews/${card?.id}`} target="_blank" rel="noopener noreferrer">
                        <div className="poster__box-title">{card?.title}</div>
                    </Link>
                    <button type="button" className="poster__box-likes" onClick={toggleLike}>
                        {user && card?.likes?.includes(user.uid) ? (
                            <AiFillHeart size={30} />
                        ) : (
                            <AiOutlineHeart size={30} />
                        )}
                    </button>
                </div>
                <div className="poster-form__hashtages-outputs">
                    {card?.hashTags?.map((tag, index) => (
                        <button value={tag} className="poster-form__hashtages-tag" key={index} onClick={() => onClickTag(tag)} >{tag}</button>
                    ))}
                </div>
                {(user?.uid === exception1 || user?.uid === exception2) && (
                    <div className="poster__box-footer">
                        <>
                            <div className="poster__count">
                                <div className="poster__count-cont">좋아요 {card?.likeCount || 0}</div>
                                <div className="poster__count-cont">조회수 {card?.views || 0}</div>
                                <div className="poster__count-cont">다운수 {card?.downCount || 0}</div>
                                <div className="poster__count-cont">{card?.createdAt || 0}</div>
                            </div>
                            <Link to={`/cardnews/edit/${card?.id}`}>
                                <button type="button" className="poster__edit">
                                    수정
                                </button>
                            </Link>
                            <button type="button" className="poster__delete" onClick={handleDelete}>
                                삭제
                            </button>
                        </>
                    </div>
                )}
            </div>
        </>
    );
}