import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { app } from "firevaseApp";
import { toast } from 'react-toastify';
import Header from "components/Header";
import Footer from "components/Footer";

export default function VerifyEmail() {
    const [user, setUser] = useState<any>(null);
    const navigate = useNavigate();
    const auth = getAuth(app);

    useEffect(() => {
        const currentUser = auth.currentUser;
        if (currentUser) {
            setUser(currentUser);
        }
    }, [auth]);

    const resendVerificationEmail = async () => {
        if (user) {
            try {
                await user.sendEmailVerification();
                toast.success("인증 이메일이 재전송되었습니다.");
            } catch (error: any) {
                toast.error(error.message);
            }
        }
    };

    const checkEmailVerified = async () => {
        if (user) {
            await user.reload();
            if (user.emailVerified) {
                toast.success("이메일 인증이 완료되었습니다.");
                navigate("/");
            } else {
                toast.warn("아직 이메일이 인증되지 않았습니다.");
            }
        }
    };

    return (
        <>
            <Header />
            <div className="form">
                <div className="form__title">이메일 인증</div>
                <div className="form__content-box">
                    <div className="form__content-top">이메일 인증이 필요합니다.</div>
                    <div className="form__content-bot">회원가입 시 사용한 이메일을 확인하고 인증을 완료해주세요.</div>

                </div>
                {user && (
                    <div className="form__block">
                        <label htmlFor="email">등록한 이메일</label>
                        <input className="form__readonly" type="text" name="email" id="email" value={user.email} readOnly />
                    </div>
                )}
                <div className="form__verify">                    
                    <button className="form__verify-resendbtn" onClick={resendVerificationEmail}>인증 이메일 재전송</button>
                    <button className="form__verify-checkbtn" onClick={checkEmailVerified}>이메일 인증 확인</button>
                </div>
            </div>
            <Footer />
        </>
    );
}
