import { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import AuthContext from "context/AuthContext";
import {
  collection,
  query,
  onSnapshot,
  orderBy,
  where,
  limit,
  startAfter,
  QueryDocumentSnapshot,
  getDocs,
  doc,
} from "firebase/firestore";
import { db } from "firevaseApp";
import { IoSearch } from "react-icons/io5";
import { FaArrowRight, FaArrowRightLong } from "react-icons/fa6";
import { AiFillHeart } from "react-icons/ai";
import Header from "components/Header";
import Footer from "components/Footer";
import PosterBox from "components/posters/PosterBox";
import { Helmet } from "react-helmet-async";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import React from "react";
import { useSwipeable } from "react-swipeable";
import Loader from "components/loader/Loader";
import AdComponent from "components/AdSense";
import Modal from "components/popup/Popup";
import { PopupSettings } from "pages/popup";
import PopupManager from "components/popup/PopupManager";

export interface PosterProps {
  id: string;
  email: string;
  title: string;
  createdAt: string;
  uid: string;
  admin?: boolean;
  profileUrl?: string;
  likes?: string[];
  likeCount?: number;
  hashTags?: string[];
  imageUrl?: string;
  views?: number;
  downCount?: number;
  currentPage?: number;
}

const imagesPoster = [
  { img: "/img/suggestion_poster_1.png", id: "4GBSwfJX8u5IyFvJvLVn" },
  { img: "/img/suggestion_poster_2.png", id: "ZXTwojDCNbQDucIl9r3y" },
  { img: "/img/suggestion_poster_3.png", id: "v90FclEwcPAnohLYep4p" },
  { img: "/img/suggestion_poster_4.png", id: "FNYJqU56W7eKwSQC2gJ1" },
];

const MAX_VISIBILITY = 3;

const Carousel: React.FC<{ children: React.ReactNode[] }> = ({ children }) => {
  const [active, setActive] = useState(0);
  const count = React.Children.count(children);

  const handlers = useSwipeable({
    onSwipedLeft: () => setActive(active < count - 1 ? active + 1 : active),
    onSwipedRight: () => setActive(active > 0 ? active - 1 : active),
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  return (
    <div className="carousel" {...handlers}>
      {active > 0 && (
        <button className="nav left" onClick={() => setActive(active - 1)}>
          <IoIosArrowDropleftCircle />
        </button>
      )}
      {React.Children.map(children, (child, i) => (
        <div
          className="card-container"
          style={
            {
              "--active": i === active ? 1 : 0,
              "--offset": (active - i) / 3,
              "--direction": Math.sign(active - i),
              "--abs-offset": Math.abs(active - i) / 3,
              pointerEvents: active === i ? "auto" : "none",
              opacity: Math.abs(active - i) >= MAX_VISIBILITY ? "0" : "1",
              display: Math.abs(active - i) > MAX_VISIBILITY ? "none" : "block",
            } as React.CSSProperties
          }
        >
          {child}
        </div>
      ))}
      {active < count - 1 && (
        <button className="nav right" onClick={() => setActive(active + 1)}>
          <IoIosArrowDroprightCircle />
        </button>
      )}
    </div>
  );
};

type TabType = "" | "안전수칙" | "동절기" | "여름" | "보호구" | "찜";

export default function PosterPage() {
  const exception1 = process.env.REACT_APP_ADMIN_EMAIL1 || "";
  const exception2 = process.env.REACT_APP_ADMIN_EMAIL2 || "";
  const [posters, setPosters] = useState<PosterProps[]>([]);
  const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot | null>(
    null
  );
  const { user } = useContext(AuthContext);
  const [tagQuery, setTagQuery] = useState<string>(""); // 검색창의 input
  const [filter, setFilter] = useState<string>("createdAt");
  const [activeTab, setActiveTab] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const itemsPerPage = 9;

  const sanitizeInput = (input: string): string => {
    return input.replace(/[<>\/]/g, "").substring(0, 10);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTagQuery(sanitizeInput(e.target.value));
  };

  const onClickFilter = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const value = sanitizeInput(e.currentTarget.value) as TabType;
    if (activeTab === value) {
      setActiveTab("");
      setTagQuery("");
      handleSearch("");
    } else {
      setActiveTab(value);
      setTagQuery(value);
      handleSearch(value);
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const sanitizedQuery = sanitizeInput(e.currentTarget.value);
      if (activeTab === sanitizedQuery) {
        setActiveTab("");
        setTagQuery("");
        handleSearch("");
      } else {
        setActiveTab(sanitizedQuery);
        setTagQuery(sanitizedQuery);
        handleSearch(sanitizedQuery);
      }
    }
  };

  const onClickTag = (tag: string) => {
    const sanitizedQuery = sanitizeInput(tag);
    if (activeTab === sanitizedQuery) {
      setActiveTab("");
      setTagQuery("");
      handleSearch("");
    } else {
      setActiveTab(sanitizedQuery);
      setTagQuery(sanitizedQuery);
      handleSearch(sanitizedQuery);
    }
  };

  const handleSearch = async (tagQuery: string) => {
    let postRef = collection(db, "posters");
    let postQuery;
    setLoading(true);

    if (tagQuery === "찜" && user?.uid) {
      postQuery = query(
        postRef,
        where("likes", "array-contains", user.uid),
        orderBy(filter, "desc"),
        limit(itemsPerPage)
      );
    } else if (tagQuery) {
      postQuery = query(
        postRef,
        where("hashTags", "array-contains-any", [tagQuery]),
        orderBy(filter, "desc"),
        limit(itemsPerPage)
      );
    } else {
      postQuery = query(postRef, orderBy(filter, "desc"), limit(itemsPerPage));
    }

    const unsubscribe = onSnapshot(postQuery, (snapShot) => {
      let lastVisible = snapShot.docs[snapShot.docs.length - 1];
      let dataObj = snapShot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setPosters(dataObj as PosterProps[]);
      setLastVisible(lastVisible);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    });
    window.scrollTo({ top: 700, behavior: "smooth" });
    return () => unsubscribe();
  };

  const loadMore = async () => {
    if (!lastVisible) return;

    let postRef = collection(db, "posters");
    let postQuery;
    if (tagQuery === "찜" && user?.uid) {
      postQuery = query(
        postRef,
        where("likes", "array-contains", user.uid),
        orderBy(filter, "desc"),
        startAfter(lastVisible),
        limit(itemsPerPage)
      );
    } else if (tagQuery) {
      postQuery = query(
        postRef,
        where("hashTags", "array-contains-any", [tagQuery]),
        orderBy(filter, "desc"),
        startAfter(lastVisible),
        limit(itemsPerPage)
      );
    } else {
      postQuery = query(
        postRef,
        orderBy(filter, "desc"),
        startAfter(lastVisible),
        limit(itemsPerPage)
      );
    }

    const snapShot = await getDocs(postQuery);
    let newLastVisible = snapShot.docs[snapShot.docs.length - 1] || null;
    let dataObj = snapShot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    })) as PosterProps[];

    setPosters((prevPosters) => {
      const updatedPosters = [...prevPosters, ...dataObj].reduce(
        (acc, curr) => {
          if (!acc.find((item) => item.id === curr.id)) {
            acc.push(curr);
          }
          return acc;
        },
        [] as PosterProps[]
      );
      return updatedPosters;
    });
    setLastVisible(newLastVisible);
  };

  useEffect(() => {
    handleSearch(tagQuery);
    window.scrollTo({ top: -100, behavior: "smooth" });
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="다양한 필요를 충족하는 안전보건포스터"
        />
        s
        <meta property="og:title" content="소소안전" />
        <meta
          property="og:description"
          content="다양한 필요를 충족하는 안전보건포스터"
        />
        <meta
          property="og:image"
          content="https://sososafety.shop/main_slider.png"
        />
        <meta property="og:url" content="https://sososafety.shop/poster" />
        <meta property="og:type" content="website" />
      </Helmet>
      {/* 팝업을 관리하는 컴포넌트 */}
      <PopupManager />
      <Header />
      <div className="posters">
        <div className="posters__noti">
          <div className="post__signs">
            <div className="post__signs-box">
              <div className="post__signs-box-flex">
                <div className="content">
                  <h3>이달의 추천 포스터</h3>
                  <p>
                    다양하고 새로운 안전포스터를
                    <br />
                    무료로 무제한 다운로드 받으세요.
                  </p>
                  <ul>
                    <li className="content_poster-li">
                      <img src="/img/main_smart_add.png" alt="smart_add" />
                      소소안전 자체 제작 포스터
                    </li>
                    <li className="content_poster-li">
                      <img src="/img/main_smart_add.png" alt="smart_add" />
                      안전보건 포스터 무제한 다운로드
                    </li>
                    <li className="content_poster-li">
                      <img src="/img/main_smart_add.png" alt="smart_add" />
                      다양한 분야, 다양한 주제의 안전보건 포스터
                    </li>
                    <li className="content_poster-li">
                      <img src="/img/main_smart_add.png" alt="smart_add" />
                      무료로 업장에서 자유롭게 사용하세요
                    </li>
                  </ul>
                </div>
                <div className="post_slider">
                  <Carousel>
                    {imagesPoster.map((src, index) => (
                      <Link
                        to={`/poster/${src?.id}`}
                        key={src.id}
                        onMouseDown={(e) => e.preventDefault()}
                        onDragStart={(e) => e.preventDefault()}
                      >
                        <div key={index} className="post_poster_img">
                          <img
                            src={src?.img}
                            alt={`포스터 ${index + 1}`}
                            onContextMenu={(e) => e.preventDefault()}
                          />
                        </div>
                      </Link>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="posters__title">안전보건 포스터</div>
        {(user?.uid === exception1 || user?.uid === exception2) && (
          <div>
            <Link to={"/poster/new"} className="posters__new">
              포스터 등록
            </Link>
            <div>{posters.length}</div>
          </div>
        )}
        <div className="search__box">
          <div className="searchs__top">
            <div className="searchs__search-div">
              <div className="searchs__box">
                <input
                  className="searchs__search"
                  value={tagQuery}
                  placeholder="키워드를 검색하세요."
                  onChange={onChange}
                  onKeyUp={handleKeyUp}
                />
                <button
                  type="button"
                  onClick={onClickFilter}
                  className="searchs__search-btn"
                >
                  <IoSearch
                    color="white"
                    size={25}
                    className="searchs__search-btnimg"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="tags">
          <div className="tags__box">
            <button
              type="button"
              value={"안전수칙"}
              onClick={onClickFilter}
              className={`tags_tag ${
                activeTab === "안전수칙" && "tags_tag--active"
              }`}
            >
              안전수칙
            </button>
            <button
              type="button"
              value={"동절기"}
              onClick={onClickFilter}
              className={`tags_tag ${
                activeTab === "동절기" && "tags_tag--active"
              }`}
            >
              동절기
            </button>
            <button
              type="button"
              value={"여름"}
              onClick={onClickFilter}
              className={`tags_tag ${
                activeTab === "여름" && "tags_tag--active"
              }`}
            >
              여름
            </button>
            <button
              type="button"
              value={"보호구"}
              onClick={onClickFilter}
              className={`tags_tag ${
                activeTab === "보호구" && "tags_tag--active"
              }`}
            >
              보호구
            </button>
            <button
              type="button"
              value={"찜"}
              onClick={onClickFilter}
              className={`tags_tag ${activeTab === "찜" && "tags_tag--active"}`}
            >
              <AiFillHeart />
            </button>
          </div>
        </div>
        {loading ? (
          <>
            <div className="loader_gap">
              <Loader />
              <div className="loader_gap-title">
                안전을 위해 열심히 검색 중..
              </div>
            </div>
          </>
        ) : (
          <div className="poster">
            <div className="addsense_smart">
              <AdComponent />
            </div>
            {posters?.length > 0 ? (
              posters.map((poster) => (
                <PosterBox
                  poster={poster}
                  key={poster.id}
                  onClickTag={onClickTag}
                  currentPage={1}
                />
              ))
            ) : (
              <div className="poster__no-posters">
                <div className="poster__text">게시글이 없습니다.</div>
              </div>
            )}
          </div>
        )}
        {lastVisible && posters.length >= itemsPerPage && (
          <button className="more__btn" onClick={loadMore}>
            <FaArrowRightLong className="more__btn-icon" />
            <div>더 보기</div>
          </button>
        )}
      </div>
      <Footer />
    </>
  );
}
