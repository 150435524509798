import React from 'react';
import "./Modal.scss";

interface ModalProps {
  title: string;
  content: string[];
  onClose: () => void;
  onDontShowToday: () => void; // "오늘 하루 보지 않기"를 위한 콜백
}

const Modal: React.FC<ModalProps> = ({ title, content, onClose, onDontShowToday }) => {
  const renderContent = (text: string) => {
    // URL 패턴을 정규식으로 감지
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    const parts = text.split(urlPattern);

    return parts.map((part, index) => {
      // URL일 경우 <a> 태그로 감싸서 반환
      if (urlPattern.test(part)) {
        return (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      }
      // URL이 아닐 경우 일반 텍스트로 반환
      return <span key={index}>{part}</span>;
    });
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="modal-header">
          <h2>{title}</h2>
          <button onClick={onClose} className="modal-close-button">×</button>
        </div>
        <div className="modal-body">
          {content.map((line, index) => (
            <p key={index}>{renderContent(line)}</p>
          ))}
          <div className="modal-footer">
            <input
              type="checkbox"
              id="dontShowToday"
              onClick={onDontShowToday}
            />
            <label htmlFor="dontShowToday">오늘 하루 보지 않기</label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
