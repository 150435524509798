// components/SearchBar.tsx
import React from 'react';
import { IoSearch } from 'react-icons/io5';

interface SearchBarProps {
  tagQuery: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyUp: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onClickFilter: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ tagQuery, onChange, onKeyUp, onClickFilter }) => {
  return (
    <div className="search__box">
      <div className="searchs__top">
        <div className="searchs__search-div">
          <div className="searchs__box">
            <input
              type="text"
              className="searchs__search"
              value={tagQuery}
              placeholder="키워드를 검색하세요."
              onChange={onChange}
              onKeyUp={onKeyUp}
            />
            <button
              type="button"
              value={tagQuery}
              onClick={onClickFilter}
              className="searchs__search-btn"
            >
              <IoSearch color="white" size={25} className="searchs__search-btnimg" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchBar;
