import axios from "axios";

import { useState } from "react";
import Header from 'components/Header';
import Footer from "components/Footer";
import AccidentNewsList from "components/accidentnews/AccidentnewsList";



export default function AccidentPage() {
    return (
        <>
            <Header />
            <AccidentNewsList />
            <Footer />
        </>
    );

};
