import React, { useEffect } from 'react';

const AdComponent = () => {
  useEffect(() => {
    // 광고 스크립트 로드
    const adScript = document.createElement("script");
    adScript.async = true;
    adScript.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-1059579480577414";
    adScript.crossOrigin = "anonymous";
    document.body.appendChild(adScript);

    // 광고 컨테이너 생성
    const adContainer = document.createElement("ins");
    adContainer.className = "adsbygoogle";
    adContainer.style.display = "block";
    adContainer.setAttribute("data-ad-client", "ca-pub-1059579480577414");
    adContainer.setAttribute("data-ad-slot", "1488455143");
    adContainer.setAttribute("data-matched-content-rows-num", "3");
    adContainer.setAttribute("data-matched-content-columns-num", "1");
    adContainer.setAttribute("data-matched-content-ui-type", "image_stacked");
    adContainer.setAttribute("data-ad-format", "autorelaxed");

    // 광고 컨테이너를 DOM에 추가
    const adContainerElement = document.querySelector(".ads-container");
    if (adContainerElement) {
      adContainerElement.appendChild(adContainer);
      setTimeout(() => {
        try {
          (window.adsbygoogle = window.adsbygoogle || []).push({});
        } catch (e) {
          console.error("AdSense error", e);
        }
      }, 1000); // 1초 딜레이 추가
    }

    // 컴포넌트 언마운트 시 정리
    return () => {
      document.body.removeChild(adScript);
      if (adContainerElement) {
        adContainerElement.removeChild(adContainer);
      }
    };
  }, []);

  return (
    <div className="ads-container" style={{ width: "100%", maxWidth: "100%", margin: "0 auto" }}>
    </div>
  );
}

export default AdComponent;
