import SeriousDisasterDetailForm from "components/seriousdisaster/SeriousDisasterdetailForm";
import { useCallback, useEffect, useState } from "react";
import Loader from "components/loader/Loader";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "firevaseApp";
import { SeriousDisasterProps } from ".";
import Header from "components/Header";
import Footer from "components/Footer";

export default function SeriousDisasterDetail() {
    const params = useParams(); //게시물 아이디 불러오기
    const [seriousDisaster, setSeriousDisaster] = useState<SeriousDisasterProps | null>(null);

    const getSeriousDisaster = useCallback(async () => {
        if (params.id) {
            const docRef = doc(db, "seriousDisasters", params.id);
            const docSnap = await getDoc(docRef);

            setSeriousDisaster({ ...(docSnap?.data() as SeriousDisasterProps), id: docSnap.id }); //데이터 확인            
        }
    }, [params.id]);

    useEffect(() => {
        if (params.id) getSeriousDisaster(); //id가 있다면 호출
    }, [getSeriousDisaster, params.id])

    return (
        <>
            <Header />
            <div className="posters">
                <h2 className="poster-form__title">중대재해 사이렌</h2>
                {seriousDisaster ? <SeriousDisasterDetailForm seriousDisaster={seriousDisaster}
                /> : <Loader />}
            </div>
            <Footer />
        </>
    )
}