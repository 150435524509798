import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { app } from "firevaseApp";
import { toast } from 'react-toastify';
import Header from "components/Header";
import Footer from "components/Footer";

export default function ForgotPasswordPage() {
    const [email, setEmail] = useState("");
    const navigate = useNavigate();

    const onSendResetEmail = async () => {
        try {
            const auth = getAuth(app);
            await sendPasswordResetEmail(auth, email);
            toast.success("비밀번호 재설정 이메일이 전송되었습니다.");
            navigate("/users/login");
        } catch (error: any) {
            toast.error(error?.message);
        }
    };

    return (
        <>
            <Header />
            <div className="form">
                <div className="form__title">비밀번호 재설정</div>
                <div className="form__content-box">
                    <div className="form__content-top">비밀번호를 재설정할 이메일 주소를 입력하세요.</div>
                </div>
                <div className="form__block">
                    <label htmlFor="email">이메일</label>
                    <input type="email" name="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required placeholder="이메일 주소를 입력해주세요."/>
                </div>
                <div className="form__block--lg">
                    <button type="button" className="form__btn--submit" onClick={onSendResetEmail}>이메일 전송</button>
                </div>
                <div className="form__block--lg">
                    <Link to="/users/login" className="form__link">로그인 화면으로 돌아가기</Link>
                </div>
            </div>
            <Footer />
        </>
    );
}
