import { Route, Routes, Navigate } from "react-router-dom" //기본 세팅 1번
import SignDetail from "pages/signs/detail";
import SignNew from "pages/signs/new";
import SignEdit from "pages/signs/edit";
import AccidentPage from "pages/accidentnews";
import EquipmentPage from "pages/equipment";
import HomePage from "pages/home";
import NewsPage from "pages/news";
import PosterDetail from "pages/posters/detail";
import PosterEdit from "pages/posters/edit";
import PosterPage from "pages/posters";
import PosterNew from "pages/posters/new";
import QnaPage from "pages/qna";
import SearchPage from "pages/search";
import SignsPage from "pages/signs";
import LoginPage from "pages/users/login";
import SignupPage from "pages/users/signup";
import NaverNewsPage from "pages/newsreport";
import NewsPic from "pages/newspic";
import CardNewsPage from "pages/cardnews";
import CardNewsDetailPage from "pages/cardnews/detail";
import CardNewsEditPage from "pages/cardnews/edit";
import CardNewsNewPage from "pages/cardnews/new";
import CardLinkPage from "pages/cardnews/link";
import SmartPage from "pages/smart";
import Verify from "pages/users/verify";
import ForgotPasswordPage from "pages/users/forgotpassword";
import NewsPicEditPage from "pages/newspic/edit";
import NewsPicForm from "./newspic/NewsPicForm";
import MsdsPage from "pages/msds";
import SeriousDisasterPage from "pages/seriousdisaster";
import SeriousDisasterDetail from "pages/seriousdisaster/detail";
import SeriousDisasterEdit from "pages/seriousdisaster/edit";
import SeriousDisasterNew from "pages/seriousdisaster/new";
import UpdateHashTagsComponent from "pages/test";
import SirenrulesRulesDetail from "pages/sirenrulesrules/detail";
import SirenrulesRulesPage from "pages/sirenrulesrules";
import SirenrulesRulesEdit from "pages/sirenrulesrules/edit";
import SirenrulesRulesNew from "pages/sirenrulesrules/new";
import EtcPage from "pages/etc";
import EtcDetail from "pages/etc/detail";
import EtcEdit from "pages/etc/edit";
import EtcNew from "pages/etc/new";
import Administrator from "pages/administrator";
import PrivacyPolicy from "pages/home/PrivacyPolicy";
import CopyrightPolicy from "pages/home/CopyrightPolicy";
import TermsOfService from "pages/home/TermsOfService";
import IndustrialAccidentPage from "pages/industrialaccident";
import DisasterNewsPage from "pages/disastercase";
import NewsReportPage from "pages/newsreport";
import NewsReportDetailPage from "pages/newsreport/detail";
import NewsReportEditPage from "pages/newsreport/edit";
import NewsReportNewPage from "pages/newsreport/new";
import NewsReportLinkPage from "pages/newsreport/link";
import PopupPage from "pages/popup";
import SafetyImgPage from "pages/safetyimg";
import SafetyImgDetail from "pages/safetyimg/detail";
import SafetyImgNew from "pages/safetyimg/new";
import SefetyImgEdit from "pages/safetyimg/edit";


interface RouterProps {
    isAuth: boolean;
}

export default function Router({ isAuth }: RouterProps) {
    return (
        <Routes>
            {isAuth ? (
                <>
                    <Route path="/" element={<HomePage />} />   
                    <Route path="/popup" element={<PopupPage />} />                                     
                    <Route path="/test" element={<UpdateHashTagsComponent />} />
                    <Route path="/administrator" element={<Administrator />} />
                    <Route path="/newsreport" element={<NewsReportPage />} />
                    <Route path="/newsreport/:id" element={<NewsReportDetailPage />} />
                    <Route path="/newsreport/edit/:id" element={<NewsReportEditPage />} />
                    <Route path="/newsreport/new" element={<NewsReportNewPage />} />
                    <Route path="/newsreport/link/:id" element={<NewsReportLinkPage />} />
                    <Route path="/smart" element={<SmartPage />} />
                    <Route path="/seriousdisaster" element={<SeriousDisasterPage />} />
                    <Route path="/seriousdisaster/:id" element={<SeriousDisasterDetail />} />
                    <Route path="/seriousdisaster/edit/:id" element={<SeriousDisasterEdit />} />
                    <Route path="/seriousdisaster/new" element={<SeriousDisasterNew />} />
                    <Route path="/sirenrules" element={<SirenrulesRulesPage />} />
                    <Route path="/sirenrules/:id" element={<SirenrulesRulesDetail />} />
                    <Route path="/sirenrules/edit/:id" element={<SirenrulesRulesEdit />} />
                    <Route path="/sirenrules/new" element={<SirenrulesRulesNew />} />
                    <Route path="/industrialaccident" element={<IndustrialAccidentPage />} />
                    <Route path="/news" element={<NewsPage />} />
                    <Route path="/newspic" element={<NewsPic />} />
                    <Route path="/newspic/edit/:id" element={<NewsPicEditPage />} />
                    <Route path="/newspic/new" element={<NewsPicForm />} />
                    <Route path="/navernews" element={<NaverNewsPage />} />
                    <Route path="/accidents" element={<AccidentPage />} />
                    <Route path="/equipment" element={<EquipmentPage />} />
                    <Route path="/msds" element={<MsdsPage />} />
                    <Route path="/poster" element={<PosterPage />} />
                    <Route path="/poster/:id" element={<PosterDetail />} />
                    <Route path="/poster/new" element={<PosterNew />} />
                    <Route path="/poster/edit/:id" element={<PosterEdit />} />
                    <Route path="/sign" element={<SignsPage />} />
                    <Route path="/sign/:id" element={<SignDetail />} />
                    <Route path="/sign/new" element={<SignNew />} />
                    <Route path="/sign/edit/:id" element={<SignEdit />} />
                    <Route path="/cardnews" element={<CardNewsPage />} />
                    <Route path="/cardnews/:id" element={<CardNewsDetailPage />} />
                    <Route path="/cardnews/edit/:id" element={<CardNewsEditPage />} />
                    <Route path="/cardnews/new" element={<CardNewsNewPage />} />
                    <Route path="/cardnews/link/:id" element={<CardLinkPage />} />
                    <Route path="/etc" element={<EtcPage />} />
                    <Route path="/etc/:id" element={<EtcDetail />} />
                    <Route path="/etc/edit/:id" element={<EtcEdit />} />
                    <Route path="/etc/new" element={<EtcNew />} />
                    <Route path="/safetyimg" element={<SafetyImgPage />} />
                    <Route path="/safetyimg/:id" element={<SafetyImgDetail />} />
                    <Route path="/safetyimg/new" element={<SafetyImgNew />} />
                    <Route path="/safetyimg/edit/:id" element={<SefetyImgEdit />} />
                    <Route path="/qna" element={<QnaPage />} />
                    <Route path="/users/login" element={<LoginPage />} />
                    <Route path="/users/signup" element={<SignupPage />} />
                    <Route path="/users/verify" element={<Verify />} />
                    <Route path="/users/forgot-password" element={<ForgotPasswordPage />} />
                    <Route path="/search" element={<SearchPage />} />
                    <Route path="/disasternews" element={<DisasterNewsPage />} />
                    <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                    <Route path="/copyrightpolicy" element={<CopyrightPolicy />} />
                    <Route path="/termsofservice" element={<TermsOfService />} />
                    <Route path="*" element={<Navigate replace to="/" />} />
                </>
            ) : (
                <>
                    <Route path="/" element={<HomePage />} />                    
                    <Route path="/test" element={<UpdateHashTagsComponent />} />
                    <Route path="/administrator" element={<Administrator />} />
                    <Route path="/newsreport" element={<NewsReportPage />} />
                    <Route path="/newsreport/:id" element={<NewsReportDetailPage />} />
                    <Route path="/newsreport/edit/:id" element={<NewsReportEditPage />} />
                    <Route path="/newsreport/new" element={<NewsReportNewPage />} />
                    <Route path="/newsreport/link/:id" element={<NewsReportLinkPage />} />
                    <Route path="/smart" element={<SmartPage />} />
                    <Route path="/seriousdisaster" element={<SeriousDisasterPage />} />
                    <Route path="/seriousdisaster/:id" element={<SeriousDisasterDetail />} />
                    <Route path="/seriousdisaster/edit/:id" element={<SeriousDisasterEdit />} />
                    <Route path="/seriousdisaster/new" element={<SeriousDisasterNew />} />
                    <Route path="/sirenrules" element={<SirenrulesRulesPage />} />
                    <Route path="/sirenrules/:id" element={<SirenrulesRulesDetail />} />
                    <Route path="/sirenrules/edit/:id" element={<SirenrulesRulesEdit />} />
                    <Route path="/sirenrules/new" element={<SirenrulesRulesNew />} />
                    <Route path="/industrialaccident" element={<IndustrialAccidentPage />} />
                    <Route path="/news" element={<NewsPage />} />
                    <Route path="/newspic" element={<NewsPic />} />
                    <Route path="/newspic/edit/:id" element={<NewsPicEditPage />} />
                    <Route path="/newspic/new" element={<NewsPicForm />} />
                    <Route path="/navernews" element={<NaverNewsPage />} />
                    <Route path="/accidents" element={<AccidentPage />} />
                    <Route path="/equipment" element={<EquipmentPage />} />
                    <Route path="/msds" element={<MsdsPage />} />
                    <Route path="/poster" element={<PosterPage />} />
                    <Route path="/poster/:id" element={<PosterDetail />} />
                    <Route path="/poster/new" element={<PosterNew />} />
                    <Route path="/poster/edit/:id" element={<PosterEdit />} />
                    <Route path="/sign" element={<SignsPage />} />
                    <Route path="/sign/:id" element={<SignDetail />} />
                    <Route path="/sign/new" element={<SignNew />} />
                    <Route path="/sign/edit/:id" element={<SignEdit />} />
                    <Route path="/cardnews" element={<CardNewsPage />} />
                    <Route path="/cardnews/:id" element={<CardNewsDetailPage />} />
                    <Route path="/cardnews/edit/:id" element={<CardNewsEditPage />} />
                    <Route path="/cardnews/new" element={<CardNewsNewPage />} />
                    <Route path="/cardnews/link/:id" element={<CardLinkPage />} />
                    <Route path="/etc" element={<EtcPage />} />
                    <Route path="/etc/:id" element={<EtcDetail />} />
                    <Route path="/etc/edit/:id" element={<EtcEdit />} />
                    <Route path="/etc/new" element={<EtcNew />} />
                    <Route path="/safetyimg" element={<SafetyImgPage />} />
                    <Route path="/safetyimg/:id" element={<SafetyImgDetail />} />
                    <Route path="/safetyimg/new" element={<SafetyImgNew />} />
                    <Route path="/safetyimg/edit/:id" element={<SefetyImgEdit />} />
                    <Route path="/qna" element={<QnaPage />} />
                    <Route path="/users/login" element={<LoginPage />} />
                    <Route path="/users/signup" element={<SignupPage />} />
                    <Route path="/users/verify" element={<Verify />} />
                    <Route path="/users/forgot-password" element={<ForgotPasswordPage />} />
                    <Route path="/search" element={<SearchPage />} />
                    <Route path="/disasternews" element={<DisasterNewsPage />} />
                    <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                    <Route path="/copyrightpolicy" element={<CopyrightPolicy />} />
                    <Route path="/termsofservice" element={<TermsOfService />} />
                    <Route path="*" element={<Navigate replace to="/" />} />
                </>)}
        </Routes>
    );
}