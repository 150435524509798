import { useCallback, useContext, useEffect, useState } from "react";
import { updateDoc, doc, getDoc } from "firebase/firestore";
import { db, storage } from "firevaseApp";
import { useNavigate, useParams } from "react-router-dom";

import { toast } from "react-toastify";

import { FiImage } from "react-icons/fi";
import { PosterProps } from "pages/posters";
import Header from "components/Header";

import { v4 as uuidv4 } from 'uuid';
import AuthContext from "context/AuthContext";
import { getDownloadURL, ref, uploadString, deleteObject } from "firebase/storage";

export default function PosterEditform() {
    const params = useParams();
    const [poster, setPoster] = useState<PosterProps | null>(null);
    const [title, setTitle] = useState<string>("");
    const [hashTag, setHashTag] = useState<string>("");
    const [tags, setTags] = useState<string[]>([]);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);//중복 이미지 등록 방지
    const [imageFile, setImageFile] = useState<string | null>(null);
    const { user } = useContext(AuthContext); //유저정보 받아오기

    const navigate = useNavigate();

    const handleFileUpload = (e: any) => {
        const {
            target: { files },
        } = e;

        const file = files?.[0];
        const fileReader = new FileReader();
        fileReader?.readAsDataURL(file);

        fileReader.onloadend = (e: any) => {
            const { result } = e?.currentTarget;
            setImageFile(result);
        };
    };

    const handleDeleteImage = () => {
        console.log("삭제1"+ poster?.imageUrl);
        setImageFile(null);
        console.log("삭제2"+ poster?.imageUrl);
    };

    const handleDrop = (event: any) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        const fileReader = new FileReader();

        fileReader.onloadend = (e: any) => {
            const { result } = e?.currentTarget;
            setImageFile(result);
        };

        fileReader.readAsDataURL(file);
    };


    const getPoster = useCallback(async () => {
        if (params.id) {
            const docRef = doc(db, "posters", params.id);
            const docSnap = await getDoc(docRef);

            setPoster({ ...(docSnap?.data() as PosterProps), id: docSnap.id }); //데이터 확인
            setTitle(docSnap?.data()?.title); //기존 title 넣기
            setTags(docSnap?.data()?.hashTags); //기존 hashTag 넣기
            setImageFile(docSnap?.data()?.imageUrl); //기존 이미지 넣기
        }
    }, [params.id]);

    const onSubmit = async (e: any) => {
        setIsSubmitting(true);
        const key = `${user?.uid}/${uuidv4()}`; //고유 키값
        const storageRef = ref(storage, key);

        e.preventDefault(); //폼이 넘어가지 않도록 설정

        try {
            if (poster) {
                let imageUrl = poster?.imageUrl || "";
                if (!title) {
                    toast.success("제목을 입력하세요.");
                    setIsSubmitting(false);
                    return;
                } else if (tags.length < 1) {
                    toast.success("태그를 등록하세요.");
                    setIsSubmitting(false);
                    return;
                } else if (!imageFile) {
                    toast.success("이미지를 등록하세요.");
                    setIsSubmitting(false);
                    return;
                } else {
                    console.log("1번 "+ poster?.imageUrl);
                    //기존 사진과 새로운 사진이 다를 경우에만 사진을 업로드
                    if (imageFile && poster?.imageUrl !== imageFile) {
                        //기존 사진 지우기
                        console.log("2번 "+ poster?.imageUrl);
                        if (poster?.imageUrl) {
                            let imageRef = ref(storage, poster?.imageUrl);
                            await deleteObject(imageRef).catch((error) => {
                                console.log(error);
                            });
                            console.log("3번 "+ poster?.imageUrl);
                        }
                        //새로운 사진 업로드
                        const data = await uploadString(storageRef, imageFile, "data_url");
                        imageUrl = await getDownloadURL(data?.ref);
                        console.log("4번 "+ poster?.imageUrl);
                    }

                    //만약 사진이 아예 없다면 삭제
                    const posterRef = doc(db, "posters", poster?.id);
                    await updateDoc(posterRef, {
                        title: title,
                        hashTags: tags,
                        imageUrl: imageUrl,
                    });
                    console.log("5번 "+ poster?.imageUrl);
                    navigate("/poster/");
                    toast.success("포스터를 수정하였습니다.");
                }
                setImageFile(null);
                setIsSubmitting(false);
            }
        } catch (e: any) {
            console.log(e);
        }
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {
            target: { name, value }, //타이핑 찍기
        } = e;

        if (name === "title") {
            setTitle(value);// 입력값 업데이트
        }
    };

    const removeTag = (tag: string) => {
        setTags(tags?.filter((val) => val !== tag));
    };

    const onChangeHashTag = (e: any) => {
        setHashTag(e?.target?.value?.trim());
    };

    const handleKeyUp = (e: any) => {
        if (e.keyCode === 32 && e.target.value.trim() !== "") {
            // 만약 같은 태그가 있다면 에러를 띄운다
            // 아니라면 태그를 생성해준다
            if (tags?.includes(e.target.value?.trim())) {
                toast.error("같은 태그가 있습니다.");
            } else {
                setTags((prev) => (prev?.length > 0 ? [...prev, hashTag] : [hashTag]));
                setHashTag("");
            }
        }
    };
   

    useEffect(() => {
        if (params.id) getPoster(); //id가 있다면 호출
    }, [getPoster, params.id])

    return (
        <>
            <Header />
            <form className="poster-form" onSubmit={onSubmit}>
                <div className="poster-form__title">안전보건포스터 수정</div>
                <input className="poster-form__input" required name="title" id="title" placeholder="타이틀 입력" onChange={onChange} value={title} />
                <div className="poster-form__hashtages">
                    <span className="poster-form__hashtages-outputs">
                        {tags?.map((tag, index) => (
                            <span className="poster-form__hashtages-tag" key={index} onClick={() => removeTag(tag)}>#{tag}</span>
                        ))}
                    </span>
                    <input
                        className="poster-form__hashtages-input"
                        type="text"
                        name="hashteg"
                        id="hashteg"
                        placeholder="태그명 + 스페이스바 입력"
                        onChange={onChangeHashTag}
                        onKeyUp={handleKeyUp}
                        value={hashTag}
                    />
                </div>
                <div className="poster-form__submit-input">
                    <div className="poster-form__image-input" onDrop={handleDrop} onDragOver={(e) => e.preventDefault()}>
                        {!imageFile && (
                            <label htmlFor="file-input" className="poster-from__file">
                                <FiImage className="poster-from__file-icon" size={500} />
                            </label>
                        )}
                        <input type="file" id="file-input" name="file-input" accept="image/*" onChange={handleFileUpload} className="hidden" />
                        {imageFile && (
                            <div className="poster-form__attachment">
                                <img src={imageFile} alt="attachment" width={300} />
                                <button className="poster-form__clear-btn" type="button" onClick={handleDeleteImage}>
                                    이미지 삭제
                                </button>
                            </div>
                        )}
                    </div>
                    <input type="submit" value="수정" className="poster-form__submit-btn" disabled={isSubmitting} />
                </div>
            </form>
        </>
    );
};