import DisasterNewsList from "components/disasternews/DisasterNewsList";
import Footer from "components/Footer";
import Header from "components/Header";

export default function DisasterNewsPage() {
  return (
    <>
      <Header />
      <DisasterNewsList />
      <Footer />
    </>
  );
}
