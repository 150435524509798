import { useContext, useEffect, useState } from "react";
import AuthContext from "context/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import { app } from "firevaseApp";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { BiUserCircle } from "react-icons/bi";
import { IoIosLogIn, IoIosLogOut } from "react-icons/io";

let currentPath = "";

interface DropdownStates {
  smartSearch: boolean;
  safetyNews: boolean;
  majorAccidents: boolean;
  referenceMaterials: boolean;
  msds: boolean;
  admin: boolean;
  industrialaccident: boolean;
}

const exception1 = process.env.REACT_APP_EXEMPT_EMAILS1 || "";
const exception2 = process.env.REACT_APP_EXEMPT_EMAILS2 || "";
const EXEMPT_EMAILS = [exception1, exception2].filter(
  (email) => email.trim() !== ""
);

export default function Header() {
  const exception1 = process.env.REACT_APP_ADMIN_EMAIL1 || "";
  const exception2 = process.env.REACT_APP_ADMIN_EMAIL2 || "";
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  let location = useLocation();
  const [dropdownStates, setDropdownStates] = useState<DropdownStates>({
    smartSearch: false,
    safetyNews: false,
    majorAccidents: false,
    referenceMaterials: false,
    msds: false,
    admin: false,
    industrialaccident: false,
  });

  useEffect(() => {
    if (currentPath === location.pathname) window.location.reload();

    currentPath = location.pathname;
  }, [location.pathname]);

  useEffect(() => {
    // 사용자가 로그인하고 이메일이 인증되지 않은 경우에 "/users/verify"로 리디렉션
    if (
      user &&
      user !== null &&
      !user.emailVerified &&
      !EXEMPT_EMAILS.includes(user.email || "")
    ) {
      navigate("/users/verify");
    }
  }, [user, navigate]); // Only re-render when user or navigate function changes

  const handleMouseEnter = (dropdownName: keyof DropdownStates) => {
    setDropdownStates((prevState) => ({
      ...prevState,
      [dropdownName]: true,
    }));
  };

  const handleMouseLeave = (dropdownName: keyof DropdownStates) => {
    setDropdownStates((prevState) => ({
      ...prevState,
      [dropdownName]: false,
    }));
  };

  return (
    <div className="header">
      <div className="header__box">
        <div className="header__top">
          <h1 className="header__logo">
            <Link to={"/"}>
              <img src="\img\logo.png" alt="로고" />
            </Link>
          </h1>
          <nav className="header__nav">
            <ul className="header__nav-ul">
              <Link to={"/smart"}>
                <li
                  className="header__nav-ul-li"
                  onMouseEnter={() => handleMouseEnter("smartSearch")}
                  onMouseLeave={() => handleMouseLeave("smartSearch")}
                >
                  스마트검색
                  <ul
                    className={`dropdown__menu-5 ${
                      dropdownStates.smartSearch ? "show" : ""
                    }`}
                  >
                    <li className="line-none">
                      <div className="line"></div>
                    </li>
                  </ul>
                </li>
              </Link>
              <li
                className="header__nav-ul-li"
                onMouseEnter={() => handleMouseEnter("safetyNews")}
                onMouseLeave={() => handleMouseLeave("safetyNews")}
              >
                안전뉴스
                <ul
                  className={`dropdown__menu ${
                    dropdownStates.safetyNews ? "show" : ""
                  }`}
                >
                  {/* <li>                    
                    <Link to={"/newsreport"}>일일 리포트</Link>
                  </li> */}
                  {/* <li>
                    
                    <Link to={"/newspic"}>뉴스 기사</Link>
                  </li> */}
                  <li>
                  <div className="line"></div>
                    <Link to={"/accidents"}>사망사고 알림</Link>
                  </li>
                </ul>
              </li>
              <li
                className="header__nav-ul-li"
                onMouseEnter={() => handleMouseEnter("majorAccidents")}
                onMouseLeave={() => handleMouseLeave("majorAccidents")}
              >
                중대재해
                <ul
                  className={`dropdown__menu ${
                    dropdownStates.majorAccidents ? "show" : ""
                  }`}
                >
                  <li>
                    <div className="line"></div>
                    <Link to={"/seriousdisaster"}>사이렌</Link>
                  </li>
                  <li>
                    <Link to={"/sirenrules"}>예방자료</Link>
                  </li>
                </ul>
              </li>

              <li
                className="header__nav-ul-li"
                onMouseEnter={() => handleMouseEnter("referenceMaterials")}
                onMouseLeave={() => handleMouseLeave("referenceMaterials")}
              >
                칼퇴자료
                <ul
                  className={`dropdown__menu ${
                    dropdownStates.referenceMaterials ? "show" : ""
                  }`}
                >
                  <li>
                    <div className="line"></div>
                    <Link to={"/sign"}>안전보건표지</Link>
                  </li>
                  <li>
                    <Link to={"/etc"}>안전표지판</Link>
                  </li>
                  <li>
                    <Link to={"/poster"}>포스터</Link>
                  </li>
                  <li>
                    <Link to={"/cardnews"}>카드뉴스</Link>
                  </li>
                  <li>
                    <Link to={"/safetyimg"}>안전이미지</Link>
                  </li>
                </ul>
              </li>

              {(user?.uid === exception1 || user?.uid === exception2) && (
                <>
                  <li
                    className="header__nav-ul-li"
                    onMouseEnter={() => handleMouseEnter("admin")}
                    onMouseLeave={() => handleMouseLeave("admin")}
                  >
                    관리자
                    <ul
                      className={`dropdown__menu ${
                        dropdownStates.admin ? "show" : ""
                      }`}
                    >
                      <li>
                        <div className="line"></div>
                        <Link to={"/administrator"}>데이터확인</Link>
                      </li>
                      <li>
                        <Link to={"/popup"}>팝업 관리</Link>
                      </li>
                      <li>
                        <Link to={"/industrialaccident"}>산재보험판결문</Link>
                      </li>
                    </ul>
                  </li>
                </>
              )}
            </ul>
          </nav>
          <div className="header__login">
            {user === null ? (
              <>
                {/* <button type="button" onClick={() => navigate("/users/signup")} className="hover_white">
                                    <BiUserCircle className="header__icon" /> 회원가입
                                </button> */}
                <button
                  type="button"
                  onClick={() => navigate("/users/login")}
                  className="hover_white"
                >
                  <IoIosLogIn className="header__icon" /> 로그인
                </button>
              </>
            ) : (
              <button
                type="button"
                onClick={async () => {
                  const auth = getAuth(app);
                  await signOut(auth);
                  toast.success("로그아웃 되었습니다.");
                }}
                className="hover_red"
              >
                <IoIosLogOut className="header__icon" /> Logout
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
