import AuthContext from "context/AuthContext";

import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { Link, useNavigate } from "react-router-dom";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "firevaseApp";
import { ref, deleteObject, getDownloadURL, } from "firebase/storage";
import { storage } from "firevaseApp";

import { SeriousDisasterProps } from "pages/seriousdisaster";
import { FaArrowRightLong } from "react-icons/fa6";

interface PosterBoxProps {
    seriousDisaster: SeriousDisasterProps;
}

export default function SeriousDisasterDetailForm({ seriousDisaster }: PosterBoxProps) {
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();
    const imageRef = ref(storage, seriousDisaster?.imageUrl);    

    //게시글 삭제
    const handleDelete = async () => {
        const confirm = window.confirm("해당 게시글을 삭제하시곗습니까?");
        if (confirm) {
            //스토리지 이미지 먼저 삭제
            if (seriousDisaster?.imageUrl) {
                deleteObject(imageRef).catch((error) => {
                    console.log(error);
                })
            };

            await deleteDoc(doc(db, "seriousDisasters", seriousDisaster.id));
            toast.success("게시글이 삭제되었습니다.")
            navigate("/seriousdisaster")
        };
    };

    //파일 다운로드
    const handleDownload = async () => {
        if (seriousDisaster?.imageUrl) {
            getDownloadURL(imageRef)
                .then((url) => {
                    // `url` is the download URL for 'images/stars.jpg'

                    // This can be downloaded directly:
                    const xhr = new XMLHttpRequest();
                    xhr.responseType = 'blob';
                    xhr.onload = (event) => {
                        const blob = xhr.response;
                        const blobURL = URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = blobURL;
                        link.download = seriousDisaster?.registrationdate + '.jpg';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    };
                    xhr.open('GET', url);
                    xhr.send();

                })
                .catch((error) => {
                    console.error(error);
                });
            const posterRef = doc(db, "seriousDisasters", seriousDisaster.id);
            await updateDoc(posterRef, {
                downCount: seriousDisaster?.downCount ? seriousDisaster?.downCount + 1 : 1,
            });
        };
    };

    useEffect(() => {
        const handleContextMenu = (event: Event) => {
            event.preventDefault();
        };
    
        const elements = document.querySelectorAll('img, button');
        elements.forEach(element => {
            element.addEventListener('contextmenu', handleContextMenu);
        });
    
        return () => {
            elements.forEach(element => {
                element.removeEventListener('contextmenu', handleContextMenu);
            });
        };
    }, []);

    return (
        <div className="poster__box" key={seriousDisaster?.id}>
            <div className="poster__box-profile poster__box-profile-detail">
                <div className="poster__box-title">배포일자 : {seriousDisaster?.registrationdate}</div>
                <Link to={"/seriousdisaster"}>
                    <button type="button" className="poster__box-list">
                        <FaArrowRightLong className="more__btn-icon" />
                        <div>목록</div>
                    </button>
                </Link>
            </div>
            <div className="poster-form__hashtages-outputs poster-form__hashtages-outputs-detail">
                {seriousDisaster?.hashTags?.map((tag, index) => (
                    <span className="poster-form__hashtages-tag" key={index} >{tag}</span>
                ))}
            </div>
            {seriousDisaster?.imageUrl && (
                <div className="poster__image-div poster__image-div-detail">
                    <div><img src={seriousDisaster?.imageUrl} alt="poster img" className="poster__image" /></div>
                    {/* <div><img src={seriousDisaster?.imageUrl} alt="poster img" className="poster__ad" /></div> */}
                </div>
            )}

            <div className="poster__down">
                <button className="poster__down-btn" onClick={handleDownload}>다운로드</button>
                {/* <div className="poster__down-guide">
                    <div className="poster__down-guide1">출처를 표시하지 않고 사업장에서 자유롭게 사용하세요!</div>
                    <div className="poster__down-guide2">(ⓒ소소안전 상업적 사용, 수정 및 재 배포는 금지합니다.)</div>
                </div> */}
            </div>
            <div className="poster__box-footer">
                {/* poster.uid === user.uid 일때 */}
                {user?.uid === seriousDisaster?.uid && (
                    <>
                        <div className="poster__Count">
                            <div className="poster__Count-cont">좋아요 {seriousDisaster?.likeCount || 0}</div>
                            <div className="poster__Count-cont">조회수 {seriousDisaster?.views || 0}</div>
                            <div className="poster__Count-cont">다운수 {seriousDisaster?.downCount || 0}</div>
                            <div className="poster__Count-cont">{seriousDisaster?.createdAt || 0}</div>
                        </div>
                        <Link to={`/seriousdisaster/edit/${seriousDisaster?.id}`}>
                            <button type="button" className="poster__edit">
                                수정
                            </button>
                        </Link>
                        <button type="button" className="poster__delete" onClick={handleDelete}>
                            삭제
                        </button>
                    </>
                )}
                {user?.uid === "Qc78Qe8C7ReWlK9roSR3FQVUcHz2" && user?.uid !== seriousDisaster?.uid && (
                    <>
                        <div className="poster__Count">
                            <div className="poster__Count-cont">좋아요 {seriousDisaster?.likeCount || 0}</div>
                            <div className="poster__Count-cont">조회수 {seriousDisaster?.views || 0}</div>
                            <div className="poster__Count-cont">{seriousDisaster?.createdAt || 0}</div>
                        </div>
                        <button type="button" className="poster__edit">
                            <Link to={`/seriousdisaster/edit/${seriousDisaster?.id}`}>
                                수정
                            </Link>
                        </button>
                        <button type="button" className="poster__delete" onClick={handleDelete}>
                            삭제
                        </button>
                    </>
                )}
            </div>
        </div>
    );
}