import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AuthContext from "context/AuthContext";

import {
  collection,
  query,
  onSnapshot,
  orderBy,
  where,
  limit,
  startAfter,
  QueryDocumentSnapshot,
  getDocs,
} from "firebase/firestore";
import { db } from "firevaseApp";

import { IoSearch } from "react-icons/io5";
import { FaArrowRightLong } from "react-icons/fa6";
import { AiFillHeart } from "react-icons/ai";

import Footer from "components/Footer";
import Header from "components/Header";
import SirenrulesRulesBox from "components/sirenrules/SirenRulesBox";
import { Helmet } from "react-helmet-async";
import Loader from "components/loader/Loader";
import AdComponent from "components/AdSense";

export interface SirenrulesRuleProps {
  id: string;
  email: string;
  title: string;
  createdAt: string;
  uid: string;
  admin?: boolean;
  profileUrl?: string;
  likes?: string[];
  likeCount?: number;
  hashTags?: string[];
  imageUrl?: string;
  views?: number;
  downCount?: number;
  currentPage?: number;
}

type TabType = "" | "건설" | "장마" | "해빙기" | "안전수칙" | "점검";

export default function SirenrulesRulesPage() {
  const exception1 = process.env.REACT_APP_ADMIN_EMAIL1 || "";
  const exception2 = process.env.REACT_APP_ADMIN_EMAIL2 || "";
  const [sirenrules, setSirenRules] = useState<SirenrulesRuleProps[]>([]);
  const [lastVisible, setLastVisible] = useState<QueryDocumentSnapshot | null>(
    null
  ); // 마지막으로 로드된 문서 추적
  const { user } = useContext(AuthContext);
  const [tagQuery, setTagQuery] = useState<string>(""); // 검색창의 input
  const [filter, setFilter] = useState<string>("createdAt"); // 초기 필터
  const [activeTab, setActiveTab] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const itemsPerPage = 9; // 페이지당 표시할 항목 수

  // 검색 관련
  const sanitizeInput = (input: string): string => {
    // 특수 문자 제거 및 길이 제한 (최대 10자)
    return input.replace(/[<>\/]/g, "").substring(0, 10);
  };
  // const onChangeFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
  //     setTagQuery(sanitizeInput(e.target.value)); // 검색창에 입력되는 값을 tagQuery로 저장
  // };

  const onClickFilter = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const value = sanitizeInput(e.currentTarget.value) as TabType;

    if (activeTab === value) {
      setActiveTab("");
      setTagQuery("");
      handleSearch("");
    } else {
      setActiveTab(value);
      setTagQuery(value);
      handleSearch(value);
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const sanitizedQuery = sanitizeInput(e.currentTarget.value.trim());
      if (activeTab === sanitizedQuery) {
        setActiveTab("");
        setTagQuery("");
        handleSearch("");
      } else {
        setActiveTab(sanitizedQuery);
        setTagQuery(sanitizedQuery);
        handleSearch(sanitizedQuery);
      }
    }
  };

  const onClickTag = (tag: string) => {
    const sanitizedQuery = sanitizeInput(tag);
    if (activeTab === sanitizedQuery) {
      setActiveTab("");
      setTagQuery("");
      handleSearch("");
    } else {
      setActiveTab(sanitizedQuery);
      setTagQuery(sanitizedQuery);
      handleSearch(sanitizedQuery);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTagQuery(sanitizeInput(e.target.value)); // 검색창에 입력되는 값을 tagQuery로 저장
  };
  // 검색
  const handleSearch = async (tagQuery: string) => {
    let postRef = collection(db, "sirenrules"); // 정보 가져오기 레퍼런스
    let postQuery;
    setLoading(true);

    if (tagQuery === "찜" && user?.uid) {
      postQuery = query(
        postRef,
        where("likes", "array-contains", user.uid),
        orderBy(filter, "desc"),
        limit(itemsPerPage)
      );
    } else if (tagQuery) {
      postQuery = query(
        postRef,
        where("hashTags", "array-contains-any", [tagQuery]),
        orderBy(filter, "desc"),
        limit(itemsPerPage)
      );
    } else {
      // 최초 쿼리
      postQuery = query(postRef, orderBy(filter, "desc"), limit(itemsPerPage)); // 쿼리 생성
      window.scrollTo({ top: 0, behavior: "smooth" }); // 페이지 맨 위로 스크롤
    }

    const unsubscribe = onSnapshot(postQuery, (snapShot) => {
      let lastVisible = snapShot.docs[snapShot.docs.length - 1];
      let dataObj = snapShot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setSirenRules(dataObj as SirenrulesRuleProps[]);
      setLastVisible(lastVisible);
      // 강제로 1초 지연
      setTimeout(() => {
        setLoading(false);
      }, 500);
    });
    window.scrollTo({ top: 100, behavior: "smooth" }); // 페이지 맨 위로 스크롤
    return () => unsubscribe();
  };

  // 추가 로드 기능 수정
  const loadMore = async () => {
    if (!lastVisible) return;

    let postRef = collection(db, "sirenrules");
    let postQuery;
    if (tagQuery === "찜" && user?.uid) {
      postQuery = query(
        postRef,
        where("likes", "array-contains", user.uid),
        orderBy(filter, "desc"),
        startAfter(lastVisible),
        limit(itemsPerPage)
      );
    } else if (tagQuery) {
      postQuery = query(
        postRef,
        where("hashTags", "array-contains-any", [tagQuery]),
        orderBy(filter, "desc"),
        startAfter(lastVisible),
        limit(itemsPerPage)
      );
    } else {
      postQuery = query(
        postRef,
        orderBy(filter, "desc"),
        startAfter(lastVisible),
        limit(itemsPerPage)
      );
    }

    const snapShot = await getDocs(postQuery); // getDocs로 변경
    let newLastVisible = snapShot.docs[snapShot.docs.length - 1] || null;
    let dataObj = snapShot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    })) as SirenrulesRuleProps[];

    setSirenRules((prevPosters) => {
      const updatedPosters = [...prevPosters, ...dataObj].reduce(
        (acc, curr) => {
          if (!acc.find((item) => item.id === curr.id)) {
            acc.push(curr);
          }
          return acc;
        },
        [] as SirenrulesRuleProps[]
      );
      return updatedPosters;
    });
    setLastVisible(newLastVisible);
  };

  useEffect(() => {
    handleSearch(tagQuery);
    window.scrollTo({ top: -100, behavior: "smooth" }); // 페이지 맨 위로 스크롤
  }, [filter]);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="중대재해 사이렌 예방자료 키워드로 모아보기"
        />
        <meta property="og:title" content="소소안전" />
        <meta
          property="og:description"
          content="중대재해 사이렌 예방자료 키워드로 모아보기"
        />
        <meta
          property="og:image"
          content="https://sososafety.shop/main_slider.png"
        />
        <meta
          property="og:url"
          content="https://sososafety.shop/seriousdisaster"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <Header />
      <div className="posters">
        <div className="posters__title">예방자료</div>
        {(user?.uid === exception1 || user?.uid === exception2) && (
          <Link to={"/sirenrules/new"} className="posters__new">
            예방자료 등록
          </Link>
        )}
        <div className="search__box">
          <div className="searchs__top">
            <div className="searchs__search-div">
              <div className="searchs__box">
                <input
                  className="searchs__search"
                  value={tagQuery}
                  placeholder="키워드를 검색하세요."
                  onChange={onChange}
                  onKeyUp={handleKeyUp}
                />
                <button
                  type="button"
                  value={tagQuery}
                  onClick={onClickFilter}
                  className="searchs__search-btn"
                >
                  <IoSearch
                    color="white"
                    size={25}
                    className="searchs__search-btnimg"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="tags">
          <div className="tags__box">
            <button
              type="button"
              value={"건설"}
              onClick={onClickFilter}
              className={`tags_tag ${
                activeTab === "건설" && "tags_tag--active"
              }`}
            >
              건설
            </button>
            <button
              type="button"
              value={"장마"}
              onClick={onClickFilter}
              className={`tags_tag ${
                activeTab === "장마" && "tags_tag--active"
              }`}
            >
              장마
            </button>
            <button
              type="button"
              value={"해빙기"}
              onClick={onClickFilter}
              className={`tags_tag ${
                activeTab === "해빙기" && "tags_tag--active"
              }`}
            >
              해빙기
            </button>
            <button
              type="button"
              value={"안전수칙"}
              onClick={onClickFilter}
              className={`tags_tag ${
                activeTab === "안전수칙" && "tags_tag--active"
              }`}
            >
              안전수칙
            </button>
            <button
              type="button"
              value={"점검"}
              onClick={onClickFilter}
              className={`tags_tag ${
                activeTab === "점검" && "tags_tag--active"
              }`}
            >
              점검
            </button>
            <button
              type="button"
              value={"찜"}
              onClick={onClickFilter}
              className={`tags_tag ${activeTab === "찜" && "tags_tag--active"}`}
            >
              <AiFillHeart /> {/* Add heart icon */}
            </button>
          </div>
        </div>

        {/* poster list */}
        {loading ? (
          <>
            <div className="loader_gap">
              <Loader />
              <div className="loader_gap-title">
                안전을 위해 열심히 검색 중..
              </div>
            </div>
          </>
        ) : (
          <div className="poster">
            <div className="addsense_smart">
            <AdComponent />
            </div>
            {sirenrules?.length > 0 ? (
              sirenrules.map((sirenrule) => (
                <SirenrulesRulesBox
                  sirenrule={sirenrule}
                  key={sirenrule.id}
                  onClickTag={onClickTag}
                  currentPage={1}
                  setSirenRules={setSirenRules}
                />
              ))
            ) : (
              <div className="poster__no-posters">
                <div className="poster__text">게시글이 없습니다.</div>
              </div>
            )}
          </div>
        )}
        {lastVisible && (
          <button className="more__btn" onClick={loadMore}>
            <FaArrowRightLong className="more__btn-icon" />
            <div>더 보기</div>
          </button>
        )}
      </div>
      <Footer />
    </>
  );
}
