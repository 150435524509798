import { useContext, useState } from "react";
import { toast } from "react-toastify";
import { collection, addDoc } from "firebase/firestore";
import { ref } from "firebase/storage";
import { db, storage } from "firevaseApp";
import { useLocation, useNavigate } from "react-router-dom";

import { v4 as uuidv4 } from 'uuid';

import AuthContext from "context/AuthContext";

export default function NewsPicForm() {
    const location = useLocation();
    const hasTag = location.state;
    const [hashTag, setHashTag] = useState<string>(hasTag);
    const [title, setTitle] = useState<string>("");    
    const [source, setSource] = useState<string>("");
    const [registrationdate, setRegistrationdate] = useState<string>("");
    const [imageUrl, setImageUrl] = useState<string>("");
    const [linkUrl, setLinkUrl] = useState<string>("");
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);//중복 이미지 등록 방지
    const { user } = useContext(AuthContext); //유저정보 받아오기
    const navigate = useNavigate();

    const validateDate = (date: string) => {
        const regex = /^\d{4}\.\d{2}\.\d{2}$/;
        return regex.test(date);
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {
            target: { name, value }, //타이핑 찍기
        } = e;

        if (name === "title") {
            setTitle(value);// 입력값 업데이트
        }        
        if (name === "source") {
            setSource(value);// 입력값 업데이트
        }
        if (name === "registrationdate") {
            setRegistrationdate(value);
        }
        if (name === "imageUrl") {
            setImageUrl(value);// 입력값 업데이트
        }
        if (name === "linkUrl") {
            setLinkUrl(value);// 입력값 업데이트
        }
    };

    const onSubmit = async (e: any) => {
        e.preventDefault(); //폼이 넘어가지 않도록 설정

        setIsSubmitting(true);
        const key = `${user?.uid}/${uuidv4()}`; //고유 키값
        const storageRef = ref(storage, key);
        setHashTag(hasTag);
        console.log("onSubmit hasTag"+hasTag)

        try {
            if (!title) {
                toast.success("제목을 입력하세요.");
                setIsSubmitting(false);
            }             
            else if (!source) {
                toast.success("출처를 등록하세요.");
                setIsSubmitting(false);
            }
            else if (!validateDate(registrationdate)) {
                toast.error("등록 일자는 YYYY.MM.DD 형식이어야 합니다.");
                setIsSubmitting(false);
            }
            else if (!imageUrl) {
                toast.success("이미지URL을 등록하세요.");
                setIsSubmitting(false);
            }
            else if (!linkUrl) {
                toast.success("링크URL을 등록하세요.");
                setIsSubmitting(false);
            } else {
                await addDoc(collection(db, 'newspics'), {
                    title: title,
                    createdAt: new Date()?.toLocaleDateString("ko", {
                        year: "numeric",
                        month: "2-digit", 
                        day: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                        hour12: false,
                    }),
                    uid: user?.uid,
                    email: user?.email,
                    source : source,
                    registrationdate : registrationdate,
                    hashTags: hashTag,
                    imageUrl: imageUrl,
                    linkUrl: linkUrl,
                });
                console.log(hasTag)
                setTitle("");                                
                setSource("");
                setRegistrationdate("");
                setIsSubmitting(false);
                toast.success("게시물을 등록하였습니다.");
                navigate("/newspic")
            }
        } catch (e: any) {
            console.log(e);
        }
    };

    return (
        <form className="poster-form" onSubmit={onSubmit}>
        <div className="poster-form__title">{hasTag} 뉴스 등록</div>
        <input className="poster-form__input readOnly" required name="hashTag" id="hashTag" placeholder="해시태그 입력" value={hasTag} readOnly/>
        <input className="poster-form__input" required name="title" id="title" placeholder="타이틀 입력" onChange={onChange} value={title} />        
        <input className="poster-form__input" required name="source" id="source" placeholder="출처 입력" onChange={onChange} value={source} />
        <input className="poster-form__input" required name="registrationdate" id="registrationdate" placeholder="출처 등록일(2024.00.00) 입력" onChange={onChange} value={registrationdate} />
        <input className="poster-form__input" required name="imageUrl" id="imageUrl" placeholder="이미지URL 입력" onChange={onChange} value={imageUrl} />
        <input className="poster-form__input" required name="linkUrl" id="" placeholder="링크URL 입력" onChange={onChange} value={linkUrl} />
        <div className="poster-form__submit-input">
            <input type="submit" value={"등록"} className="poster-form__submit-btn" disabled={isSubmitting} />
        </div>
    </form>
    )
}