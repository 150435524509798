import AuthContext from "context/AuthContext";

import { useContext, useEffect } from "react";
import { toast } from "react-toastify";

import { Link, useNavigate } from "react-router-dom";
import { arrayRemove, arrayUnion, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { db, storage } from "firevaseApp";
import { ref, deleteObject } from "firebase/storage";

import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { SafetyImgProps } from "pages/safetyimg";

interface SafetyImgBoxProps {
    post: SafetyImgProps;
    onClickTag: (tag: string) => void;
    currentPage: number;
    onToggleLike: (updatedPost: SafetyImgProps) => void; // 새로운 prop 추가
}

export default function SafetyImgBox({ post, onClickTag, currentPage, onToggleLike }: SafetyImgBoxProps) {
    const exception1 = process.env.REACT_APP_ADMIN_EMAIL1 || "";
    const exception2 = process.env.REACT_APP_ADMIN_EMAIL2 || "";
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();
    const imageRef = ref(storage, post?.imageUrl);

    //조회수
    const onClickViews = async (event: React.MouseEvent<HTMLAnchorElement>) => {
        // event.preventDefault(); // 기본 동작 방지
        // event.stopPropagation(); // 이벤트 전파 방지

        const postRef = doc(db, "safetyimgs", post.id);
        await updateDoc(postRef, {
            views: post?.views ? post?.views + 1 : 1,
        });        
    };

    // 좋아요
    const toggleLike = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault(); // 기본 동작 방지
        event.stopPropagation(); // 이벤트 전파 방지

        if (!user) {
            navigate("/users/login");
        } else {
            const postRef = doc(db, "safetyimgs", post.id);
            let updatedPost;

            if (user?.uid && post.likes?.includes(user?.uid)) {
                // 사용자가 좋아요를 미리 한 경우 취소
                updatedPost = {
                    ...post,
                    likes: post.likes ? post.likes.filter(uid => uid !== user.uid) : [],
                    likeCount: post?.likeCount ? post?.likeCount - 1 : 0,
                };
                await updateDoc(postRef, {
                    likes: arrayRemove(user?.uid),
                    likeCount: post?.likeCount ? post?.likeCount - 1 : 0,
                });
            } else {
                // 하지 않은 경우 좋아요 추가
                updatedPost = {
                    ...post,
                    likes: post.likes ? [...post.likes, user.uid] : [user.uid],
                    likeCount: post?.likeCount ? post?.likeCount + 1 : 1,
                };
                await updateDoc(postRef, {
                    likes: arrayUnion(user?.uid),
                    likeCount: post?.likeCount ? post?.likeCount + 1 : 1,
                });
            }
            onToggleLike(updatedPost); // 업데이트된 데이터를 전달
        }
    };

    //게시글 삭제
    const handleDelete = async () => {
        const confirm = window.confirm("해당 게시글을 삭제하시곗습니까?");
        if (confirm) {
            //스토리지 이미지 먼저 삭제
            if (post?.imageUrl) {
                deleteObject(imageRef).catch((error) => {
                    console.log(error);
                })
            };

            await deleteDoc(doc(db, "safetyimgs", post.id));
            toast.success("게시글이 삭제되었습니다.")
            navigate("/safetyimg")
        }
    };

    useEffect(() => {
        const handleContextMenu = (event: Event) => {
            event.preventDefault();
        };

        const elements = document.querySelectorAll('img, button');
        elements.forEach(element => {
            element.addEventListener('contextmenu', handleContextMenu);
        });

        return () => {
            elements.forEach(element => {
                element.removeEventListener('contextmenu', handleContextMenu);
            });
        };
    }, []);

    return (
        <>
            <div className="poster__box" key={post?.id} >
                <Link to={`/safetyimg/${post?.id}`} target="_blank" rel="noopener noreferrer" onClick={onClickViews}>
                    {post?.imageUrl && (
                        <div className="poster__image-div-sign">
                            <img src={post?.imageUrl} alt={`${post.title} 표지 이미지`} className="poster__image-sign" onContextMenu={(e) => e.preventDefault()} />
                        </div>
                    )}
                </Link>
                <div className="poster__box-profile">
                    <Link to={`/safetyimg/${post?.id}`} target="_blank" rel="noopener noreferrer">
                        <div className="poster__box-title">{post?.title}</div>
                    </Link>
                    <button type="button" className="poster__box-likes" onClick={toggleLike}>
                        {user && post?.likes?.includes(user.uid) ? (
                            <AiFillHeart size={30} />
                        ) : (
                            <AiOutlineHeart size={30} />
                        )}
                    </button>
                </div>
                <div className="poster-form__hashtages-outputs">
                    {post?.hashTags?.map((tag, index) => (
                        <button value={tag} className="poster-form__hashtages-tag" key={index} onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            onClickTag(tag);
                        }}>{tag}</button>
                    ))}
                </div>
                {(user?.uid === exception1 || user?.uid === exception2) && (
                    <div className="poster__box-footer">
                        <>
                            <div className="poster__count">
                                <div className="poster__count-cont">좋아요 {post?.likeCount || 0}</div>
                                <div className="poster__count-cont">조회수 {post?.views || 0}</div>
                                <div className="poster__count-cont">다운수 {post?.downCount || 0}</div>
                                <div className="poster__count-cont">{post?.createdAt || 0}</div>
                            </div>
                            <Link to={`/safetyimg/edit/${post?.id}`} onClick={(event) => event.stopPropagation()} target="_blank">
                                <button type="button" className="poster__edit">
                                    수정
                                </button>
                            </Link>
                            <button type="button" className="poster__delete" onClick={handleDelete}>
                                삭제
                            </button>
                        </>
                    </div>
                )}
            </div>
        </>
    );
}