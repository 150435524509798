import AuthContext from "context/AuthContext";
import { SignProps } from "pages/signs";

import { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";

import { Link, useNavigate } from "react-router-dom";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "firevaseApp";
import { ref, deleteObject, getDownloadURL } from "firebase/storage";
import { storage } from "firevaseApp";

import { FaArrowRightLong } from "react-icons/fa6";
import { Helmet } from "react-helmet-async";

interface SignBoxProps {
  sign: SignProps;
}

export default function SignDetailForm({ sign }: SignBoxProps) {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const imageRef = ref(storage, sign?.imageUrl);

  const [isWaiting, setIsWaiting] = useState(true);
  const [timeLeft, setTimeLeft] = useState(10);

  // 게시글 삭제
  const handleDelete = async () => {
    const confirm = window.confirm("해당 게시글을 삭제하시곗습니까?");
    if (confirm) {
      // 스토리지 이미지 먼저 삭제
      if (sign?.imageUrl) {
        deleteObject(imageRef).catch((error) => {
          console.log(error);
        });
      }

      await deleteDoc(doc(db, "signs", sign.id));
      toast.success("게시글이 삭제되었습니다.");
      navigate("/sign");
    }
  };

  // 파일 다운로드
  const handleDownload = async () => {
    if (sign?.imageUrl) {
      getDownloadURL(imageRef)
        .then((url) => {
          const xhr = new XMLHttpRequest();
          xhr.responseType = "blob";
          xhr.onload = (event) => {
            const blob = xhr.response;
            const blobURL = URL.createObjectURL(blob);
            const link = document.createElement("a");

            link.href = blobURL;
            link.download = sign?.title + ".png";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          };
          xhr.open("GET", url);
          xhr.send();
        })
        .catch((error) => {
          console.error(error);
        });
      const signRef = doc(db, "signs", sign.id);
      await updateDoc(signRef, {
        downCount: sign?.downCount ? sign?.downCount + 1 : 1,
      });
      console.log("1" + sign?.downCount);
    }
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime === 1) {
          clearInterval(timer);
          setIsWaiting(false);
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer); // 컴포넌트가 언마운트될 때 타이머를 정리
  }, []);

  useEffect(() => {
    const handleContextMenu = (event: Event) => {
      event.preventDefault();
    };

    const elements = document.querySelectorAll("img, button");
    elements.forEach((element) => {
      element.addEventListener("contextmenu", handleContextMenu);
    });

    return () => {
      elements.forEach((element) => {
        element.removeEventListener("contextmenu", handleContextMenu);
      });
    };
  }, []);

  useEffect(() => {
    const adScript = document.createElement("script");
    adScript.async = true;
    adScript.src =
      "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js";
    adScript.setAttribute("data-ad-client", "ca-pub-1059579480577414");
    adScript.crossOrigin = "anonymous";
    document.body.appendChild(adScript);

    const adContainer = document.createElement("ins");
    adContainer.className = "adsbygoogle";
    adContainer.style.display = "block";
    adContainer.setAttribute("data-ad-client", "ca-pub-1059579480577414");
    adContainer.setAttribute("data-ad-slot", "4517124924");
    adContainer.setAttribute("data-ad-format", "auto");
    adContainer.setAttribute("data-full-width-responsive", "true");

    const adPushScript = document.createElement("script");
    adPushScript.innerHTML =
      "(adsbygoogle = window.adsbygoogle || []).push({});";

    const adElement = document.getElementById("ad-container");
    if (adElement) {
      adElement.appendChild(adContainer);
      adElement.appendChild(adPushScript);
    }

    return () => {
      document.body.removeChild(adScript);
    };
  }, []);

  return (
    <>
      <Helmet>
        <meta name="description" content={sign.title} />
        <meta property="og:description" content={sign.title} />
        <meta property="og:image" content={sign.imageUrl} />
        <meta
          property="og:url"
          content={`https://sososafety.shop/sign/${sign.id}`}
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="poster__box" key={sign?.id}>
        <div className="poster__box-profile poster__box-profile-detail">
          <div className="poster__box-title">{sign?.title}</div>
          <Link to={"/sign"}>
            <button type="button" className="poster__box-list">
              <FaArrowRightLong className="more__btn-icon" />
              <div>목록</div>
            </button>
          </Link>
        </div>
        <div className="poster-form__hashtages-outputs poster-form__hashtages-outputs-detail">
          {sign?.hashTags?.map((tag, index) => (
            <span className="poster-form__hashtages-tag" key={index}>
              #{tag}
            </span>
          ))}
        </div>
        {sign?.imageUrl && (
          <div className="poster__image-div-sign poster__image-div-sign-detail">
            <img
              id="myimg"
              src={sign?.imageUrl}
              alt="poster img"
              className="poster__image-sign"
              onContextMenu={(e) => e.preventDefault()}
            />
          </div>
        )}
        <div className="poster__down">
          <button
            className="poster__down-btn"
            onClick={handleDownload}
            disabled={isWaiting}
          >
            {isWaiting ? `다운로드 준비 중 (${timeLeft})` : "무료 다운로드"}
          </button>
          <div className="poster__down-guide">
            <div className="poster__down-guide1">
              사업장에서 자유롭게 사용하세요!
            </div>
            <div className="poster__down-guide2">
              (ⓒ소소안전 상업적으로 수정, 재배포, 판매는 금지합니다.)
            </div>
          </div>
        </div>
        <div id="ad-container">{/* 광고 위치 */} </div>
        <div className="poster__box-footer">
          {/* poster.uid === user.uid 일때 */}
          {user?.uid === sign?.uid && (
            <>
              <div className="poster__Count">
                <div className="poster__Count-cont">
                  좋아요 {sign?.likeCount || 0}
                </div>
                <div className="poster__Count-cont">
                  조회수 {sign?.views || 0}
                </div>
                <div className="poster__Count-cont">
                  다운수 {sign?.downCount || 0}
                </div>
                <div className="poster__Count-cont">{sign?.createdAt || 0}</div>
              </div>
              <Link to={`/sign/edit/${sign?.id}`}>
                <button type="button" className="poster__edit">
                  수정
                </button>
              </Link>
              <button
                type="button"
                className="poster__delete"
                onClick={handleDelete}
              >
                삭제
              </button>
            </>
          )}
          {user?.uid === "Qc78Qe8C7ReWlK9roSR3FQVUcHz2" &&
            user?.uid !== sign?.uid && (
              <>
                <div className="poster__Count">
                  <div className="poster__Count-cont">
                    좋아요 {sign?.likeCount || 0}
                  </div>
                  <div className="poster__Count-cont">
                    조회수 {sign?.views || 0}
                  </div>
                  <div className="poster__Count-cont">
                    다운수 {sign?.downCount || 0}
                  </div>
                  <div className="poster__Count-cont">
                    {sign?.createdAt || 0}
                  </div>
                </div>
                <button type="button" className="poster__edit">
                  <Link to={`/sign/edit/${sign?.id}`}>수정</Link>
                </button>
                <button
                  type="button"
                  className="poster__delete"
                  onClick={handleDelete}
                >
                  삭제
                </button>
              </>
            )}
        </div>
      </div>
    </>
  );
}
