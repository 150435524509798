import React, { useState, useEffect } from "react";

import { Layout } from 'components/Layout';
import Router from 'components/Router';

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { app } from 'firevaseApp';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import Loader from "components/loader/Loader";

function App() {
  const auth = getAuth(app); //파이어베이스에서 인증
  const [init, setInit] = useState<boolean>(false);
  const [isAuth, setIsAuth] = useState<boolean>(
    !!auth?.currentUser
  ); //currentUser 있으면 참

  //auth 값이 바뀔때 마다 갱신
  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuth(true);
      } else {
        setIsAuth(false);
      }
      setInit(true);
    })
  }, [auth])

  return (
    <Layout>
      <ToastContainer autoClose={1000} newestOnTop/>      
      {init ? <Router isAuth={isAuth} /> : <Loader />}
    </Layout>
  );
}

export default App;
