import { useCallback, useEffect, useState } from "react";

import Loader from "components/loader/Loader";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "firevaseApp";
import Header from "components/Header";
import Footer from "components/Footer";
import SirenrulesRulesDetailForm from "components/sirenrules/SirenRulesDetailForm";
import { SirenrulesRuleProps } from ".";

export default function SirenrulesRulesDetail() {
    const params = useParams(); //게시물 아이디 불러오기
    const [sirenrule, setSirenrule] = useState<SirenrulesRuleProps | null>(null);

    const getSirenrule = useCallback(async () => {
        if (params.id) {
            const docRef = doc(db, "sirenrules", params.id);
            const docSnap = await getDoc(docRef);

            setSirenrule({ ...(docSnap?.data() as SirenrulesRuleProps), id: docSnap.id }); //데이터 확인            
        }
    }, [params.id]);

    useEffect(() => {
        if (params.id) getSirenrule(); //id가 있다면 호출
    }, [getSirenrule, params.id])

    return (
        <>
            <Header />
            <div className="posters">
                <div className="poster-form__title">예방자료</div>
                {sirenrule ? <SirenrulesRulesDetailForm sirenrule={sirenrule}
                /> : <Loader />}
            </div>
            <Footer />
        </>
    )
}