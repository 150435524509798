import React from 'react';
import { collection, getDocs, query, where, updateDoc, doc } from "firebase/firestore";
import { db } from 'firevaseApp';


const UpdateHashTagsComponent: React.FC = () => {

  const updateHashTags = async () => {
    try {
      const signsRef = collection(db, "signs");
      const q = query(signsRef, where("hashTags", "array-contains", "경보표지"));
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach(async (documentSnapshot) => {
        const docData = documentSnapshot.data();
        const docRef = doc(db, "signs", documentSnapshot.id);

        if (docData.hashTags && Array.isArray(docData.hashTags)) {
          const updatedHashTags = docData.hashTags.map(tag => tag === "경보표지" ? "경고표지" : tag);

          await updateDoc(docRef, {
            hashTags: updatedHashTags
          });

          console.log(`Document with ID ${documentSnapshot.id} updated successfully`);
        }
      });
    } catch (error) {
      console.error("Error updating documents: ", error);
    }
  };

  return (
    <div>
      <button onClick={updateHashTags}>[경보표지]를 [경고표지]로 Update HashTags</button>
    </div>
  );
};

export default UpdateHashTagsComponent;
