import Footer from "components/Footer";
import Header from "components/Header";
import EtcEditForm from "components/etc/EtcEditForm";

export default function EtcEdit(){
    return (
        <>
            <EtcEditForm />
        </>
    )
}