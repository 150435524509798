import axios from "axios";
import NewsList from "components/accidentnews/AccidentnewsList";

import { useState } from "react";
import Header from 'components/Header';
import Footer from "components/Footer";

export default function NewsPage() {
   
    return (
        <>
            <Header />
            <h1>NewsPage</h1>
            <Footer />
        </>
    );

};
