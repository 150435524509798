import AuthContext from "context/AuthContext";

import { useContext, useEffect } from "react";
import { toast } from "react-toastify";

import { Link, useNavigate } from "react-router-dom";
import { arrayRemove, arrayUnion, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "firevaseApp";
import { ref, deleteObject, getDownloadURL, } from "firebase/storage";
import { storage } from "firevaseApp";

import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { SirenrulesRuleProps } from "pages/sirenrulesrules";
import { FaArrowRightLong } from "react-icons/fa6";

interface SirenrulesRuleBoxProps {
    sirenrule: SirenrulesRuleProps;
}


export default function SirenrulesRulesDetailForm({ sirenrule }: SirenrulesRuleBoxProps) {
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();
    const imageRef = ref(storage, sirenrule?.imageUrl);

    //조회수
    const onClickViews = async () => {
        const postRef = doc(db, "sirenrules", sirenrule.id);
        await updateDoc(postRef, {
            views: sirenrule?.views ? sirenrule?.views + 1 : 1,
        });
    };

    //좋아요
    const toggleLike = async () => {
        if (!user) {
            navigate("/users/login");
        } else {
            const postRef = doc(db, "sirenrules", sirenrule.id);

            if (user?.uid && sirenrule?.likes?.includes(user?.uid)) {
                //사용자가 좋아요를 미리 한경우 취소
                await updateDoc(postRef, {
                    likes: arrayRemove(user?.uid),
                    likeCount: sirenrule?.likeCount ? sirenrule?.likeCount - 1 : 0,
                });

            } else {
                //하지않은 경우 좋아요 추가        
                await updateDoc(postRef, {
                    likes: arrayUnion(user?.uid),
                    likeCount: sirenrule?.likeCount ? sirenrule?.likeCount + 1 : 1,
                });
            }
        }
    };

    //게시글 삭제
    const handleDelete = async () => {
        const confirm = window.confirm("해당 게시글을 삭제하시곗습니까?");
        if (confirm) {
            //스토리지 이미지 먼저 삭제
            if (sirenrule?.imageUrl) {
                deleteObject(imageRef).catch((error) => {
                    console.log(error);
                })
            };

            await deleteDoc(doc(db, "sirenrules", sirenrule.id));
            toast.success("게시글이 삭제되었습니다.")
            navigate("/sirenrules")
        };
    };

    //파일 다운로드
    const handleDownload = async () => {
        if (sirenrule?.imageUrl) {
            getDownloadURL(imageRef)
                .then((url) => {
                    // `url` is the download URL for 'images/stars.jpg'

                    // This can be downloaded directly:
                    const xhr = new XMLHttpRequest();
                    xhr.responseType = 'blob';
                    xhr.onload = (event) => {
                        const blob = xhr.response;
                        const blobURL = URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = blobURL;
                        link.download = sirenrule?.title + '.jpg';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    };
                    xhr.open('GET', url);
                    xhr.send();

                })
                .catch((error) => {
                    console.error(error);
                });
            const postRef = doc(db, "sirenrules", sirenrule.id);
            await updateDoc(postRef, {
                downCount: sirenrule?.downCount ? sirenrule?.downCount + 1 : 1,
            });
        };
    };

    useEffect(() => {
        const handleContextMenu = (event: Event) => {
            event.preventDefault();
        };
    
        const elements = document.querySelectorAll('img, button');
        elements.forEach(element => {
            element.addEventListener('contextmenu', handleContextMenu);
        });
    
        return () => {
            elements.forEach(element => {
                element.removeEventListener('contextmenu', handleContextMenu);
            });
        };
    }, []);

    return (
        <div className="poster__box" key={sirenrule?.id}>
            <div className="poster__box-profile poster__box-profile-detail">
                <div className="poster__box-title">{sirenrule?.title}</div>
                <Link to={"/sirenrules"}>
                    <button type="button" className="poster__box-list">
                        <FaArrowRightLong className="more__btn-icon" />
                        <div>목록</div>
                    </button>
                </Link>
            </div>
            <div className="poster-form__hashtages-outputs poster-form__hashtages-outputs-detail">
                {sirenrule?.hashTags?.map((tag, index) => (
                    <span className="poster-form__hashtages-tag" key={index} >{tag}</span>
                ))}
            </div>
            {sirenrule?.imageUrl && (
                <div className="poster__image-div poster__image-div-detail">
                    <img src={sirenrule?.imageUrl} alt="poster img" className="poster__image" />
                    {/* <img src={sirenrule?.imageUrl} alt="poster img" className="poster__ad" /> */}
                </div>
            )}
            <div className="poster__down">
                <button className="poster__down-btn" onClick={handleDownload}>다운로드</button>
                {/* <div className="poster__down-guide">
                    <div className="poster__down-guide1">출처를 표시하지 않고 사업장에서 자유롭게 사용하세요!</div>
                    <div className="poster__down-guide2">(ⓒ소소안전 상업적 사용, 수정 및 재 배포는 금지합니다.)</div>
                </div> */}
            </div>
            <div className="poster__box-footer">
                {/* poster.uid === user.uid 일때 */}
                {user?.uid === sirenrule?.uid && (
                    <>
                        <div className="poster__Count">
                            <div className="poster__Count-cont">좋아요 {sirenrule?.likeCount || 0}</div>
                            <div className="poster__Count-cont">조회수 {sirenrule?.views || 0}</div>
                            <div className="poster__Count-cont">다운수 {sirenrule?.downCount || 0}</div>
                            <div className="poster__Count-cont">{sirenrule?.createdAt || 0}</div>
                        </div>
                        <Link to={`/sirenrules/edit/${sirenrule?.id}`}>
                            <button type="button" className="poster__edit">
                                수정
                            </button>
                        </Link>
                        <button type="button" className="poster__delete" onClick={handleDelete}>
                            삭제
                        </button>
                    </>
                )}
                {user?.uid === "Qc78Qe8C7ReWlK9roSR3FQVUcHz2" && user?.uid !== sirenrule?.uid && (
                    <>
                        <div className="poster__Count">
                            <div className="poster__Count-cont">좋아요 {sirenrule?.likeCount || 0}</div>
                            <div className="poster__Count-cont">조회수 {sirenrule?.views || 0}</div>
                            <div className="poster__Count-cont">{sirenrule?.createdAt || 0}</div>
                        </div>
                        <button type="button" className="poster__edit">
                            <Link to={`/sirenrules/edit/${sirenrule?.id}`}>
                                수정
                            </Link>
                        </button>
                        <button type="button" className="poster__delete" onClick={handleDelete}>
                            삭제
                        </button>
                    </>
                )}
            </div>
        </div>
    );
}