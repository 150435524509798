import Header from "components/Header";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  collection,
  query,
  onSnapshot,
  orderBy,
  where,
  limit,
  startAfter,
  getDocs,
} from "firebase/firestore";
import AuthContext from "context/AuthContext";
import { db } from "firevaseApp";
import { IoSearch } from "react-icons/io5";
import Footer from "components/Footer";
import CardNewsBox from "components/cardnews/CardBox";
import { Helmet } from "react-helmet-async";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import { FaArrowRightLong } from "react-icons/fa6";
import React from "react";
import Loader from "components/loader/Loader";
import { AiFillHeart } from "react-icons/ai";
import AdComponent from "components/AdSense";
import PopupManager from "components/popup/PopupManager";

export interface CardNewsProps {
  id: string;
  email: string;
  title: string;
  createdAt: string;
  uid: string;
  admin?: boolean;
  profileUrl?: string;
  likes?: string[];
  likeCount?: number;
  hashTags?: string[];
  imageUrl?: string;
  imageUrls?: string[];
  views?: number;
  downCount?: number;
  currentPage?: number;
}

const imagesCard = [
  { img: "/img/main_card_1.png", id: "lY5M4bSQOs7339bk9wPo" },
  { img: "/img/main_card_2.png", id: "lY5M4bSQOs7339bk9wPo" },
  { img: "/img/main_card_3.png", id: "lY5M4bSQOs7339bk9wPo" },
  { img: "/img/main_card_4.png", id: "lY5M4bSQOs7339bk9wPo" },
  { img: "/img/main_card_5.png", id: "lY5M4bSQOs7339bk9wPo" },
  { img: "/img/main_card_6.png", id: "lY5M4bSQOs7339bk9wPo" },
];

const MAX_VISIBILITY = 3;

const Carousel: React.FC<{ children: React.ReactNode[] }> = ({ children }) => {
  const [active, setActive] = useState(0);
  const count = React.Children.count(children);

  return (
    <div className="carousel">
      {active > 0 && (
        <button className="nav left" onClick={() => setActive((i) => i - 1)}>
          <IoIosArrowDropleftCircle />
        </button>
      )}
      {React.Children.map(children, (child, i) => (
        <div
          className="card-container"
          style={
            {
              "--active": i === active ? 1 : 0,
              "--offset": (active - i) / 3,
              "--direction": Math.sign(active - i),
              "--abs-offset": Math.abs(active - i) / 3,
              pointerEvents: active === i ? "auto" : "none",
              opacity: Math.abs(active - i) >= MAX_VISIBILITY ? "0" : "1",
              display: Math.abs(active - i) > MAX_VISIBILITY ? "none" : "block",
            } as React.CSSProperties
          }
        >
          {child}
        </div>
      ))}
      {active < count - 1 && (
        <button className="nav right" onClick={() => setActive((i) => i + 1)}>
          <IoIosArrowDroprightCircle />
        </button>
      )}
    </div>
  );
};

type TabType = "" | "중량물" | "안전수칙" | "여름" | "질병" | "찜";

export default function CardNewsPage() {
  const exception1 = process.env.REACT_APP_ADMIN_EMAIL1 || "";
  const exception2 = process.env.REACT_APP_ADMIN_EMAIL2 || "";
  const [cards, setCards] = useState<CardNewsProps[]>([]);
  const [lastVisible, setLastVisible] = useState<any>(null);
  const { user } = useContext(AuthContext);
  const [tagQuery, setTagQuery] = useState<string>("");
  const [filter, setFilter] = useState<string>("createdAt");
  const [activeTab, setActiveTab] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const itemsPerPage = 9;

  const sanitizeInput = (input: string): string => {
    return input.replace(/[<>\/]/g, "").substring(0, 10);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTagQuery(sanitizeInput(e.target.value));
  };

  const onClickFilter = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const value = sanitizeInput(e.currentTarget.value) as TabType;
    if (activeTab === value) {
      setActiveTab("");
      setTagQuery("");
      handleSearch("");
    } else {
      setActiveTab(value);
      setTagQuery(value);
      handleSearch(value);
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const sanitizedQuery = sanitizeInput(e.currentTarget.value);
      if (activeTab === sanitizedQuery) {
        setActiveTab("");
        setTagQuery("");
        handleSearch("");
      } else {
        setActiveTab(sanitizedQuery);
        setTagQuery(sanitizedQuery);
        handleSearch(sanitizedQuery);
      }
    }
  };

  const onClickTag = (tag: string) => {
    const sanitizedQuery = sanitizeInput(tag);
    if (activeTab === sanitizedQuery) {
      setActiveTab("");
      setTagQuery("");
      handleSearch("");
    } else {
      setActiveTab(sanitizedQuery);
      setTagQuery(sanitizedQuery);
      handleSearch(sanitizedQuery);
    }
  };

  const handleSearch = async (tagQuery: string) => {
    let postRef = collection(db, "cards");
    let postQuery;
    setLoading(true);
    if (tagQuery === "찜" && user?.uid) {
      postQuery = query(
        postRef,
        where("likes", "array-contains", user.uid),
        orderBy(filter, "desc"),
        limit(itemsPerPage)
      );
    } else if (tagQuery) {
      postQuery = query(
        postRef,
        where("hashTags", "array-contains-any", [tagQuery]),
        orderBy(filter, "desc"),
        limit(itemsPerPage)
      );
    } else {
      postQuery = query(postRef, orderBy(filter, "desc"), limit(itemsPerPage));
    }

    const unsubscribe = onSnapshot(postQuery, (snapShot) => {
      let lastVisible = snapShot.docs[snapShot.docs.length - 1];
      let dataObj = snapShot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setCards(dataObj as CardNewsProps[]);
      setLastVisible(lastVisible);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    });
    window.scrollTo({ top: 700, behavior: "smooth" });
    return () => unsubscribe();
  };

  const loadMore = async () => {
    if (!lastVisible) return;

    let postRef = collection(db, "cards");
    let postQuery;
    if (tagQuery === "찜" && user?.uid) {
      postQuery = query(
        postRef,
        where("likes", "array-contains", user.uid),
        orderBy(filter, "desc"),
        startAfter(lastVisible),
        limit(itemsPerPage)
      );
    } else if (tagQuery) {
      postQuery = query(
        postRef,
        where("hashTags", "array-contains-any", [tagQuery]),
        orderBy(filter, "desc"),
        startAfter(lastVisible),
        limit(itemsPerPage)
      );
    } else {
      postQuery = query(
        postRef,
        orderBy(filter, "desc"),
        startAfter(lastVisible),
        limit(itemsPerPage)
      );
    }

    const snapShot = await getDocs(postQuery);
    let newLastVisible = snapShot.docs[snapShot.docs.length - 1] || null;
    let dataObj = snapShot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    })) as CardNewsProps[];

    setCards((prevCards) => {
      const updatedCards = [...prevCards, ...dataObj].reduce((acc, curr) => {
        if (!acc.find((item) => item.id === curr.id)) {
          acc.push(curr);
        }
        return acc;
      }, [] as CardNewsProps[]);
      return updatedCards;
    });
    setLastVisible(newLastVisible);
  };

  const handleToggleLike = (updatedCard: CardNewsProps) => {
    setCards((prevCards) =>
      prevCards.map((card) => (card.id === updatedCard.id ? updatedCard : card))
    );
  };

  useEffect(() => {
    handleSearch(tagQuery);
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="링크로 바로 공유하는 안전보건 카드뉴스"
        />
        <meta property="og:title" content="소소안전" />
        <meta
          property="og:description"
          content="링크로 바로 공유하는 안전보건 카드뉴스"
        />
        <meta
          property="og:image"
          content="https://sososafety.shop/main_slider.png"
        />
        <meta property="og:url" content="https://sososafety.shop/cardnews" />
        <meta property="og:type" content="website" />
      </Helmet>
            {/* 팝업을 관리하는 컴포넌트 */}
            <PopupManager />
      <Header />
      <div className="posters">
        <div className="posters__noti">
          <div className="post__signs">
            <div className="post__signs-box">
              <div className="post__signs-box-flex">
                <div className="content">
                  <h3>링크로 바로 공유하는 안전보건 카드뉴스</h3>
                  <p>
                    카드뉴스 이미지를 하나하나 저장하셨나요?
                    <br />
                    필요한 안전보건 카드뉴스를 찾아서 링크로 바로 공유하세요.
                  </p>
                  <ul>
                    <li className="content_poster-li">
                      <img
                        src="/img/main_smart_add.png"
                        alt="이미지 저장없이 링크 바로 공유"
                      />
                      이미지 저장없이 링크 바로 공유
                    </li>
                    <li className="content_poster-li">
                      <img
                        src="/img/main_smart_add.png"
                        alt="클릭 한번으로 원하는 카드뉴스 링크 복사"
                      />
                      클릭 한번으로 원하는 카드뉴스 링크 복사
                    </li>
                    <li className="content_poster-li">
                      <img
                        src="/img/main_smart_add.png"
                        alt="무료로 얼마든지 공유하세요"
                      />
                      무료로 얼마든지 공유하세요
                    </li>
                  </ul>
                </div>
                <div className="post_slider">
                  <Carousel>
                    {imagesCard.map((src, index) => (
                      <Link
                        to={`/cardnews/${imagesCard[0]?.id}`}
                        key={imagesCard[0]?.id}
                        onMouseDown={(e) => e.preventDefault()}
                        onDragStart={(e) => e.preventDefault()}
                      >
                        <div key={index} className="post_slider_img">
                          <img src={src.img} alt={`slide ${index + 1}`} />
                        </div>
                      </Link>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="posters__title">안전보건 카드뉴스</div>
        {(user?.uid === exception1 || user?.uid === exception2) && (
          <div>
            <Link to={"/cardnews/new"} className="posters__new">
              카드뉴스 등록
            </Link>
            <div>{cards.length}</div>
          </div>
        )}
        <div className="search__box">
          <div className="searchs__top">
            <div className="searchs__search-div">
              <div className="searchs__box">
                <input
                  type="text"
                  className="searchs__search"
                  value={tagQuery}
                  placeholder="키워드를 검색하세요."
                  onChange={onChange}
                  onKeyUp={handleKeyUp}
                />
                <button
                  type="button"
                  value={tagQuery}
                  onClick={onClickFilter}
                  className="searchs__search-btn"
                >
                  <IoSearch
                    color="white"
                    size={25}
                    className="searchs__search-btnimg"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="tags">
          <div className="tags__box">
            <button
              type="button"
              value="중량물"
              onClick={onClickFilter}
              className={`tags_tag ${
                activeTab === "중량물" && "tags_tag--active"
              }`}
            >
              중량물
            </button>
            <button
              type="button"
              value="안전수칙"
              onClick={onClickFilter}
              className={`tags_tag ${
                activeTab === "안전수칙" && "tags_tag--active"
              }`}
            >
              안전수칙
            </button>
            <button
              type="button"
              value="여름"
              onClick={onClickFilter}
              className={`tags_tag ${
                activeTab === "여름" && "tags_tag--active"
              }`}
            >
              여름
            </button>
            <button
              type="button"
              value="질병"
              onClick={onClickFilter}
              className={`tags_tag ${
                activeTab === "질병" && "tags_tag--active"
              }`}
            >
              질병
            </button>
            <button
              type="button"
              value="찜"
              onClick={onClickFilter}
              className={`tags_tag ${activeTab === "찜" && "tags_tag--active"}`}
            >
              <AiFillHeart />
            </button>
          </div>
        </div>
        {loading ? (
          <>
            <div className="loader_gap">
              <Loader />
              <div className="loader_gap-title">
                안전을 위해 열심히 검색 중..
              </div>
            </div>
          </>
        ) : (
          <div className="poster">
            <div className="addsense_smart">
              <AdComponent />
            </div>
            {cards.length > 0 ? (
              cards.map((card) => (
                <CardNewsBox
                  card={card}
                  key={card.id}
                  onClickTag={onClickTag}
                  currentPage={1}
                  onToggleLike={handleToggleLike}
                />
              ))
            ) : (
              <div className="poster__no-posters">
                <div className="poster__text">게시글이 없습니다.</div>
              </div>
            )}
          </div>
        )}
        {lastVisible && cards.length >= itemsPerPage && (
          <button className="more__btn" onClick={loadMore}>
            <FaArrowRightLong className="more__btn-icon" />
            <div>더 보기</div>
          </button>
        )}
      </div>
      <Footer />
    </>
  );
}
