import AuthContext from "context/AuthContext";
import { EtcProps } from "pages/etc";

import { useContext, useEffect } from "react";
import { toast } from "react-toastify";

import { Link, useNavigate } from "react-router-dom";
import { arrayRemove, arrayUnion, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { db, storage } from "firevaseApp";
import { ref, deleteObject } from "firebase/storage";

import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

interface EtcBoxProps {
    etc: EtcProps;
    onClickTag: (tag: string) => void;
    currentPage: number;
    onToggleLike: (updatedSign: EtcProps) => void; // 새로운 prop 추가
}

export default function EtcBox({ etc, onClickTag, currentPage, onToggleLike }: EtcBoxProps) {
    const exception1 = process.env.REACT_APP_ADMIN_EMAIL1 || "";
    const exception2 = process.env.REACT_APP_ADMIN_EMAIL2 || "";
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();
    const imageRef = ref(storage, etc?.imageUrl);

    //조회수
    const onClickViews = async (event: React.MouseEvent<HTMLAnchorElement>) => {
        // event.preventDefault(); // 기본 동작 방지
        // event.stopPropagation(); // 이벤트 전파 방지

        const postRef = doc(db, "etcs", etc.id);
        await updateDoc(postRef, {
            views: etc?.views ? etc?.views + 1 : 1,
        });
    };

    // 좋아요
    const toggleLike = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault(); // 기본 동작 방지
        event.stopPropagation(); // 이벤트 전파 방지

        if (!user) {
            navigate("/users/login");
        } else {
            const postRef = doc(db, "etcs", etc.id);
            let updatedPost;

            if (user?.uid && etc.likes?.includes(user?.uid)) {
                // 사용자가 좋아요를 미리 한 경우 취소
                updatedPost = {
                    ...etc,
                    likes: etc.likes ? etc.likes.filter(uid => uid !== user.uid) : [],
                    likeCount: etc?.likeCount ? etc?.likeCount - 1 : 0,
                };
                await updateDoc(postRef, {
                    likes: arrayRemove(user?.uid),
                    likeCount: etc?.likeCount ? etc?.likeCount - 1 : 0,
                });
            } else {
                // 하지 않은 경우 좋아요 추가
                updatedPost = {
                    ...etc,
                    likes: etc.likes ? [...etc.likes, user.uid] : [user.uid],
                    likeCount: etc?.likeCount ? etc?.likeCount + 1 : 1,
                };
                await updateDoc(postRef, {
                    likes: arrayUnion(user?.uid),
                    likeCount: etc?.likeCount ? etc?.likeCount + 1 : 1,
                });
            }
            onToggleLike(updatedPost); // 업데이트된 데이터를 전달
        }
    };

    //게시글 삭제
    const handleDelete = async () => {
        const confirm = window.confirm("해당 게시글을 삭제하시곗습니까?");
        if (confirm) {
            //스토리지 이미지 먼저 삭제
            if (etc?.imageUrl) {
                deleteObject(imageRef).catch((error) => {
                    console.log(error);
                })
            };

            await deleteDoc(doc(db, "etcs", etc.id));
            toast.success("게시글이 삭제되었습니다.")
            navigate("/etc")
        }
    };

    useEffect(() => {
        const handleContextMenu = (event: Event) => {
            event.preventDefault();
        };
    
        const elements = document.querySelectorAll('img, button');
        elements.forEach(element => {
            element.addEventListener('contextmenu', handleContextMenu);
        });
    
        return () => {
            elements.forEach(element => {
                element.removeEventListener('contextmenu', handleContextMenu);
            });
        };
    }, []);

    return (
        <> 
            <div className="poster__box" key={etc?.id} onClick={(event) => event.stopPropagation()}>
                <Link to={`/etc/${etc?.id}`} target="_blank" rel="noopener noreferrer" onClick={onClickViews}>
                    {etc?.imageUrl && (
                        <div className="poster__image-div-sign">
                            <img id="myimg" src={etc?.imageUrl} alt="poster img" className="poster__image-sign" onContextMenu={(e) => e.preventDefault()} />
                        </div>
                    )}
                </Link>
                <div className="poster__box-profile">
                    <Link to={`/etc/${etc?.id}`} target="_blank" rel="noopener noreferrer">
                        <div className="poster__box-title">{etc?.title}</div>
                    </Link>
                    <button type="button" className="poster__box-likes" onClick={toggleLike}>
                        {user && etc?.likes?.includes(user.uid) ? (
                            <AiFillHeart size={30} />
                        ) : (
                            <AiOutlineHeart size={30} />
                        )}
                    </button>
                </div>
                <div className="poster-form__hashtages-outputs">
                    {etc?.hashTags?.map((tag, index) => (
                        <button value={tag} className="poster-form__hashtages-tag" key={index} onClick={(event) => {
                            event.preventDefault();
                            event.stopPropagation();
                            onClickTag(tag);
                        }}>{tag}</button>
                    ))}
                </div>
                {(user?.uid === exception1 || user?.uid === exception2) && (
                    <div className="poster__box-footer">
                        <>
                            <div className="poster__count">
                                <div className="poster__count-cont">좋아요 {etc?.likeCount || 0}</div>
                                <div className="poster__count-cont">조회수 {etc?.views || 0}</div>
                                <div className="poster__count-cont">다운수 {etc?.downCount || 0}</div>
                                <div className="poster__count-cont">{etc?.createdAt || 0}</div>
                            </div>
                            <Link to={`/etc/edit/${etc?.id}`} onClick={(event) => event.stopPropagation()}>
                                <button type="button" className="poster__edit">
                                    수정
                                </button>
                            </Link>
                            <button type="button" className="poster__delete" onClick={handleDelete}>
                                삭제
                            </button>
                        </>

                    </div>
                )}
            </div>
        </>
    );
}