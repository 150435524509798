// components/TagFilter.tsx
import React from 'react';

interface TagFilterProps {
  activeTab: string;
  onClickFilter: (e: React.MouseEvent<HTMLButtonElement>) => void;
  tags: string[];  // 태그 목록을 props로 받음
}

const TagFilter: React.FC<TagFilterProps> = ({ activeTab, onClickFilter, tags }) => {
  return (
    <div className="tags">
      <div className="tags__box">
        {tags.map((tag) => (
          <button
            key={tag}
            type="button"
            value={tag}
            onClick={onClickFilter}
            className={`tags_tag ${activeTab === tag && "tags_tag--active"}`}
          >
            {tag}
          </button>
        ))}
      </div>
    </div>
  );
};

export default TagFilter;
