import AuthContext from "context/AuthContext";
import { FieldValue, arrayRemove, arrayUnion, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { toast } from "react-toastify";
import { deleteObject, ref } from "firebase/storage";
import { db, storage } from "firevaseApp";
import { SeriousDisasterProps } from "pages/seriousdisaster";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";

interface SeriousDisasterBoxProps {
    seriousDisaster: SeriousDisasterProps;
    setSeriousDisasters: React.Dispatch<React.SetStateAction<SeriousDisasterProps[]>>;
    onClickTag: (tag: string) => void;
}

export default function SeriousDisasterBox({ seriousDisaster, onClickTag, setSeriousDisasters }: SeriousDisasterBoxProps) {
    const exception1 = process.env.REACT_APP_ADMIN_EMAIL1 || "";
    const exception2 = process.env.REACT_APP_ADMIN_EMAIL2 || "";
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();
    const imageRef = ref(storage, seriousDisaster?.imageUrl);

    //조회수
    const onClickViews = async (event: React.MouseEvent<HTMLAnchorElement>) => {
        const seriousDisasterRef = doc(db, "seriousDisasters", seriousDisaster.id);
        await updateDoc(seriousDisasterRef, {
            views: seriousDisaster?.views ? seriousDisaster?.views + 1 : 1,
        });

    };

    // 찜
    const toggleLike = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();

        if (!user) {
            navigate("/users/login");
        } else {
            const seriousDisasterRef = doc(db, "seriousDisasters", seriousDisaster.id);
            let updatedLikes: string[];
            let updatedLikeCount: number;

            if (user?.uid && seriousDisaster.likes?.includes(user?.uid)) {
                updatedLikes = seriousDisaster.likes.filter(uid => uid !== user.uid);
                updatedLikeCount = seriousDisaster?.likeCount ? seriousDisaster?.likeCount - 1 : 0;

                await updateDoc(seriousDisasterRef, {
                    likes: arrayRemove(user?.uid),
                    likeCount: updatedLikeCount,
                });
            } else {
                updatedLikes = [...(seriousDisaster.likes || []), user.uid];
                updatedLikeCount = seriousDisaster?.likeCount ? seriousDisaster?.likeCount + 1 : 1;

                await updateDoc(seriousDisasterRef, {
                    likes: arrayUnion(user?.uid),
                    likeCount: updatedLikeCount,
                });
            }

            setSeriousDisasters((prevDisasters) =>
                prevDisasters.map((disaster) =>
                    disaster.id === seriousDisaster.id
                        ? { ...disaster, likes: updatedLikes, likeCount: updatedLikeCount }
                        : disaster
                )
            );
        }
    };

    //게시글 삭제
    const handleDelete = async () => {
        const confirm = window.confirm("해당 게시글을 삭제하시곗습니까?");
        if (confirm) {
            //스토리지 이미지 먼저 삭제
            if (seriousDisaster?.imageUrl) {
                deleteObject(imageRef).catch((error) => {
                    console.log(error);
                })
            };

            await deleteDoc(doc(db, "seriousDisasters", seriousDisaster.id));
            toast.success("게시글이 삭제되었습니다.")
            navigate("/seriousdisaster")
        }
    };

    useEffect(() => {
        const handleContextMenu = (event: Event) => {
            event.preventDefault();
        };
    
        const elements = document.querySelectorAll('img, button');
        elements.forEach(element => {
            element.addEventListener('contextmenu', handleContextMenu);
        });
    
        return () => {
            elements.forEach(element => {
                element.removeEventListener('contextmenu', handleContextMenu);
            });
        };
    }, []);

    return (
        <div className="poster__box">
            <Link to={`/seriousdisaster/${seriousDisaster?.id}`} target="_blank" rel="noopener noreferrer" onClick={onClickViews}>
                {seriousDisaster?.imageUrl && (
                    <div className="poster__image-div">
                        <img src={seriousDisaster?.imageUrl} alt="poster img" className="poster__image" />
                    </div>
                )}
            </Link>
            <div className="poster__box-profile2">
                <Link to={`/seriousdisaster/${seriousDisaster?.id}`} target="_blank" rel="noopener noreferrer">
                    <div className="poster__box-seriousDisaster">배포일자 : {seriousDisaster?.registrationdate}</div>
                </Link>
                <button type="button" className="poster__box-likes" onClick={toggleLike}>
                    {user && seriousDisaster?.likes?.includes(user.uid) ? (
                        <AiFillHeart size={30} />
                    ) : (
                        <AiOutlineHeart size={30} />
                    )}
                </button>
            </div>
            <div className="poster-form__hashtages-outputs">
                {seriousDisaster?.hashTags?.map((tag, index) => (
                    <button value={tag} className="poster-form__hashtages-tag" key={index} onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        onClickTag(tag);
                    }}>{tag}</button>
                ))}
            </div>


            {(user?.uid === exception1 || user?.uid === exception2) && (
                <div className="poster__box-footer">
                    <>
                        <div className="poster__count">
                            <div className="poster__count-cont">좋아요 {seriousDisaster?.likeCount || 0}</div>
                            <div className="poster__count-cont">조회수 {seriousDisaster?.views || 0}</div>
                            <div className="poster__count-cont">다운수 {seriousDisaster?.downCount || 0}</div>
                            <div className="poster__count-cont">{seriousDisaster?.createdAt || 0}</div>
                        </div>
                        <Link to={`/seriousdisaster/edit/${seriousDisaster?.id}`}>
                            <button type="button" className="poster__edit">
                                수정
                            </button>
                        </Link>
                        <button type="button" className="poster__delete" onClick={handleDelete}>
                            삭제
                        </button>
                    </>
                </div>
            )}
        </div>
    );
}
