import Header from "components/Header";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  collection,
  query,
  onSnapshot,
  orderBy,
  where,
  limit,
  startAfter,
  getDocs,
} from "firebase/firestore";
import AuthContext from "context/AuthContext";
import { db } from "firevaseApp";

import Footer from "components/Footer";

import { FaArrowRightLong } from "react-icons/fa6";
import React from "react";

import { Helmet } from "react-helmet-async";
import Loader from "components/loader/Loader";
import AdComponent from "components/AdSense";
import PopupManager from "components/popup/PopupManager";
import SearchBar from "components/share/SearchBar";
import TagFilter from "components/share/TagFilter";
import SafetyImgBox from "components/safetyimg/SafetyImgBox";

export interface SafetyImgProps {
  id: string;
  email: string;
  title: string;
  createdAt: string;
  uid: string;
  admin?: boolean;
  profileUrl?: string;
  likes?: string[];
  likeCount?: number;
  hashTags?: string[];
  imageUrl?: string;
  views?: number;
  downCount?: number;
  currentPage?: number;
}

export default function SafetyImgPage() {
  const exception1 = process.env.REACT_APP_ADMIN_EMAIL1 || "";
  const exception2 = process.env.REACT_APP_ADMIN_EMAIL2 || "";
  const { user } = useContext(AuthContext);
  const [safetyImgs, setSafetyImgs] = useState<SafetyImgProps[]>([]);
  const [tagQuery, setTagQuery] = useState<string>("");
  const [activeTab, setActiveTab] = useState<string>("");
  const [filter, setFilter] = useState<string>("createdAt");
  const [lastVisible, setLastVisible] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const itemsPerPage = 9;
  const tags = [
    "추석",
    "운전",
    "TBM",
    "찜",
  ];

  const sanitizeInput = (input: string): string => {
    return input.replace(/[<>\/]/g, "").substring(0, 10);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTagQuery(sanitizeInput(e.target.value));
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      const sanitizedQuery = sanitizeInput(e.currentTarget.value);
      if (activeTab === sanitizedQuery) {
        setActiveTab("");
        setTagQuery("");
        handleSearch("");
      } else {
        setActiveTab(sanitizedQuery);
        setTagQuery(sanitizedQuery);
        handleSearch(sanitizedQuery);
      }
    }
  };
  const onClickFilter = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const value = sanitizeInput(e.currentTarget.value);

    if (activeTab === value) {
      setActiveTab("");
      setTagQuery("");
      handleSearch("");
    } else {
      setActiveTab(value);
      setTagQuery(value);
      handleSearch(value);
    }
  };

  const handleSearch = async (tagQuery: string) => {
    let postRef = collection(db, "safetyimgs");
    let postQuery;
    setLoading(true);

    if (tagQuery === "찜" && user?.uid) {
      postQuery = query(
        postRef,
        where("likes", "array-contains", user.uid),
        orderBy(filter, "desc"),
        limit(itemsPerPage)
      );
    } else if (tagQuery) {
      postQuery = query(
        postRef,
        where("hashTags", "array-contains-any", [tagQuery]),
        orderBy(filter, "desc"),
        limit(itemsPerPage)
      );
    } else {
      postQuery = query(postRef, orderBy(filter, "desc"), limit(itemsPerPage));
    }

    const unsubscribe = onSnapshot(postQuery, (snapShot) => {
      const lastVisible = snapShot.docs[snapShot.docs.length - 1];
      const dataObj = snapShot.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));
      setSafetyImgs(dataObj as SafetyImgProps[]);
      setLastVisible(lastVisible);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    });
    return () => unsubscribe();
  };

  const loadMore = async () => {
    if (!lastVisible) return;

    let postRef = collection(db, "safetyimgs");
    let postQuery;

    if (tagQuery === "찜" && user?.uid) {
      postQuery = query(
        postRef,
        where("likes", "array-contains", user.uid),
        orderBy(filter, "desc"),
        startAfter(lastVisible),
        limit(itemsPerPage)
      );
    } else if (tagQuery) {
      postQuery = query(
        postRef,
        where("hashTags", "array-contains-any", [tagQuery]),
        orderBy(filter, "desc"),
        startAfter(lastVisible),
        limit(itemsPerPage)
      );
    } else {
      postQuery = query(
        postRef,
        orderBy(filter, "desc"),
        startAfter(lastVisible),
        limit(itemsPerPage)
      );
    }

    const snapShot = await getDocs(postQuery);
    const newLastVisible = snapShot.docs[snapShot.docs.length - 1] || null;
    const dataObj = snapShot.docs.map((doc) => ({
      ...doc.data(),
      id: doc.id,
    })) as SafetyImgProps[];

    setSafetyImgs((prevPosts) => {
      const updatedPosts = [...prevPosts, ...dataObj].reduce((acc, curr) => {
        if (!acc.find((item) => item.id === curr.id)) {
          acc.push(curr);
        }
        return acc;
      }, [] as SafetyImgProps[]);
      return updatedPosts;
    });
    setLastVisible(newLastVisible);
  };

  useEffect(() => {
    handleSearch(tagQuery);
  }, []);

  const onClickTag = (tag: string) => {
    const sanitizedQuery = sanitizeInput(tag);
    if (activeTab === sanitizedQuery) {
      setActiveTab("");
      setTagQuery("");
      handleSearch("");
    } else {
      setActiveTab(sanitizedQuery);
      setTagQuery(sanitizedQuery);
      handleSearch(sanitizedQuery);
    }
  };

  const handleToggleLike = (updatedPost: SafetyImgProps) => {
    setSafetyImgs((prevPosts) =>
      prevPosts.map((post) => (post.id === updatedPost.id ? updatedPost : post))
    );
  };

  return (
    <>
      <Helmet>
        <meta name="description" content="다양한 필요를 충족하는 안전이미지" />
        <meta property="og:title" content="소소안전" />
        <meta
          property="og:description"
          content="다양한 필요를 충족하는 안전이미지"
        />
        <meta
          property="og:image"
          content="https://sososafety.shop/main_slider.png"
        />
        <meta property="og:url" content="https://sososafety.shop/safetyimg" />
        <meta property="og:type" content="website" />
      </Helmet>
      {/* 팝업을 관리하는 컴포넌트 */}
      <PopupManager />
      <Header />
      <div className="posters">
        <div className="posters__title">안전 이미지</div>
        {(user?.uid === exception1 || user?.uid === exception2) && (
          <div>
            <Link to={"/safetyimg/new"} className="posters__new">
              안전이미지 등록
            </Link>
            <div>{safetyImgs.length}</div>
          </div>
        )}
        <SearchBar
          tagQuery={tagQuery}
          onChange={onChange}
          onKeyUp={handleKeyUp}
          onClickFilter={onClickFilter}
        />
        <TagFilter
          activeTab={activeTab}
          onClickFilter={onClickFilter}
          tags={tags}
        />
        {loading ? (
          <>
            <div className="loader_gap">
              <Loader />
              <div className="loader_gap-title">
                안전을 위해 열심히 검색 중..
              </div>
            </div>
          </>
        ) : (
          <div className="poster">
            <div className="addsense_smart">
              <AdComponent />
            </div>
            {safetyImgs?.length > 0 ? (
              safetyImgs.map((safetyImg) => (
                <SafetyImgBox
                  post={safetyImg}
                  key={safetyImg.id}
                  onClickTag={onClickTag}
                  currentPage={1}
                  onToggleLike={handleToggleLike}
                />
              ))
            ) : (
              <div className="poster__no-posters">
                <div className="poster__text">게시글이 없습니다.</div>
              </div>
            )}
          </div>
        )}
        {lastVisible && safetyImgs.length >= itemsPerPage && (
          <button className="more__btn" onClick={loadMore}>
            <FaArrowRightLong className="more__btn-icon" />
            <div>더 보기</div>
          </button>
        )}
      </div>
      <Footer />
    </>
  );
}
