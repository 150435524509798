import React, { useState, useEffect } from 'react';
import Modal from './Popup'; // Modal 컴포넌트를 import 합니다.
import { db } from 'firevaseApp';
import { doc, onSnapshot } from 'firebase/firestore';

// PopupSettings 타입 정의
export interface PopupSettings {
  title: string;
  content: string[];
  isVisible: boolean;
}

const PopupManager: React.FC = () => {
  const [popupSettings, setPopupSettings] = useState<PopupSettings | null>(null);

  useEffect(() => {
    const checkPopupStatus = async () => {
      // "오늘 하루 보지 않기"가 설정된 경우 로컬 저장소에서 확인
      const closedDate = localStorage.getItem('popupClosedDate');
      const today = new Date().toISOString().split('T')[0];

      if (closedDate === today) {
        // 오늘은 팝업을 띄우지 않음
        return;
      }

      const docRef = doc(db, "popupSettings", "currentSettings");

      // Firestore에서 실시간으로 설정을 가져옴
      const unsubscribe = onSnapshot(docRef, (docSnap) => {
        if (docSnap.exists()) {
          const settings = docSnap.data() as PopupSettings;
          if (settings.isVisible) {
            setPopupSettings(settings);
          }
        }
      });

      return () => unsubscribe();
    };

    checkPopupStatus();
  }, []);

  const handleDontShowToday = () => {
    const today = new Date().toISOString().split('T')[0];
    localStorage.setItem('popupClosedDate', today);
    setPopupSettings(null); // 팝업을 닫습니다.
  };

  return (
    <>
      {popupSettings && (
        <Modal
          title={popupSettings.title}
          content={popupSettings.content}
          onClose={() => setPopupSettings(null)}
          onDontShowToday={handleDontShowToday} // "오늘 하루 보지 않기" 기능 추가
        />
      )}
    </>
  );
};

export default PopupManager;
