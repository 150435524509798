import { useCallback, useEffect, useState } from "react";

import Loader from "components/loader/Loader";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "firevaseApp";
import Header from "components/Header";
import Footer from "components/Footer";
import { NewsReportProps } from ".";
import NewsReportDetailForm from "components/newsreport/NewsReportDetailForm";
import AdComponent from "components/AdSense";

// window 인터페이스 확장
declare global {
  interface Window {
    adsbygoogle: any[];
  }
}

export default function NewsReportDetailPage() {
  const params = useParams(); // 게시물 아이디 불러오기
  const [card, setCard] = useState<NewsReportProps | null>(null);

  const getCard = useCallback(async () => {
    if (params.id) {
      const docRef = doc(db, "newsreports", params.id);
      const docSnap = await getDoc(docRef);

      setCard({ ...(docSnap?.data() as NewsReportProps), id: docSnap.id }); // 데이터 확인
    }
  }, [params.id]);

  useEffect(() => {
    if (params.id) getCard(); // id가 있다면 호출
  }, [getCard, params.id]);

  return (
    <>
      <Header />
      <div className="posters">
        <div className="addsense_report">
          <AdComponent />
        </div>
        <div className="poster-form__title">일일 리포트</div>
        {card ? <NewsReportDetailForm card={card} /> : <Loader />}
      </div>
      <Footer />
    </>
  );
}
