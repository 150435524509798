import { useCallback, useEffect, useState } from "react";
import { SignProps } from ".";
import Loader from "components/loader/Loader";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "firevaseApp";
import Header from "components/Header";
import SignDetailForm from "components/signs/SignDetailForm";
import Footer from "components/Footer";
import AdComponent from "components/AdSense";

export default function SignDetail() {
  const params = useParams(); //게시물 아이디 불러오기
  const [sign, setSign] = useState<SignProps | null>(null);

  const getSign = useCallback(async () => {
    if (params.id) {
      const docRef = doc(db, "signs", params.id);
      const docSnap = await getDoc(docRef);

      setSign({ ...(docSnap?.data() as SignProps), id: docSnap.id }); //데이터 확인
    }
  }, [params.id]);

  useEffect(() => {
    if (params.id) getSign(); //id가 있다면 호출
  }, [getSign, params.id]);

  return (
    <>
      <Header />
      <div className="posters">
        <div className="addsense_smart">
          <AdComponent />
        </div>
        <div className="poster-form__title">안전보건 표지</div>
        {sign ? <SignDetailForm sign={sign} /> : <Loader />}
      </div>
      <Footer />
    </>
  );
}
