import AuthContext from "context/AuthContext";
import { PosterProps } from "pages/posters";
import { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { db, storage } from "firevaseApp";
import { ref, deleteObject } from "firebase/storage";
import { arrayRemove, arrayUnion, deleteDoc, doc, updateDoc } from "firebase/firestore";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { Helmet } from "react-helmet-async";

interface PosterBoxProps {
    poster: PosterProps;
    onClickTag: (tag: string) => void;
    currentPage: number;
}

export default function PosterBox({ poster, onClickTag, currentPage }: PosterBoxProps) {
    const exception1 = process.env.REACT_APP_ADMIN_EMAIL1 || "";
    const exception2 = process.env.REACT_APP_ADMIN_EMAIL2 || "";
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();
    const imageRef = ref(storage, poster?.imageUrl);

    //조회수
    const onClickViews = async () => {
        try {
            const posterRef = doc(db, "posters", poster.id);
            await updateDoc(posterRef, {
                views: poster?.views ? poster?.views + 1 : 1,
            });
        } catch (error) {
            console.error("Error updating views:", error);
            toast.error("조회수를 업데이트하는데 실패했습니다. 나중에 다시 시도하세요.");
        }
    };

    //좋아요
    const toggleLike = async () => {
        if (!user) {
            navigate("/users/login");
            return;
        }
        try {
            const posterRef = doc(db, "posters", poster.id);

            if (user?.uid && poster?.likes?.includes(user?.uid)) {
                //사용자가 좋아요를 미리 한경우 취소
                await updateDoc(posterRef, {
                    likes: arrayRemove(user?.uid),
                    likeCount: poster?.likeCount ? poster?.likeCount - 1 : 0,
                });
            } else {
                //하지않은 경우 좋아요 추가        
                await updateDoc(posterRef, {
                    likes: arrayUnion(user?.uid),
                    likeCount: poster?.likeCount ? poster?.likeCount + 1 : 1,
                });
            }
        } catch (error) {
            console.error("Error toggling like:", error);
            toast.error("좋아요를 업데이트하는데 실패했습니다. 나중에 다시 시도하세요.");
        }
    };

    //게시글 삭제
    const handleDelete = async () => {
        const confirm = window.confirm("해당 게시글을 삭제하시겠습니까?");
        if (confirm) {
            //스토리지 이미지 먼저 삭제
            if (poster?.imageUrl) {
                deleteObject(imageRef).catch((error) => {
                    console.log(error);
                });
            };

            await deleteDoc(doc(db, "posters", poster.id));
            toast.success("게시글이 삭제되었습니다.");
            navigate("/poster");
        }
    };

    const jsonLdData = {
        "@context": "http://schema.org",
        "@type": "Article",
        "mainEntityOfPage": {
            "@type": "WebPage",
            "@id": `https://sososafety.shop/poster/${poster.id}`
        },
        "headline": poster.title,
        "image": poster.imageUrl,
        "author": {
            "@type": "Person",
            "name": poster.email
        },
        "publisher": {
            "@type": "Organization",
            "name": "소소안전",
            "logo": {
                "@type": "ImageObject",
                "url": "https://sososafety.shop/logo192.png"
            }
        },
        "datePublished": poster.createdAt,
        "dateModified": poster.createdAt,
        "description": "소소안전 안전보건포스터"
    };

    useEffect(() => {
        const handleContextMenu = (event: Event) => {
            event.preventDefault();
        };
    
        const elements = document.querySelectorAll('img, button');
        elements.forEach(element => {
            element.addEventListener('contextmenu', handleContextMenu);
        });
    
        return () => {
            elements.forEach(element => {
                element.removeEventListener('contextmenu', handleContextMenu);
            });
        };
    }, []);

    return (
        <>
            <div className="poster__box" key={poster?.id}>
                <Link to={`/poster/${poster?.id}`} target="_blank" rel="noopener noreferrer" onClick={onClickViews}>
                    {poster?.imageUrl && (
                        <div className="poster__image-div">
                            <img src={poster?.imageUrl} alt={`${poster.title} 포스터 이미지`} className="poster__image" onContextMenu={(e) => e.preventDefault()}/>
                        </div>
                    )}
                </Link>
                <div className="poster__box-profile">
                    <Link to={`/poster/${poster?.id}`} target="_blank" rel="noopener noreferrer">
                        <div className="poster__box-title">{poster?.title}</div>
                    </Link>
                    <button type="button" className="poster__box-likes" onClick={toggleLike}>
                        {user && poster?.likes?.includes(user.uid) ? (
                            <AiFillHeart size={30} />
                        ) : (
                            <AiOutlineHeart size={30} />
                        )}
                    </button>
                </div>

                <div className="poster-form__hashtages-outputs">
                    {poster?.hashTags?.map((tag, index) => (
                        <button value={tag} className="poster-form__hashtages-tag" key={index} onClick={() => onClickTag(tag)}>{tag}</button>
                    ))}
                </div>
                {(user?.uid === exception1 || user?.uid === exception2) && (
                    <div className="poster__box-footer">
                        <>
                            <div className="poster__count">
                                <div className="poster__count-cont">좋아요 {poster?.likeCount || 0}</div>
                                <div className="poster__count-cont">조회수 {poster?.views || 0}</div>
                                <div className="poster__count-cont">다운수 {poster?.downCount || 0}</div>
                                <div className="poster__count-cont">{poster?.createdAt || 0}</div>
                            </div>
                            <Link to={`/poster/edit/${poster?.id}`}>
                                <button type="button" className="poster__edit">
                                    수정
                                </button>
                            </Link>
                            <button type="button" className="poster__delete" onClick={handleDelete}>
                                삭제
                            </button>
                        </>
                    </div>
                )}
            </div>
        </>
    );
}
