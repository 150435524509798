import { useEffect } from 'react';
import './PrivacyPolicy.scss';

export default function PrivacyPolicy() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    
    return (
        <div className="privacy-policy">
            <h1>개인정보처리방침</h1>
            <p><strong>발효일자: 2024년 6월 12일</strong></p>

            <section>
                <h2>1. 수집하는 개인정보의 항목</h2>
                <p>회사는 다음과 같은 개인정보를 수집하고 있습니다.</p>
                <ul>
                    <li>회원가입 시 수집 항목: 이름, 이메일 주소, 비밀번호, 전화번호 등</li>
                    <li>서비스 이용 시 수집 항목: IP 주소, 쿠키, 방문 기록, 서비스 이용 기록 등</li>
                    <li>결제 시 수집 항목: 결제 카드 정보, 청구지 주소 등</li>
                </ul>
            </section>

            <section>
                <h2>2. 개인정보 수집 방법</h2>
                <p>회사는 다음과 같은 방법으로 개인정보를 수집합니다.</p>
                <ul>
                    <li>회원가입 및 서비스 이용 과정에서 사용자가 직접 제공</li>
                    <li>자동 수집 장치를 통한 수집 (쿠키 등)</li>
                    <li>제3자로부터의 제공</li>
                </ul>
            </section>

            <section>
                <h2>3. 개인정보의 수집 및 이용 목적</h2>
                <p>회사는 수집한 개인정보를 다음과 같은 목적으로 이용합니다.</p>
                <ul>
                    <li>회원 관리: 회원제 서비스 이용에 따른 본인 확인, 개인 식별, 부정 이용 방지</li>
                    <li>서비스 제공: 콘텐츠 제공, 맞춤 서비스 제공, 본인 인증, 결제 및 정산</li>
                    <li>마케팅 및 광고: 신규 서비스 개발 및 맞춤 서비스 제공, 이벤트 및 광고성 정보 제공</li>
                    <li>법적 의무 준수: 관련 법령에 따른 법적 의무 이행</li>
                </ul>
            </section>

            <section>
                <h2>4. 개인정보의 보유 및 이용 기간</h2>
                <p>회사는 법령에 따른 개인정보 보유 및 이용 기간 또는 이용자로부터 개인정보를 수집 시에 동의 받은 개인정보 보유 및 이용 기간 내에서 개인정보를 처리, 보유합니다.</p>
                <ul>
                    <li>회원가입 정보: 회원 탈퇴 시까지</li>
                    <li>결제 정보: 거래 완료 후 5년간 보관</li>
                    <li>마케팅 및 광고 목적: 동의 철회 시까지</li>
                </ul>
            </section>

            <section>
                <h2>5. 개인정보의 제3자 제공</h2>
                <p>회사는 사용자의 개인정보를 원칙적으로 외부에 제공하지 않습니다. 다만, 다음의 경우에는 예외로 합니다.</p>
                <ul>
                    <li>사용자가 사전에 동의한 경우</li>
                    <li>법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우</li>
                </ul>
            </section>

            <section>
                <h2>6. 개인정보의 처리 위탁</h2>
                <p>회사는 서비스 제공을 위하여 필요한 업무 중 일부를 외부 업체에 위탁하고 있으며, 위탁받은 업체가 개인정보 보호 관련 법령을 준수하도록 관리, 감독하고 있습니다.</p>
            </section>

            <section>
                <h2>7. 이용자의 권리와 행사 방법</h2>
                <p>이용자는 언제든지 다음과 같은 권리를 행사할 수 있습니다.</p>
                <ul>
                    <li>개인정보 열람 요청</li>
                    <li>오류 등이 있을 경우 정정 요청</li>
                    <li>삭제 요청</li>
                    <li>처리 정지 요청</li>
                </ul>
                <p>권리 행사는 서면, 전화, 이메일 등을 통해 하실 수 있으며, 회사는 이에 대해 지체 없이 조치하겠습니다.</p>
            </section>

            <section>
                <h2>8. 개인정보의 안전성 확보 조치</h2>
                <p>회사는 이용자의 개인정보를 보호하기 위해 다음과 같은 조치를 취하고 있습니다.</p>
                <ul>
                    <li>관리적 조치: 내부관리계획 수립, 시행, 정기적 직원 교육</li>
                    <li>기술적 조치: 개인정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램 설치</li>
                    <li>물리적 조치: 전산실, 자료보관실 등의 접근통제</li>
                </ul>
            </section>

            <section>
                <h2>9. 개인정보 보호책임자</h2>
                <p>회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 이용자의 불만 처리 및 피해 구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.</p>
                <ul>
                    <li>개인정보 보호책임자: [이름]</li>
                    <li>연락처: [이메일 주소], [전화번호]</li>
                </ul>
            </section>

            <section>
                <h2>10. 개인정보처리방침 변경</h2>
                <p>이 개인정보처리방침은 시행일로부터 적용되며, 법령 및 방침에 따른 변경 내용의 추가, 삭제 및 정정이 있는 경우에는 변경사항의 시행 7일 전부터 공지사항을 통하여 고지할 것입니다.</p>
            </section>
        </div>
    );
};