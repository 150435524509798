import { useEffect } from 'react';
import './TermsOfService.scss';

export default function TermsOfService() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    
      return (
        <div className="terms-of-service">
          <h1>이용약관</h1>
    
          <section>
            <h2>1. 목적</h2>
            <p>본 약관은 소소안전(이하 "회사")이 제공하는 모든 서비스(이하 "서비스")의 이용 조건 및 절차, 이용자와 회사의 권리, 의무 및 책임사항을 규정함을 목적으로 합니다.</p>
          </section>
    
          <section>
            <h2>2. 용어의 정의</h2>
            <p>본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
            <ul>
              <li>"이용자"란 본 약관에 따라 회사가 제공하는 서비스를 받는 자를 말합니다.</li>
              <li>"회원"이란 회사와 서비스 이용 계약을 체결하고 이용자 아이디(ID)를 부여받은 자를 말합니다.</li>
              <li>"비회원"이란 회원에 가입하지 않고 회사가 제공하는 서비스를 이용하는 자를 말합니다.</li>
            </ul>
          </section>
    
          <section>
            <h2>3. 약관의 효력 및 변경</h2>
            <p>
              본 약관은 이용자가 서비스에 접속하여 동의를 표시함으로써 효력이 발생합니다. 회사는 관련 법령을 위배하지 않는 범위에서 본 약관을 개정할 수 있으며, 
              약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행 약관과 함께 회사의 홈페이지에 그 적용일자 7일 전부터 공지합니다.
            </p>
          </section>
    
          <section>
            <h2>4. 서비스의 제공 및 변경</h2>
            <p>회사는 이용자에게 다음과 같은 서비스를 제공합니다.</p>
            <ul>
              <li>공공데이터를 이용한 안전보건 스마트검색 제공</li>
              <li>고용노동부에서 공유하는 중대재해 자료 제공</li>
              <li>안전보건에 관련된 관계 법령 및 소소안전에 제작한 표지, 포스터, 카드뉴스, 기타자료 제공</li>
              <li>뉴스픽에서 제공하는 뉴스 제공</li>
              <li>공공데이터를 이용한 사망사고 제공</li>
            </ul>
            <p>회사는 서비스의 내용 및 제공일자를 변경할 수 있으며, 이 경우 변경 사유 및 일정을 사전에 공지합니다.</p>
          </section>
    
          <section>
            <h2>5. 서비스의 중단</h2>
            <p>회사는 다음과 같은 사유가 발생한 경우 서비스 제공을 일시적으로 중단할 수 있습니다.</p>
            <ul>
              <li>설비의 유지, 보수 등을 위한 정기 또는 임시 점검</li>
              <li>전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우</li>
              <li>기타 불가피한 사유가 발생한 경우</li>
            </ul>
          </section>
    
          <section>
            <h2>6. 회원가입</h2>
            <p>
              이용자는 회사가 정한 가입 양식에 따라 회원정보를 기입한 후 본 약관에 동의한다는 의사표시를 함으로써 회원가입을 신청합니다. 
              회사는 이러한 신청에 대하여 특별한 사유가 없는 한 승인합니다.
            </p>
          </section>
    
          <section>
            <h2>7. 회원 탈퇴 및 자격 상실</h2>
            <p>
              회원은 언제든지 탈퇴를 요청할 수 있으며, 회사는 즉시 회원 탈퇴를 처리합니다. 회원이 다음 각 호의 사유에 해당하는 경우, 회사는 회원자격을 제한 및 정지시킬 수 있습니다.
            </p>
            <ul>
              <li>가입 신청 시 허위 내용을 등록한 경우</li>
              <li>서비스 이용과 관련하여 법령 또는 본 약관이 금지하거나 공서양속에 반하는 행위를 한 경우</li>
            </ul>
          </section>
    
          <section>
            <h2>8. 개인정보 보호</h2>
            <p>회사는 이용자의 개인정보를 보호하기 위해 관련 법령에 따라 노력하며, 개인정보 보호정책을 별도로 두어 보호하고 있습니다.</p>
          </section>
    
          <section>
            <h2>9. 이용자의 의무</h2>
            <p>이용자는 서비스를 이용함에 있어 다음 각 호의 행위를 해서는 안 됩니다.</p>
            <ul>
              <li>신청 또는 변경 시 허위 내용의 등록</li>
              <li>타인의 정보 도용</li>
              <li>회사가 게시한 정보의 변경</li>
              <li>회사가 정한 정보 이외의 정보(컴퓨터 프로그램 등)의 송신 또는 게시</li>
              <li>기타 불법적이거나 부당한 행위</li>
            </ul>
          </section>
    
          <section>
            <h2>10. 저작권의 귀속 및 이용제한</h2>
            <p>
              회사가 작성한 저작물에 대한 저작권 기타 지적재산권은 회사에 귀속합니다. 이용자는 회사가 제공하는 정보를 회사의 사전 승낙 없이 복제, 
              송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다.
            </p>
          </section>
    
          <section>
            <h2>11. 분쟁 해결</h2>
            <p>회사와 이용자 간에 발생한 분쟁에 대하여는 관련 법령에 따라 해결합니다.</p>
          </section>
    
          <section>
            <h2>12. 부칙</h2>
            <p>본 약관은 2024년 6월 12일부터 시행됩니다.</p>
          </section>
        </div>
      );
    };