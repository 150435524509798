import { useCallback, useEffect, useState } from "react";
import Loader from "components/loader/Loader";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "firevaseApp";
import { CardNewsProps } from ".";
import CardLink from "components/cardnews/CardLink";

export default function CardLinkPage() {
    const params = useParams(); //게시물 아이디 불러오기
    const [card, setCard] = useState<CardNewsProps | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    const preloadImages = (images: string[]) => {
        images.forEach(src => {
            const img = new Image();
            img.src = src;
        });
    };

    const getCard = useCallback(async () => {
        if (params.id) {
            const docRef = doc(db, "cards", params.id);
            const docSnap = await getDoc(docRef);

            const cardData = { ...(docSnap?.data() as CardNewsProps), id: docSnap.id };
            setCard(cardData);
            setIsLoading(false);

            if (cardData.imageUrls) {
                preloadImages(cardData.imageUrls); // 이미지 프리로딩
            }
        }
    }, [params.id]);

    useEffect(() => {
        if (params.id) getCard(); //id가 있다면 호출
    }, [getCard, params.id])

    return (
        <>
            {isLoading ? <Loader /> : card && <CardLink card={card} />}
        </>
    );
}