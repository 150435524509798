import { useCallback, useContext, useEffect, useState } from "react";
import { updateDoc, doc, getDoc } from "firebase/firestore";
import { db, storage } from "firevaseApp";
import { useNavigate, useParams } from "react-router-dom";

import { toast } from "react-toastify";

import { FiImage } from "react-icons/fi";
import Header from "components/Header";

import { v4 as uuidv4 } from 'uuid';
import AuthContext from "context/AuthContext";
import { NewsPicsProps } from "pages/newspic";
import { ref } from "firebase/storage";

export default function NewsPicEdit() {
    const params = useParams();
    const [newsPic, setNewsPic] = useState<NewsPicsProps | null>(null);
    const [title, setTitle] = useState<string>("");
    const [source, setSource] = useState<string>("");
    const [registrationdate, setRegistrationdate] = useState<string>("");
    const [hashTags, setHashTag] = useState<string>("");
    const [imageUrl, setImageUrl] = useState<string>("");
    const [linkUrl, setLinkUrl] = useState<string>("");    
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);//중복 이미지 등록 방지
    const { user } = useContext(AuthContext);

    const navigate = useNavigate();

    const validateDate = (date: string) => {
        const regex = /^\d{4}\.\d{2}\.\d{2}$/;
        return regex.test(date);
    };

    const getNewsPic = useCallback(async () => {
        if (params.id) {
            const docRef = doc(db, "newspics", params.id);
            const docSnap = await getDoc(docRef);

            setNewsPic({ ...(docSnap?.data() as NewsPicsProps), id: docSnap.id }); //데이터 확인
            setTitle(docSnap?.data()?.title); 
            setSource(docSnap?.data()?.source); 
            setRegistrationdate(docSnap?.data()?.registrationdate);
            setHashTag(docSnap?.data()?.hashTags);
            setImageUrl(docSnap?.data()?.imageUrl); 
            setLinkUrl(docSnap?.data()?.linkUrl); 
        }
    }, [params.id]);
    
    const onSubmit = async (e: any) => {
        e.preventDefault(); //폼이 넘어가지 않도록 설정

        setIsSubmitting(true);
        const key = `${user?.uid}/${uuidv4()}`; //고유 키값
        const storageRef = ref(storage, key);
        setHashTag(hashTags);

        try {
            if (newsPic) {                                                
                if (!title) {
                    toast.success("제목을 입력하세요.");
                    setIsSubmitting(false);
                }             
                else if (!source) {
                    toast.success("출처를 등록하세요.");
                    setIsSubmitting(false);
                }
                else if (!validateDate(registrationdate)) {
                    toast.error("등록 일자는 YYYY.MM.DD 형식이어야 합니다.");
                    setIsSubmitting(false);
                }
                else if (!imageUrl) {
                    toast.success("이미지URL을 등록하세요.");
                    setIsSubmitting(false);
                }
                else if (!linkUrl) {
                    toast.success("링크URL을 등록하세요.");
                    setIsSubmitting(false);
                } else {          

                    //만약 사진이 아예 없다면 삭제
                    const newsPicRef = doc(db, "newspics", newsPic?.id);
                    await updateDoc(newsPicRef, {
                        title: title,
                        source: source,
                        registrationdate: registrationdate,
                        imageUrl: imageUrl,
                        linkUrl: linkUrl,
                    });
                    
                    navigate(-1);
                    toast.success("게시물을 수정하였습니다.");
                }
                setIsSubmitting(false);
            }
        } catch (e: any) {
            console.log(e);
        }
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {
            target: { name, value }, //타이핑 찍기
        } = e;

        if (name === "title") {
            setTitle(value);// 입력값 업데이트
        }        
        if (name === "source") {
            setSource(value);// 입력값 업데이트
        }
        if (name === "registrationdate") {
            setRegistrationdate(value);
        }
        if (name === "imageUrl") {
            setImageUrl(value);// 입력값 업데이트
        }
        if (name === "linkUrl") {
            setLinkUrl(value);// 입력값 업데이트
        }
    };

    useEffect(() => {
        if (params.id) getNewsPic(); //id가 있다면 호출
    }, [getNewsPic, params.id])

    return (
        <form className="poster-form" onSubmit={onSubmit}>
            <div className="poster-form__title">{hashTags} 뉴스  수정</div>
            <input className="poster-form__input readOnly" required name="hashTag" id="hashTag" placeholder="해시태그 입력" value={hashTags} readOnly />
            <input className="poster-form__input" required name="title" id="title" placeholder="타이틀 입력" onChange={onChange} value={title} />
            <input className="poster-form__input" required name="source" id="source" placeholder="출처 입력" onChange={onChange} value={source} />
            <input className="poster-form__input" required name="registrationdate" id="registrationdate" placeholder="출처 등록일(2024.00.00) 입력" onChange={onChange} value={registrationdate} />
            <input className="poster-form__input" required name="imageUrl" id="imageUrl" placeholder="이미지URL 입력" onChange={onChange} value={imageUrl} />
            <input className="poster-form__input" required name="linkUrl" id="" placeholder="링크URL 입력" onChange={onChange} value={linkUrl} />
            <div className="poster-form__submit-input">
                <input type="submit" value={"등록"} className="poster-form__submit-btn" disabled={isSubmitting} />
            </div>
        </form>
    )
}