import { useState } from "react";

import Footer from "components/Footer";
import Header from "components/Header";
import SmartList from "components/smart/SmartList";

export default function SmartPage() {

    return (
        <>
            <Header />
            <SmartList />
            <Footer />
        </>
    );

};
